import { createSvgIcon } from '@mui/material';

const CheckIcon = createSvgIcon(
  <>
    <path id="Path_284" data-name="Path 284" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_285"
      data-name="Path 285"
      d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm-1-4L6.76,11.757l1.414-1.414L11,13.172l5.656-5.657,1.415,1.414Z"
      fill="currentColor"
    />
  </>,
  'Check'
);

export default CheckIcon;
