import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  Stack,
  Theme,
} from '@mui/material';
import { format } from 'date-fns';
import parse from 'date-fns/parse';

import Typography from 'components/atoms/Typography';
import Separator from 'components/atoms/Separator';
import Selector from 'components/molecules/Selector';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Checkbox from 'components/atoms/Checkbox';
import Alert from 'components/molecules/Alert';
import Modal from 'components/molecules/Modal';
import useCallbackPrompt from 'hooks/useCallbackPrompt';
import { isErrorWithMessage, isFetchBaseQueryError } from 'utils/api';
import { isBankHoliday } from 'utils/calendar-utils';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import {
  OfficeDaysBody,
  selectOfficeDaysByYear,
  useGetBankHolidaysQuery,
  useGetOfficeDaysQuery,
  useUpdateOfficeDaysMutation,
} from 'app-redux/apiSlice';
import {
  openErrorSnackbar,
  openSuccessSnackbar,
} from 'app-redux/storeSnackbarSlice';
import { store } from 'app-redux/store';
import Spinner from 'components/molecules/Spinner';
import { HAS_ACCESS, hasAccess } from 'utils/roleManagement';

// TODO: refactor - there are already constants with these values
enum DaysOfWeekForSelect {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
}

enum DaysOfWeekForCalendar {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

enum Months {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December',
}

const daysMap = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
};

const YEAR_THRESHOLD = 1;

const getKeyByValue = (object: Record<string, unknown>, value: unknown) =>
  Object.keys(object).find(key => object[key] === value) || '';

const OfficeDaysForm = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const daysOfWeekForSelect = Object.keys(DaysOfWeekForSelect) as Array<
    keyof typeof DaysOfWeekForSelect
  >;
  const daysOfWeekForCalendar = Object.keys(DaysOfWeekForCalendar) as Array<
    keyof typeof DaysOfWeekForCalendar
  >;

  const months = Object.keys(Months) as Array<keyof typeof Months>;
  const isHRorAdmin = hasAccess([...HAS_ACCESS.ADMIN, ...HAS_ACCESS.HR]);

  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<{ value: boolean; cb?: () => void }>({
    value: false,
    cb: undefined,
  });
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [conflictCount, setConflictCount] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());
  const [recurranceInputValue, setRecurranceInputValues] = useState('');
  const [recurranceValue, setRecurranceValue] = useState<
    Array<keyof typeof DaysOfWeekForSelect>
  >([]);

  const { data: bankHolidayList = [] } = useGetBankHolidaysQuery('ROU');
  const { data: officeDays, isFetching: isLoadingOfficeDays } =
    useGetOfficeDaysQuery({
      employeeId: (employeeId && employeeId) || '',
      year,
    });

  const [updateOfficeDays, resultUpdateOfficeDays] =
    useUpdateOfficeDaysMutation();

  const getDaysCountInMonth = (month: number, year: number) =>
    new Date(year, month + 1, 0).getDate();

  const checkForIndeterminate = (dayIndex: number) => {
    const isIncludeDayIndex = selectedDays.some(
      day => parse(day, 'dd-MM-yyyy', 1).getDay() === dayIndex
    );

    return isIncludeDayIndex;
  };

  const getUniqueWeekDayNames = () => {
    const dayNames: string[] = [];

    for (const date of selectedDays) {
      const currentDate = parse(date, 'dd-MM-yyyy', 1);
      const dayName = currentDate.toLocaleDateString('en-US', {
        weekday: 'long',
      });

      const isWeekend =
        currentDate.getDay() === 0 || currentDate.getDay() === 6;

      if (!dayNames.includes(dayName) && !isWeekend) {
        dayNames.push(dayName);
      }
    }

    return dayNames;
  };

  const countSpecificDayInYear = (dayIndex: number) => {
    let count = 0;
    for (let month = 0; month < 12; month++) {
      const daysInMonth = getDaysCountInMonth(month, year);
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const bankHoliday = isBankHoliday(date, bankHolidayList);
        if (
          date.getFullYear() === year &&
          date.getDay() === dayIndex &&
          !bankHoliday
        ) {
          count++;
        }
      }
    }

    return count;
  };

  const increaseYearByOne = (date: string) => {
    const currDate = parse(date, 'dd-MM-yyyy', 1);
    currDate.setFullYear(currDate.getFullYear() + 1);

    return format(currDate, 'dd-MM-yyyy');
  };

  const countSpecificDayInSelectedDays = (days: string[], dayIndex: number) => {
    const selectedDays = days.filter(day => {
      const date = parse(day, 'dd-MM-yyyy', 1);

      return date.getDay() === dayIndex;
    });

    return selectedDays.length;
  };

  const getAllWeekdaysInYear = () => {
    const weekdays = [];

    for (let month = 0; month < 12; month++) {
      const daysInMonth = getDaysCountInMonth(month, year);
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
        const bankHoliday = isBankHoliday(date, bankHolidayList);

        if (dayOfWeek >= 1 && dayOfWeek <= 5 && !bankHoliday) {
          weekdays.push(format(date, 'dd-MM-yyyy'));
        }
      }
    }

    return weekdays;
  };

  const removeAllSpecificWeekdays = (dayIndex: number): string[] => {
    const filteredDates: string[] = [];

    for (const date of selectedDays) {
      const currentDate = parse(date, 'dd-MM-yyyy', 1);
      if (currentDate.getDay() !== dayIndex) {
        filteredDates.push(date);
      }
    }

    return filteredDates;
  };

  const getAllSpecificDayInYear = (dayIndex: number) => {
    const days = [];

    for (let month = 0; month < 12; month++) {
      const daysInMonth = getDaysCountInMonth(month, year);
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
        const bankHoliday = isBankHoliday(date, bankHolidayList);
        if (dayOfWeek === dayIndex && !bankHoliday) {
          days.push(format(date, 'dd-MM-yyyy'));
        }
      }
    }

    return days;
  };

  const handleNextYear = () => {
    const fn = () => {
      if (currentYear + YEAR_THRESHOLD > year) {
        setYear(year + 1);
        setIsOpen({ value: false, cb: undefined });
        setIsDirty(false);
      }
    };

    if (isDirty) {
      setIsOpen({ value: true, cb: fn });
    } else {
      fn();
    }
  };

  const handlePrevYear = () => {
    const fn = () => {
      if (currentYear - YEAR_THRESHOLD < year) {
        setYear(year - 1);
        setIsOpen({ value: false, cb: undefined });
        setIsDirty(false);
      }
    };
    if (isDirty) {
      setIsOpen({ value: true, cb: fn });
    } else {
      fn();
    }
  };

  const handleSelectDay = (day: string) => {
    setIsDirty(true);
    const prev = [...selectedDays];
    const itemIndex = prev.findIndex(d => d === day);
    const date = parse(day, 'dd-MM-yyyy', 1);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
      const updatedRecurranceValue = recurranceValue.filter(
        day => day !== daysMap[date.getDay() as keyof typeof daysMap]
      );
      const concatedStringValue = updatedRecurranceValue
        .map(value => value)
        .join(', ');
      setRecurranceValue(updatedRecurranceValue);
      setRecurranceInputValues(concatedStringValue);
    } else {
      prev.push(day);
      const selectedDayCount = countSpecificDayInSelectedDays(
        prev,
        date.getDay()
      );
      const totalDayCount = countSpecificDayInYear(date.getDay());

      if (selectedDayCount === totalDayCount) {
        const addedDay = daysMap[date.getDay() as keyof typeof daysMap];
        setRecurranceValue(curr => {
          const newValue = [...curr];
          newValue.splice(
            date.getDay() - 1,
            0,
            addedDay as keyof typeof DaysOfWeekForSelect
          );
          const concatedStringValue = newValue.map(value => value).join(', ');
          setRecurranceInputValues(concatedStringValue);

          return newValue;
        });
      }
    }
    setSelectedDays([...prev]);
  };

  const handleSelectAllDays = () => {
    setIsDirty(true);
    const days = getAllWeekdaysInYear();
    const concatedStringValue = daysOfWeekForSelect.map(day => day).join(', ');

    setSelectedDays(days);
    setRecurranceValue(daysOfWeekForSelect);
    setRecurranceInputValues(concatedStringValue);
  };

  const handleClear = () => {
    setIsDirty(true);
    setSelectedDays([]);
    setRecurranceValue([]);
    setRecurranceInputValues('');
    setConflictCount(0);
  };

  const handleChangeWeekRecurrence = (
    values: Array<keyof typeof DaysOfWeekForSelect>,
    reason: AutocompleteChangeReason
  ) => {
    setIsDirty(true);
    const days: string[] = [];
    const concatedStringValue = values.map(value => value).join(', ');

    if (reason === 'selectOption') {
      values.forEach(value => {
        const newSelectedDays = getAllSpecificDayInYear(
          daysOfWeekForSelect.findIndex(w => w === value) + 1
        );
        days.push(...newSelectedDays);
      });
    } else {
      const removedDay = recurranceValue.filter(v => values.indexOf(v) < 0)[0];
      const removedDayIndex =
        daysOfWeekForSelect.findIndex(w => w === removedDay) + 1;
      setSelectedDays(removeAllSpecificWeekdays(removedDayIndex));
    }

    setRecurranceInputValues(concatedStringValue);
    setRecurranceValue(values);
    setSelectedDays(curr => [...new Set([...days, ...curr])]);
  };

  const handleUpdate = async () => {
    try {
      const body: OfficeDaysBody = {
        employeeId: employeeId ? employeeId : '',
        officeDateList: selectedDays,
        officeRecurrence: recurranceValue,
        officeDaysYear: year,
      };

      await updateOfficeDays(body);
      dispatch(openSuccessSnackbar('Office days updated succesfuly.'));
      setIsDirty(false);
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        const errMsg = (err.data as { error: string }).error;
        dispatch(openErrorSnackbar(errMsg));
      } else if (isErrorWithMessage(err)) {
        dispatch(openErrorSnackbar(err.message));
      }
    }
  };

  const generateCalendarGrid = useCallback(() => {
    const calendarGrid: JSX.Element[] = [];

    for (let month = 0; month < 12; month++) {
      const daysInMonth = getDaysCountInMonth(month, year);
      const monthGrid: JSX.Element[] = [];

      const firstDay = new Date(year, month, 1).getDay();
      const offset = firstDay === 0 ? 6 : firstDay - 1;

      for (let i = 0; i < 7; i++) {
        const dayName = daysOfWeekForCalendar[i];
        monthGrid.push(
          <Typography
            variant="subtitle-1"
            color="ixColorGrey70"
            key={`dayname_${dayName}`}
            sx={{
              textAlign: 'center',
              width: '36px',
              height: '28px',
            }}
          >
            {dayName.charAt(0)}
          </Typography>
        );
      }

      for (let i = 0; i < offset; i++) {
        monthGrid.push(
          <Box
            key={`empty_${i}`}
            sx={{
              padding: '8px',
            }}
          />
        );
      }

      for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = new Date(year, month, day);
        const isWeekend =
          currentDate.getDay() === 0 || currentDate.getDay() === 6;
        const formattedDate = format(currentDate, 'dd-MM-yyyy');
        const isSelected = selectedDays.includes(formattedDate);
        const bankHoliday = isBankHoliday(currentDate, bankHolidayList);
        const isConflicted = (bankHoliday || isWeekend) && isSelected;
        const isDisabled = (bankHoliday || isWeekend) && !isConflicted;
        const isToday =
          format(currentDate, 'dd.MM.yyyy') ===
          format(new Date(), 'dd.MM.yyyy');

        // TODO: refactor
        const setBackgroundColor = (theme: Theme) => {
          if (isConflicted) {
            return '#C000150D';
          }
          if (bankHoliday && !isWeekend) {
            return theme.palette.ixColorGrey10.main;
          }
          if (isToday) {
            return 'rgb(189 66 87 / 10%)';
          }

          return '';
        };

        // TODO: refactor
        const setBorderColor = (theme: Theme) => {
          if (isConflicted) {
            return '#C0001566';
          }
          if (isDisabled) {
            return theme.palette.ixColorGrey40.main;
          }

          return '';
        };

        const setBorderRadi = () => {
          if (isConflicted) {
            return '8px';
          }
          if (isWeekend) {
            return '0';
          }

          return '8px';
        };

        monthGrid.push(
          <Box
            key={day}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme => setBackgroundColor(theme),
              borderStyle: 'solid',
              borderRadius: setBorderRadi(),
              borderColor: theme => setBorderColor(theme),
              borderWidth: isSelected ? '1px' : '0',
              width: '36px',
              height: '28px',
              margin: isWeekend ? '0' : '1px',
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}
            onClick={() =>
              isHRorAdmin && !isDisabled && handleSelectDay(formattedDate)
            }
          >
            <Typography variant="body-2">{day}</Typography>
          </Box>
        );
      }

      calendarGrid.push(
        <Box
          key={month}
          sx={{ marginBottom: '24px', h2: { textAlign: 'center' } }}
        >
          <Typography
            variant="subtitle-1"
            sx={{ textAlign: 'center', marginBottom: '18px' }}
          >
            {capitalizeFirstLetter(months[month])}
          </Typography>
          <Box
            className="month-grid"
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(7, 1fr)',
              gap: '0',
              position: 'relative',
            }}
          >
            {monthGrid}
            <Box
              sx={{
                position: 'absolute',
                right: '3px',
                bottom: 0,
                top: '-4px',
                backgroundColor: theme => theme.palette.ixColorGrey10.main,
                width: '81px',
                zIndex: -1,
              }}
            />
          </Box>
        </Box>
      );
    }

    return calendarGrid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays, bankHolidayList, year]);

  useEffect(() => {
    if (
      (officeDays?.officeDateList?.length === 0 ||
        officeDays?.officeDateList === null) &&
      year > currentYear
    ) {
      const prevYearofficeDays = selectOfficeDaysByYear(
        store.getState(),
        year - 1,
        employeeId || ''
      );

      if (prevYearofficeDays?.officeDateList?.length > 0) {
        const dateFromPrevYear = prevYearofficeDays?.officeDateList.map(date =>
          increaseYearByOne(date)
        );
        setSelectedDays(dateFromPrevYear || []);
        setIsDirty(true);
      }
    } else {
      const concatedStringValue = officeDays?.officeRecurrence
        ?.map(day => day)
        ?.join(', ');

      setRecurranceInputValues(concatedStringValue || '');
      setSelectedDays(officeDays?.officeDateList || []);
      setRecurranceValue(
        (officeDays?.officeRecurrence as Array<
          keyof typeof DaysOfWeekForSelect
        >) || []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeDays, year]);

  useEffect(() => {
    if (selectedDays.length > 0 && bankHolidayList.length > 0) {
      let conflictCount = 0;
      selectedDays.forEach(day => {
        const currDay = parse(day, 'dd-MM-yyyy', 1);
        const bankHoliday = isBankHoliday(currDay, bankHolidayList);
        const isWeekend = currDay.getDay() === 0 || currDay.getDay() === 6;
        const isConflicted = bankHoliday || isWeekend;
        if (isConflicted) {
          conflictCount += 1;
        }
      });

      const newRecurranceValue: Array<keyof typeof DaysOfWeekForSelect> = [];
      const includedDays = getUniqueWeekDayNames();
      for (const day of includedDays) {
        let included = true;
        const allSpecificDays = getAllSpecificDayInYear(
          daysOfWeekForSelect.findIndex(w => w === day) + 1
        );

        for (const specificDay of allSpecificDays) {
          if (!selectedDays.includes(specificDay)) {
            included = false;
          }
        }

        if (included) {
          newRecurranceValue.push(day as keyof typeof DaysOfWeekForSelect);
        }
      }

      const concatedStringValue = newRecurranceValue.map(day => day).join(', ');

      setRecurranceInputValues(concatedStringValue || '');
      setConflictCount(conflictCount);
      setRecurranceValue(newRecurranceValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays, bankHolidayList]);

  return (
    <Box>
      {resultUpdateOfficeDays.isLoading && (
        <Spinner fullPage text="Updating Office Days..." />
      )}
      <Modal open={!!showPrompt || isOpen.value}>
        <Stack spacing={3} sx={{ width: '300px' }}>
          <Typography variant="h5">Are you sure?</Typography>
          <Typography variant="body-2">
            {/* TODO: why is this hardcoded? - shouldn't the name be coming from the user data? */}
            You’ve made changes to Alexandru Marin’s office days and haven’t
            updated them.
          </Typography>
          <Stack flexDirection="row" justifyContent="space-between">
            <Button
              onClick={() => {
                cancelNavigation();
                setIsOpen({ value: false, cb: undefined });
              }}
              variant="text"
            >
              Go Back
            </Button>
            <Button
              onClick={() => {
                confirmNavigation();
                isOpen.cb?.();
              }}
              variant="destructive"
            >
              {isOpen.value ? 'Yes, Change year' : 'Exit without saving'}
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingRight: '40px' }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Selector
            label={year}
            onNext={handleNextYear}
            onPrev={handlePrevYear}
            disableNext={year === currentYear + 1}
            disablePrev={year === currentYear - 1}
          />
          {isHRorAdmin ? (
            <>
              <Button variant="text" onClick={handleSelectAllDays}>
                Select All
              </Button>
              <Button variant="text" onClick={handleClear}>
                Clear
              </Button>
              <Box sx={{ width: 244 }}>
                <Autocomplete
                  value={recurranceValue}
                  inputValue={recurranceInputValue}
                  onChange={(_e, values, reason) =>
                    handleChangeWeekRecurrence(values, reason)
                  }
                  disablePortal
                  options={daysOfWeekForSelect}
                  sx={{ width: 244 }}
                  multiple
                  disableClearable
                  disableCloseOnSelect
                  filterOptions={options => options}
                  renderInput={params => (
                    <Input
                      {...params}
                      placeholder="Weekly recurrence"
                      label="Weekly recurrence"
                      sx={{ margin: 0 }}
                      value={recurranceValue}
                      InputProps={{
                        ...params.InputProps,
                        placeholder: 'Weekly recurrence',
                        value: recurranceInputValue,
                        sx: {
                          caretColor: 'transparent',
                          '& .MuiInputBase-input': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            paddingRight: '36px !important',
                            paddingLeft: '0 !important',
                            minWidth: '150px !important',
                          },
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          checked={selected}
                          indeterminate={
                            !selected &&
                            checkForIndeterminate(
                              Number(getKeyByValue(daysMap, option))
                            )
                          }
                        />
                        <Typography sx={{ marginLeft: '8px' }}>
                          {option}
                        </Typography>
                      </Stack>
                    </li>
                  )}
                  renderTags={() => null}
                />
              </Box>
            </>
          ) : null}
        </Stack>
        {isHRorAdmin ? (
          <Stack direction="row" spacing={2} alignItems="center">
            {conflictCount > 0 && (
              <Alert
                message={`Dates copied from previous year. You need resolve ${conflictCount} conflict/s before update.`}
                variant="error"
                sx={{ padding: '6px' }}
              />
            )}
            {isLoadingOfficeDays && <Spinner />}
            {conflictCount === 0 && (
              <Button
                disabled={conflictCount > 0 || !isDirty}
                onClick={handleUpdate}
              >
                Update
              </Button>
            )}
          </Stack>
        ) : null}
      </Stack>
      <Separator sx={{ margin: '24px 0' }} />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '> div': { width: '290px', marginRight: '44px' },
        }}
      >
        {generateCalendarGrid()}
      </Box>
    </Box>
  );
};

export default OfficeDaysForm;
