import '@fontsource/inter';
import '@fontsource/roboto';

import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    destructive: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    ixColorTalc: true;
    ixColorMist: true;
    ixColorOnyx: true;
    ixColorFlamingoDarker: true;
    ixColorFlamingo: true;
    ixColorFlamingo50: true;
    ixColorFlamingo30: true;
    ixColorWaveDarker: true;
    ixColorWave: true;
    ixColorWave70: true;
    ixColorWave60: true;
    ixColorTurmeric: true;
    ixColorTurmeric70: true;
    ixColorTurmeric50: true;
    ixColorJade: true;
    ixColorJade60: true;
    ixColorJade40: true;
    ixColorSapphire: true;
    ixColorSapphire70: true;
    ixColorSapphire40: true;
    ixColorAmethyst: true;
    ixColorAmethyst70: true;
    ixColorAmethyst30: true;
    ixColorGrey90: true;
    ixColorGrey80: true;
    ixColorGrey70: true;
    ixColorGrey60: true;
    ixColorGrey50: true;
    ixColorGrey40: true;
    ixColorGrey30: true;
    ixColorGrey20: true;
    ixColorGrey10: true;
    ixColorGrey5: true;
    ixColorError: true;
  }
}

declare module '@mui/material/Box' {
  interface BoxPropsColorOverrides {
    ixColorTalc: true;
    ixColorMist: true;
    ixColorOnyx: true;
    ixColorFlamingoDarker: true;
    ixColorFlamingo: true;
    ixColorFlamingo50: true;
    ixColorFlamingo30: true;
    ixColorWaveDarker: true;
    ixColorWave: true;
    ixColorWave70: true;
    ixColorWave60: true;
    ixColorTurmeric: true;
    ixColorTurmeric70: true;
    ixColorTurmeric50: true;
    ixColorJade: true;
    ixColorJade60: true;
    ixColorJade40: true;
    ixColorSapphire: true;
    ixColorSapphire70: true;
    ixColorSapphire40: true;
    ixColorAmethyst: true;
    ixColorAmethyst70: true;
    ixColorAmethyst30: true;
    ixColorGrey90: true;
    ixColorGrey80: true;
    ixColorGrey70: true;
    ixColorGrey60: true;
    ixColorGrey50: true;
    ixColorGrey40: true;
    ixColorGrey30: true;
    ixColorGrey20: true;
    ixColorGrey10: true;
    ixColorGrey5: true;
    ixColorError: true;
    inherit: true;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    size: {
      small: number;
      medium: number;
      large: number;
      xlarge: number;
      xxlarge: number;
      xxxlarge: number;
    };
  }
  interface ThemeOptions {
    size: {
      small: number;
      medium: number;
      large: number;
      xlarge: number;
      xxlarge: number;
      xxxlarge: number;
    };
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    calendarFrame: Palette['primary'];
    // new tokens start here
    ixColorTalc: PaletteColor;
    ixColorMist: PaletteColor;
    ixColorOnyx: PaletteColor;
    ixColorFlamingoDarker: PaletteColor;
    ixColorFlamingo: PaletteColor;
    ixColorFlamingo50: PaletteColor;
    ixColorFlamingo30: PaletteColor;
    ixColorWaveDarker: PaletteColor;
    ixColorWave: PaletteColor;
    ixColorWave70: PaletteColor;
    ixColorWave60: PaletteColor;
    ixColorTurmeric: PaletteColor;
    ixColorTurmeric70: PaletteColor;
    ixColorTurmeric50: PaletteColor;
    ixColorJade: PaletteColor;
    ixColorJade60: PaletteColor;
    ixColorJade40: PaletteColor;
    ixColorSapphire: PaletteColor;
    ixColorSapphire70: PaletteColor;
    ixColorSapphire40: PaletteColor;
    ixColorAmethyst: PaletteColor;
    ixColorAmethyst70: PaletteColor;
    ixColorAmethyst30: PaletteColor;
    ixColorGrey90: PaletteColor;
    ixColorGrey80: PaletteColor;
    ixColorGrey70: PaletteColor;
    ixColorGrey60: PaletteColor;
    ixColorGrey50: PaletteColor;
    ixColorGrey40: PaletteColor;
    ixColorGrey30: PaletteColor;
    ixColorGrey20: PaletteColor;
    ixColorGrey10: PaletteColor;
    ixColorGrey5: PaletteColor;
    ixColorError: PaletteColor;
    // new tokens end here
  }
  interface PaletteOptions {
    calendarFrame: PaletteOptions['primary'];
    // new tokens start here
    ixColorTalc: PaletteColor;
    ixColorMist: PaletteColor;
    ixColorOnyx: PaletteColor;
    ixColorFlamingoDarker: PaletteColor;
    ixColorFlamingo: PaletteColor;
    ixColorFlamingo50: PaletteColor;
    ixColorFlamingo30: PaletteColor;
    ixColorWaveDarker: PaletteColor;
    ixColorWave: PaletteColor;
    ixColorWave70: PaletteColor;
    ixColorWave60: PaletteColor;
    ixColorTurmeric: PaletteColor;
    ixColorTurmeric70: PaletteColor;
    ixColorTurmeric50: PaletteColor;
    ixColorJade: PaletteColor;
    ixColorJade60: PaletteColor;
    ixColorJade40: PaletteColor;
    ixColorSapphire: PaletteColor;
    ixColorSapphire70: PaletteColor;
    ixColorSapphire40: PaletteColor;
    ixColorAmethyst: PaletteColor;
    ixColorAmethyst70: PaletteColor;
    ixColorAmethyst30: PaletteColor;
    ixColorGrey90: PaletteColor;
    ixColorGrey80: PaletteColor;
    ixColorGrey70: PaletteColor;
    ixColorGrey60: PaletteColor;
    ixColorGrey50: PaletteColor;
    ixColorGrey40: PaletteColor;
    ixColorGrey30: PaletteColor;
    ixColorGrey20: PaletteColor;
    ixColorGrey10: PaletteColor;
    ixColorGrey5: PaletteColor;
    ixColorError: PaletteColor;
    // new tokens end here
  }
  interface PaletteColor {
    dayOff?: string;
    borderColor?: string;
    todayTextColor?: string;
    selectionColor?: string;
    selectionBorderColor?: string;
    selectionColorSpecial?: string;
  }
  interface SimplePaletteColorOptions {
    dayOff?: string;
    borderColor?: string;
    todayTextColor?: string;
    selectionColor?: string;
    selectionBorderColor?: string;
    selectionColorSpecial?: string;
  }
}

const { palette } = createTheme();

const theme = createTheme({
  size: {
    small: 0.5,
    medium: 1,
    large: 1.5,
    xlarge: 2,
    xxlarge: 3,
    xxxlarge: 4,
  },
  palette: {
    primary: {
      main: '#018786',
    },
    secondary: {
      main: '#F2617A',
    },
    calendarFrame: {
      main: '#E0E0E0',
      // dayOff: '#F8F8F8', old leaveapp calendar weekend and holidays color
      dayOff: '#f0f1f1',
      borderColor: '#E9E9EA',
      todayTextColor: '#F2617A',
      selectionColor: '#D6EDF0',
      selectionBorderColor: '#C5DCDF',
      selectionColorSpecial: '#D1E8EB',
    },
    info: {
      main: '#000000BC',
      light: '#00000061',
    },
    // new tokens start here
    ixColorTalc: palette.augmentColor({
      color: {
        main: '#FFFFFF',
      },
    }),
    ixColorMist: palette.augmentColor({
      color: {
        main: '#EDF1F3',
      },
    }),
    ixColorOnyx: palette.augmentColor({
      color: {
        main: '#000000',
      },
    }),
    ixColorFlamingoDarker: palette.augmentColor({
      color: {
        main: '#852233',
      },
    }),
    ixColorFlamingo: palette.augmentColor({
      color: {
        main: '#F2617A',
      },
    }),
    ixColorFlamingo50: palette.augmentColor({
      color: {
        main: '#9B293C',
      },
    }),
    ixColorFlamingo30: palette.augmentColor({
      color: {
        main: '#BD4257',
      },
    }),
    ixColorWaveDarker: palette.augmentColor({
      color: {
        main: '#002936',
      },
    }),
    ixColorWave: palette.augmentColor({
      color: {
        main: '#003D4F',
      },
    }),
    ixColorWave70: palette.augmentColor({
      color: {
        main: '#156C86',
      },
    }),
    ixColorWave60: palette.augmentColor({
      color: {
        main: '#0D98C1',
      },
    }),
    ixColorTurmeric: palette.augmentColor({
      color: {
        main: '#CC850A',
      },
    }),
    ixColorTurmeric70: palette.augmentColor({
      color: {
        main: '#A06908',
      },
    }),
    ixColorTurmeric50: palette.augmentColor({
      color: {
        main: '#F39E0C',
      },
    }),
    ixColorJade: palette.augmentColor({
      color: {
        main: '#6B9E78',
      },
    }),
    ixColorJade60: palette.augmentColor({
      color: {
        main: '#517B5C',
      },
    }),
    ixColorJade40: palette.augmentColor({
      color: {
        main: '#78C58C',
      },
    }),
    ixColorSapphire: palette.augmentColor({
      color: {
        main: '#47A1AD',
      },
    }),
    ixColorSapphire70: palette.augmentColor({
      color: {
        main: '#1F8290',
      },
    }),
    ixColorSapphire40: palette.augmentColor({
      color: {
        main: '#53C4D3',
      },
    }),
    ixColorAmethyst: palette.augmentColor({
      color: {
        main: '#634F7D',
      },
    }),
    ixColorAmethyst70: palette.augmentColor({
      color: {
        main: '#4A3B5E',
      },
    }),
    ixColorAmethyst30: palette.augmentColor({
      color: {
        main: '#B0A1C4',
      },
    }),
    ixColorGrey90: palette.augmentColor({
      color: {
        main: '#212223',
      },
    }),
    ixColorGrey80: palette.augmentColor({
      color: {
        main: '#414343',
      },
    }),
    ixColorGrey70: palette.augmentColor({
      color: {
        main: '#616364',
      },
    }),
    ixColorGrey60: palette.augmentColor({
      color: {
        main: '#808285',
      },
    }),
    ixColorGrey50: palette.augmentColor({
      color: {
        main: '#9A9C9E',
      },
    }),
    ixColorGrey40: palette.augmentColor({
      color: {
        main: '#BDBEC0',
      },
    }),
    ixColorGrey30: palette.augmentColor({
      color: {
        main: '#E1E2E3',
      },
    }),
    ixColorGrey20: palette.augmentColor({
      color: {
        main: '#E9E9EA',
      },
    }),
    ixColorGrey10: palette.augmentColor({
      color: {
        main: '#F0F1F1',
      },
    }),
    ixColorGrey5: palette.augmentColor({
      color: {
        main: '#F8F8F8',
      },
    }),
    ixColorError: palette.augmentColor({
      color: {
        main: '#C00015',
      },
    }),
    // new tokens end here
  },
  typography: {
    fontFamily: 'Inter',
    caption: {
      fontFamily: 'Inter',
      fontSize: 14,
      color: '#000000BD',
      letterSpacing: '0.4px',
      textAlign: 'center',
      paddingBottom: 5,
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState['aria-pressed'] === 'true' && {
            backgroundColor: '#0000000B',
            color: '#000000BC',
          }),
          ...(ownerState['aria-pressed'] === 'false' && {
            color: '#00000061',
          }),
          fontFamily: 'Roboto',
          border: '1px solid #E0E0E0',
          borderRadius: 4,
          fontWeight: 600,
          letterSpacing: 1.25,
          fontSize: 16,
          height: '50px',
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          letterSpacing: '0.4px',
          color: '#000000BD',
          fontSize: '16px',
          width: '30%',
          border: 'none',
        },
        body: {
          justify: {
            letterSpacing: '0.15px',
            color: '#00000061',
            width: '100%',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 500,
          fontFamily: 'Roboto',
          padding: '6px 16px',
          borderRadius: '4px',
          color: '#00000061',
          background: 'white',
          '&.Mui-selected': {
            color: '#018786',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#0000000B',
          color: '#00000099',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: '15px',
          marginRight: 'auto',
          paddingBottom: 0,
          marginTop: 0,
          fontWeight: 500,
        },
      },
    },
  },
});

export default theme;
