import { Link, NavLink } from 'react-router-dom';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Stack from '../../atoms/Stack';
import Typography from '../../atoms/Typography';
import IconButton from '../../atoms/IconButton';
import UserIcon from '../../atoms/Icons/UserIcon';
import Menu from '../Menu';
import { MouseEvent, useState } from 'react';

type AppHeaderProps = {
  employeeNumber?: string;
  employeeFullName?: string;
};

const StyledWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.ixColorGrey5.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '80px',
  padding: '0 64px',
  position: 'relative',
}));

const StyledMessage = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  '&.active li': {
    backgroundColor: theme.palette.ixColorGrey10.main,
  },
}));

const AppHeader = (props: AppHeaderProps) => {
  const { employeeNumber, employeeFullName } = props;
  const [menuTrigger, setMenuTrigger] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuTrigger(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuTrigger(null);
  };

  const open = Boolean(menuTrigger);

  return (
    <StyledWrapper>
      <Box sx={{ height: '30px' }}>
        <Link to="/">
          <img src="/images/logo.png" />
        </Link>
      </Box>
      <StyledMessage>
        <Typography variant="body-2">Hello, {employeeFullName}</Typography>
      </StyledMessage>
      <Box>
        <Stack gap="medium">
          <IconButton color="ixColorOnyx" onClick={handleOpenMenu}>
            <UserIcon />
          </IconButton>
          <Menu
            anchorEl={menuTrigger}
            open={open}
            onClose={handleCloseMenu}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <StyledNavLink to={`/employees/personal-details/${employeeNumber}`}>
              <Menu.Item onClick={handleCloseMenu}>
                <Typography>Personal Details</Typography>
              </Menu.Item>
            </StyledNavLink>
            <StyledNavLink to={`/employees/job-details/${employeeNumber}`}>
              <Menu.Item onClick={handleCloseMenu}>
                <Typography>Job Details</Typography>
              </Menu.Item>
            </StyledNavLink>
            <StyledNavLink to={`/employees/office-days/${employeeNumber}`}>
              <Menu.Item onClick={handleCloseMenu}>
                <Typography>Office Days</Typography>
              </Menu.Item>
            </StyledNavLink>
            <StyledNavLink to={`/employees/week-days/${employeeNumber}`}>
              <Menu.Item onClick={handleCloseMenu}>
                <Typography>Week Days</Typography>
              </Menu.Item>
            </StyledNavLink>
          </Menu>
        </Stack>
      </Box>
    </StyledWrapper>
  );
};

export default AppHeader;
