import { ReactNode } from 'react';
import { styled, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

import Typography from '../Typography';

type ChipProps = {
  label: ReactNode;
  size?: 'small' | 'default';
  variant?: 'filled' | 'outlined';
  color?: 'dark' | 'grey' | 'light';
};

const generateSizeStyles = (size: ChipProps['size']) => {
  switch (size) {
    case 'small':
      return {
        padding: '4px',
      };
    case 'default':
    default:
      return {
        padding: '8px',
      };
  }
};

const generateColorAndVariantStyles = (
  color: ChipProps['color'],
  variant: ChipProps['variant'],
  theme: Theme
) => {
  switch (color) {
    case 'dark':
      return {
        backgroundColor:
          variant === 'filled'
            ? theme.palette.ixColorWave.main
            : theme.palette.ixColorTalc.main,
        border:
          variant === 'filled'
            ? 'none'
            : `1px solid ${theme.palette.ixColorWave.main}`,
        color:
          variant === 'filled'
            ? theme.palette.ixColorTalc.main
            : theme.palette.ixColorWave.main,
        '&:hover': {
          backgroundColor:
            variant === 'filled'
              ? theme.palette.ixColorGrey80.main
              : theme.palette.ixColorGrey5.main,
        },
      };
    case 'grey':
      return {
        backgroundColor:
          variant === 'filled'
            ? theme.palette.ixColorMist.main
            : theme.palette.ixColorTalc.main,
        border:
          variant === 'filled'
            ? 'none'
            : `1px solid ${theme.palette.ixColorMist.main}`,
        color: theme.palette.ixColorGrey90.main,
        '&:hover': {
          backgroundColor:
            variant === 'filled'
              ? theme.palette.ixColorGrey30.main
              : theme.palette.ixColorGrey5.main,
        },
      };
    case 'light':
    default:
      return {
        backgroundColor: theme.palette.ixColorTalc.main,
        border:
          variant === 'filled'
            ? 'none'
            : `1px solid ${theme.palette.ixColorGrey30.main}`,
        color: theme.palette.ixColorGrey90.main,
        '&:hover': {
          backgroundColor: theme.palette.ixColorGrey5.main,
        },
      };
  }
};

const StyledChip = styled(Box)<Omit<ChipProps, 'label'>>(
  ({ size, variant, color, theme }) => ({
    borderRadius: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    ...generateSizeStyles(size),
    ...generateColorAndVariantStyles(color, variant, theme),
    span: {
      display: 'flex',
    },
  })
);

const Chip = (props: ChipProps) => {
  const { label, size = 'default', variant = 'filled', color = 'dark' } = props;

  return (
    <StyledChip size={size} variant={variant} color={color}>
      <Typography variant="caption" color="inherit">
        {label}
      </Typography>
    </StyledChip>
  );
};

export default Chip;
