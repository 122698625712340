import * as Yup from 'yup';

import { Box, InputAdornment, Stack } from '@mui/material';
import {
  Colleague,
  SpecialLeaveType,
  useCancelVacationMutation,
  useCreateVacationMutation,
  useCreateVacationOnBehalfOfEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetBankHolidaysQuery,
  useGetEmailsQuery,
  useGetSpecialLeaveTypesQuery,
} from 'app-redux/apiSlice';
import { CommonFields, LEAVE_TYPES } from '..';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Form, {
  FormProvider,
  useForm,
  yupResolver,
} from 'components/atoms/Form';
import { HAS_ACCESS, HasAccess, hasAccess } from 'utils/roleManagement';
import {
  Leave,
  getWorkingDaysBetweenStartDateEndDate,
  isBankHoliday,
  isDayOfWeekend,
} from 'utils/calendar-utils';
import { isErrorWithMessage, isFetchBaseQueryError } from 'utils/api';
import {
  setEndDateSelection,
  setStartDateSelection,
} from 'app-redux/storeCalendarSlice';
import { useDispatch, useSelector } from 'react-redux';

import Alert from 'components/molecules/Alert';
import Button from 'components/atoms/Button';
import ChatIcon from 'components/atoms/Icons/ChatIcon';
import FocusIcon from 'components/atoms/Icons/FocusIcon';
import FormDatePicker from 'components/atoms/Form/components/FormDatePicker';
import FormInput from 'components/atoms/Form/components/FormInput';
import FormSelect from 'components/atoms/Form/components/FormSelect';
import FormSubmit from 'components/atoms/Form/components/FormSubmit';
import FormTagInput from 'components/atoms/Form/components/FormTagInput';
import Modal from 'components/molecules/Modal';
import { RootState } from 'app-redux/store';
import Spinner from 'components/molecules/Spinner';
import Typography from 'components/atoms/Typography';
import UserIcon from 'components/atoms/Icons/UserIcon';
import UserPlusIcon from 'components/atoms/Icons/UserPlusIcon';
import { VacationModel } from 'app-redux/apiSlice';
import { clearRangeSelection } from 'app-redux/storeCalendarSlice';
import { close as closeBookLeaveForm } from 'app-redux/storeDisclosureSlice';
import { convertToUTCTime } from 'utils/convertToUTCTime';
import { openErrorSnackbar } from 'app-redux/storeSnackbarSlice';
import { openSuccessSnackbar } from 'app-redux/storeSnackbarSlice';
import { selectUserEmployeeData } from 'app-redux/apiSlice';
import { sortColleaguesListAlphabetically } from 'utils/SortArrayAlphatbetically';
import { useDebounce } from 'hooks/useDebounce';
import useDisclosure from 'hooks/useDisclosure';

type LeaveFormProps = {
  leaveType: LEAVE_TYPES;
  isEditing?: boolean;
  vacation?: Leave;
  onCloseEdit?: () => void;
  setCommonFields?: Dispatch<SetStateAction<CommonFields>>;
  commonFields?: CommonFields;
};

type INormalLeaveForm = {
  specialLeaveTypeId?: number;
  employeeId?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  comment?: string;
  cc: string[];
};

// Constants and ENUMs
const bookLeaveFormPopperStoreKey = 'bookLeaveFormPopper';
const now = new Date();
const tomorrow = new Date();
tomorrow.setDate(now.getDate() + 1);

const LeaveForm = (props: LeaveFormProps) => {
  const {
    leaveType,
    isEditing = false,
    vacation,
    onCloseEdit,
    commonFields,
    setCommonFields,
  } = props;
  const isHRorAdmin = hasAccess([...HAS_ACCESS.HR, ...HAS_ACCESS.ADMIN]);
  const isUser = hasAccess(HAS_ACCESS.USER);

  const dispatch = useDispatch();
  const minDate = isHRorAdmin ? new Date(now.getFullYear(), 0, 1) : tomorrow;
  const currentUser = useSelector(selectUserEmployeeData);
  const dateSelectionFromCalendar = useSelector(
    (state: RootState) => state.calendar.dateSelection
  );
  const formIsOpen = useSelector(
    (state: RootState) => state.disclosure[bookLeaveFormPopperStoreKey]
  );
  const year = useSelector(
    (state: RootState) => state.calendarFilter.selectedYear
  );
  const [emailSearchQuery, setEmailSearchQuery] = useState('');
  const debouncedEmailSeachQuery = useDebounce(emailSearchQuery, 500);
  const { data: emailList } = useGetEmailsQuery(debouncedEmailSeachQuery);
  const arrayOfUniqueEmails = [...new Set(emailList)];

  const {
    open: openDeleteConfirm,
    close: closeDeleteConfirm,
    isOpen: isOpenDeleteConfirm,
  } = useDisclosure();

  const startDateSelectionAsNumber = dateSelectionFromCalendar.dateStart;
  const endDateSelectionAsNumber = dateSelectionFromCalendar.dateEnd;
  const startDateSelectionAsDate = new Date(
    dateSelectionFromCalendar.dateStart
  );
  const endDateSelectionAsDate = new Date(dateSelectionFromCalendar.dateEnd);

  // TODO: refactor
  const isVacationDeleteable = () => {
    if (!vacation) {
      return false;
    }

    if (isUser) {
      return (
        vacation.startDate.getTime() > new Date().getTime() && vacation.isMyself
      );
    }

    return true;
  };

  // Form
  const defaultValues: INormalLeaveForm = {
    employeeId: commonFields?.employeeId || '',
    startDate:
      vacation?.startDate ||
      (startDateSelectionAsNumber > 0 ? startDateSelectionAsDate : null),
    endDate:
      vacation?.endDate ||
      (endDateSelectionAsNumber > 0 ? endDateSelectionAsDate : null),
    comment: vacation?.comment || commonFields?.comment || '',
    cc: vacation?.cc.split(',').filter(Boolean) || commonFields?.cc || [],
  };

  const formSchema = Yup.object().shape({
    employeeId: Yup.string().when([], {
      is: () => hasAccess(HAS_ACCESS.HR),
      then: () => Yup.string().required('Person is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
    specialLeaveTypeId: Yup.string().when([], {
      is: () => leaveType === LEAVE_TYPES.SPECIAL,
      then: () => Yup.string().required('Leave type is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
    startDate: Yup.date()
      .required('Start date is required.')
      .typeError('Start date is required.'),
    endDate: Yup.date()
      .required('End date is required.')
      .typeError('End date is required.'),
    comment: Yup.string(),
    cc: Yup.array()
      .of(
        Yup.string()
          .email(({ value }) => `${value} is not a valid email.`)
          .matches(
            /@thoughtworks.com$/gi,
            ({ value }) => `${value} must have thoughtworks domain.`
          )
          .test('valid', 'You can not add your own email address.', value => {
            if (currentUser?.email === value) {
              return false;
            }

            return true;
          })
      )
      .min(1, 'Please enter at least 1 email.')
      .required('CC is required.'),
  });
  const methods = useForm<INormalLeaveForm>({
    resolver: yupResolver(formSchema),
    defaultValues,
    shouldUnregister: true,
  });
  const startDateValue = methods.watch('startDate');
  const endDateValue = methods.watch('endDate');
  const employeeIdValue = methods.watch('employeeId');
  const specialLeaveTypeIdValue = methods.watch('specialLeaveTypeId');
  const ccValue = methods.watch('cc');
  const commentValue = methods.watch('comment');

  const onSubmitHandler = methods.handleSubmit(async values => {
    const payload: VacationModel = {
      type: leaveType,
      specialLeaveType: specialLeaves?.find(
        (leave: SpecialLeaveType) =>
          leave.id === Number(values.specialLeaveTypeId)
      ),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      startDate: convertToUTCTime(values.startDate!),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      endDate: convertToUTCTime(values.endDate!),
      comment: values.comment || '',
      cc: values.cc.join(','),
      status: 'approved',
      emailRequester: currentUser?.email || '',
      emailEmployee: currentUser?.email || '',
      employeeNumber: values.employeeId || currentUser?.employeeNumber || '',
    };
    try {
      if (isHRorAdmin) {
        await createVacationAsHR(payload).unwrap();
      } else {
        await createVacationAsUser(payload).unwrap();
      }
      dispatch(openSuccessSnackbar('Leave created successfully.'));
      dispatch(closeBookLeaveForm(bookLeaveFormPopperStoreKey));
      dispatch(clearRangeSelection());
      methods.reset();
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        const errMsg = (err.data as { error: string }).error;
        dispatch(openErrorSnackbar(errMsg));
      } else if (isErrorWithMessage(err)) {
        dispatch(openErrorSnackbar(err.message));
      }
    }
  });
  // Queries
  const { data: bankHolidays = [] } = useGetBankHolidaysQuery('ROU');
  const { data: employees = [], isLoading: isLoadingEmployees } =
    useGetAllEmployeesQuery(year, {
      skip: isUser,
    });
  const { data: specialLeaves = [], isLoading: isLoadingLeaveTypes } =
    useGetSpecialLeaveTypesQuery(undefined, {
      skip: isUser && leaveType !== LEAVE_TYPES.SPECIAL,
    });

  // Mutations
  const [createVacationAsHR, resultAsHR] =
    useCreateVacationOnBehalfOfEmployeeMutation();
  const [createVacationAsUser, resultAsUser] = useCreateVacationMutation();
  const [deleteVacation, deleteResult] = useCancelVacationMutation();

  const handleDeleteVacation = async () => {
    try {
      if (!vacation) {
        return;
      }

      await deleteVacation(vacation.id);
      dispatch(openSuccessSnackbar('Leave deleted successfully.'));
      onCloseEdit?.();
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        const errMsg = (err.data as { error: string }).error;
        dispatch(openErrorSnackbar(errMsg));
      } else if (isErrorWithMessage(err)) {
        dispatch(openErrorSnackbar(err.message));
      }
    }
  };

  const handleDisableDates = useCallback(
    (day: Date) => {
      return isDayOfWeekend(day) || isBankHoliday(day, bankHolidays);
    },
    [bankHolidays]
  );

  const handleDoNotDisableDates = () => {
    return false;
  };

  const renderEmployeeSelectLabel = (employee: Colleague) => {
    if (leaveType === LEAVE_TYPES.NORMAL) {
      if (employee.employeeId === currentUser?.employeeNumber) {
        return `${employee.fullName} (myself) (${
          employee.remainingVacationDays
        } ${employee.remainingVacationDays > 1 ? 'days' : 'day'} available)`;
      }

      return `${employee.fullName} (${employee.remainingVacationDays} ${
        employee.remainingVacationDays > 1 ? 'days' : 'day'
      } available)`;
    }

    if (employee.employeeId === currentUser?.employeeNumber) {
      return `${employee.fullName} (myself)`;
    }

    return employee.fullName;
  };

  const renderSpecialLeaveSelectLabel = (leave: SpecialLeaveType) => {
    if (leave.duration) {
      return `${leave.name} (${leave.duration} days)`;
    }

    return `${leave.name} (flexible duration)`;
  };

  const isFreeDay = useCallback(
    (date: Date) => isDayOfWeekend(date) || isBankHoliday(date, bankHolidays),
    [bankHolidays]
  );

  const endDateAfterDuration = useCallback(
    (specialLeave: SpecialLeaveType, manualStartDate?: Date | null) => {
      const currentStartDate = manualStartDate || startDateValue;
      if (!currentStartDate) {
        return;
      }

      const days = specialLeave?.duration || 1;
      const daysToAdd = days >= 0 ? days - 1 : days;

      const modifiedEndDate = new Date();
      modifiedEndDate.setTime(currentStartDate.getTime());
      modifiedEndDate.setDate(currentStartDate.getDate() + daysToAdd);

      const iterationDate = new Date(currentStartDate);

      while (iterationDate <= modifiedEndDate) {
        if (isFreeDay(iterationDate)) {
          modifiedEndDate.setDate(modifiedEndDate.getDate() + 1);
        }
        iterationDate.setDate(iterationDate.getDate() + 1);
      }

      return modifiedEndDate;
    },
    [isFreeDay, startDateValue]
  );

  const sortedEmployeesList = useMemo(() => {
    if (employees.length > 0) {
      const list = employees.slice().sort(sortColleaguesListAlphabetically);

      const mySelf = employees.find(
        (employee: Colleague) =>
          employee.employeeId === currentUser?.employeeNumber
      );
      if (mySelf !== undefined) {
        const index = list.indexOf(mySelf);
        list.splice(index, 1);
        list.unshift(mySelf);
      }

      return list;
    }

    return [];
  }, [employees, currentUser?.employeeNumber]);

  const requestedVacationDays = useMemo(() => {
    if (startDateValue && endDateValue && bankHolidays.length > 0) {
      return getWorkingDaysBetweenStartDateEndDate(
        startDateValue,
        endDateValue,
        bankHolidays
      );
    }

    return [];
  }, [startDateValue, endDateValue, bankHolidays]);

  const selectedEmployee = useMemo(
    () => employees.find(employee => employee.employeeId === employeeIdValue),
    [employees, employeeIdValue]
  );

  const hasEnoughVacationDays = useMemo(() => {
    if (selectedEmployee && leaveType === LEAVE_TYPES.NORMAL) {
      return (
        selectedEmployee.remainingVacationDays >= requestedVacationDays.length
      );
    }

    return true;
  }, [selectedEmployee, requestedVacationDays, leaveType]);

  // Form Value Effects
  useEffect(() => {
    if (startDateValue) {
      const selectedSpecialLeave = specialLeaves?.find(
        (leave: SpecialLeaveType) => leave.id === specialLeaveTypeIdValue
      );
      const hasDuration = selectedSpecialLeave?.duration;
      if (hasDuration) {
        const modifiedEndDate = endDateAfterDuration(selectedSpecialLeave);
        methods.setValue('endDate', modifiedEndDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialLeaveTypeIdValue]);

  useEffect(() => {
    const { isDirty } = methods.formState;
    if (!isDirty) {
      return;
    }

    if (specialLeaveTypeIdValue && startDateValue) {
      const selectedSpecialLeave = specialLeaves?.find(
        (leave: SpecialLeaveType) => leave.id === specialLeaveTypeIdValue
      );
      const hasDuration = selectedSpecialLeave?.duration;
      if (hasDuration) {
        const modifiedEndDate = endDateAfterDuration(selectedSpecialLeave);
        methods.setValue('endDate', modifiedEndDate);
      } else {
        methods.setValue('endDate', startDateValue);
      }
    } else if (endDateValue) {
      methods.setValue('endDate', null);
    } else {
      methods.setValue('endDate', startDateValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue]);

  // Form Values Error Effects
  // TODO: Make improvement for custom errors.
  useEffect(() => {
    const { isDirty } = methods.formState;
    if (isDirty) {
      if (startDateValue) {
        if (startDateValue <= new Date() && isUser) {
          methods.setError('startDate', {
            message: 'The past date cannot be selected.',
          });
        } else if (handleDisableDates(startDateValue)) {
          methods.setError('startDate', {
            message: 'Holidays and weekends cannot be selected.',
          });
        } else if (endDateValue && startDateValue > endDateValue) {
          methods.setError('startDate', {
            message: 'Start date must be less than end date.',
          });
        } else if (!hasEnoughVacationDays) {
          methods.setError('startDate', {
            message: 'Number of days greater than available days',
          });
        } else {
          methods.clearErrors('startDate');
          methods.clearErrors('endDate');
        }
      } else {
        if (methods.formState.isDirty) {
          methods.clearErrors('startDate');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue, endDateValue]);

  useEffect(() => {
    const { isDirty } = methods.formState;
    if (isDirty) {
      if (endDateValue) {
        if (endDateValue <= new Date() && isUser) {
          methods.setError('endDate', {
            message: 'The past date cannot be selected.',
          });
        } else if (handleDisableDates(endDateValue)) {
          methods.setError('endDate', {
            message: 'Holidays and weekends cannot be selected.',
          });
        } else if (startDateValue && startDateValue > endDateValue) {
          methods.setError('endDate', {
            message: 'End date must be bigger than start date.',
          });
        } else if (!hasEnoughVacationDays) {
          methods.setError('startDate', {
            message: 'Number of days greater than available days',
          });
          methods.setError('endDate', {
            message: ' ',
          });
        } else {
          methods.clearErrors('startDate');
          methods.clearErrors('endDate');
        }
      } else {
        if (methods.formState.isDirty) {
          methods.clearErrors('endDate');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateValue]);

  // Sync calendar and form dates selections
  useEffect(() => {
    if (formIsOpen) {
      dispatch(setStartDateSelection(startDateValue?.getTime() || 0));
      dispatch(
        setEndDateSelection(
          endDateValue
            ? endDateValue?.getTime()
            : startDateValue?.getTime() || 0
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue, endDateValue]);

  useEffect(() => {
    if (startDateValue && endDateValue && !hasEnoughVacationDays) {
      methods.setError('startDate', {
        message: 'Number of days greater than available days',
      });
      methods.setError('endDate', {
        message: ' ',
      });
    } else {
      if (methods.formState.isDirty) {
        methods.clearErrors('startDate');
        methods.clearErrors('endDate');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue, endDateValue, employeeIdValue]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      setCommonFields?.(curr => ({
        ...curr,
        employeeId: employeeIdValue || '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeIdValue]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      setCommonFields?.(curr => ({
        ...curr,
        cc: ccValue || [],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccValue]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      setCommonFields?.(curr => ({
        ...curr,
        comment: commentValue || '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentValue]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmitHandler} noValidate autoComplete="off">
        {leaveType === LEAVE_TYPES.SPECIAL &&
          !isEditing && ( // TODO: should we edit leave type? if yes BE needed.
            <FormSelect
              name="specialLeaveTypeId"
              label="Leave Type"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FocusIcon />
                  </InputAdornment>
                ),
              }}
            >
              {isLoadingLeaveTypes && <Spinner />}
              {specialLeaves.length > 0 &&
                specialLeaves.map(leave => (
                  <FormSelect.MenuItem value={leave.id} key={leave.id}>
                    {renderSpecialLeaveSelectLabel(leave)}
                  </FormSelect.MenuItem>
                ))}
            </FormSelect>
          )}
        {!isEditing && ( // TODO: should we edit leave type? if yes BE needed.
          <HasAccess roles={HAS_ACCESS.HR}>
            <FormSelect
              name="employeeId"
              label="Person"
              placeholder="Select Person"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <UserIcon />
                  </InputAdornment>
                ),
              }}
            >
              {isLoadingEmployees && <Spinner />}
              {sortedEmployeesList.length > 0 &&
                sortedEmployeesList.map(employee => (
                  <FormSelect.MenuItem
                    value={employee.employeeId}
                    key={employee.employeeId}
                  >
                    {renderEmployeeSelectLabel(employee)}
                  </FormSelect.MenuItem>
                ))}
            </FormSelect>
          </HasAccess>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <FormDatePicker
            readOnly={isEditing}
            name="startDate"
            label="Start Date"
            minDate={minDate}
            // {specialLeaves. == 'Medical / sick leave' || vacation.specialLeaveType == 'Parental leave'} ? null :
            shouldDisableDate={
              specialLeaveTypeIdValue == 1 ||
              specialLeaveTypeIdValue == 2 ||
              specialLeaveTypeIdValue == 3 ||
              specialLeaveTypeIdValue == 4
                ? handleDoNotDisableDates
                : handleDisableDates
            }
          />
          <Box sx={{ margin: '0 20px 30px 20px' }}>-</Box>
          <FormDatePicker
            readOnly={isEditing}
            name="endDate"
            label="End Date"
            defaultCalendarMonth={startDateValue || new Date()}
            minDate={startDateValue || minDate}
            shouldDisableDate={
              specialLeaveTypeIdValue == 1 ||
              specialLeaveTypeIdValue == 2 ||
              specialLeaveTypeIdValue == 3 ||
              specialLeaveTypeIdValue == 4
                ? handleDoNotDisableDates
                : handleDisableDates
            }
          />
        </Box>
        <FormInput
          name="comment"
          label="Comment"
          InputProps={{
            readOnly: isEditing,
            startAdornment: (
              <InputAdornment position="start">
                <ChatIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormTagInput
          onSearch={setEmailSearchQuery}
          options={arrayOfUniqueEmails}
          readonly={isEditing}
          name="cc"
          label="CC"
          placeholder="example@thoughtworks.com"
          prefixIcon={<UserPlusIcon />}
        />
        {/* TODO: open when BE implement */}
        {/* {leaveType === LEAVE_TYPES.SPECIAL && (
          <FormUploadInput name='vacationFiles' />
        )} */}
        {!isEditing && <FormSubmit>Create</FormSubmit>}
      </Form>
      {isEditing && isVacationDeleteable() && (
        <Stack justifyContent="end" flexDirection="row">
          <Modal open={isOpenDeleteConfirm} onClose={closeDeleteConfirm}>
            <Stack spacing={3} sx={{ width: '300px' }}>
              {deleteResult.isLoading && (
                <Spinner fullPage text="Deleting Vacation..." />
              )}
              <Typography variant="h5">Are you sure?</Typography>
              <Typography variant="body-2">
                You are about to delete this entry.
              </Typography>
              <Stack flexDirection="row" justifyContent="space-between">
                <Button onClick={handleDeleteVacation} variant="destructive">
                  Confirm
                </Button>
                <Button onClick={closeDeleteConfirm} variant="text">
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Modal>
          <Button
            sx={{ marginRight: '16px' }}
            variant="destructive"
            onClick={openDeleteConfirm}
          >
            Delete
          </Button>
          <Button onClick={() => onCloseEdit?.()} variant="text">
            Close
          </Button>
        </Stack>
      )}
      {isEditing && !isVacationDeleteable() && vacation?.isMyself && (
        <Alert
          fluid
          message="You can not edit or delete vacations from the past. Please contact your HR Representative or administrator."
          variant="information"
        />
      )}
      {(resultAsHR.isLoading || resultAsUser.isLoading) && (
        <Spinner fullPage text="Adding Vacations..." />
      )}
    </FormProvider>
  );
};

export default LeaveForm;
