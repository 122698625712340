import { ReactNode, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import Button, { ButtonProps } from '../../../Button';
import Stack from '../../../Stack';

type FormSubmitProps = {
  align?: 'left' | 'right';
  disabled?: boolean;
  variant?: ButtonProps['variant'];
  otherActions?: ReactNode[];
} & PropsWithChildren;

const FormSubmit = (props: FormSubmitProps) => {
  const {
    children,
    align = 'right',
    disabled,
    variant = 'text',
    otherActions = [],
  } = props;

  return (
    <Box sx={{ width: '100%', textAlign: align }}>
      <Stack>
        {otherActions}
        <Button variant={variant} type="submit" disabled={disabled}>
          {children}
        </Button>
      </Stack>
    </Box>
  );
};

export default FormSubmit;
