import MuiModal, { ModalProps as MuiModalProps } from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export type ModalProps = MuiModalProps;

const StyledBackdrop = styled(Backdrop)(() => ({
  backdropFilter: 'blur(2px)',
}));

const StyledModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.ixColorTalc.main,
  borderRadius: '4px',
  boxShadow: `0px 3px 6px ${theme.palette.ixColorGrey70.main}`,
  padding: '24px',
}));

const Modal = (props: ModalProps) => {
  const { open, children, ...rest } = props;

  return (
    <MuiModal
      closeAfterTransition
      open={open}
      slots={{ backdrop: StyledBackdrop }}
      {...rest}
    >
      <Fade in={open}>
        <StyledModalContainer>{children}</StyledModalContainer>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
