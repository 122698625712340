import { FC, ReactNode } from 'react';
import { HasAccess, PermifyProvider } from '@permify/react-role';

type PropsWithChildren = {
  children: ReactNode;
};

const ROLES = {
  USER: ['USER'],
  REPORTER: ['REPORTER'],
  HR: ['HR', 'USER', 'REPORTER'],
  ADMIN: ['ADMIN', 'HR', 'USER', 'REPORTER'],
};

const HAS_ACCESS = {
  ADMIN: ['ADMIN'],
  HR: ['HR'],
  REPORTER: ['REPORTER'],
  USER: ['USER'],
};

const hasAccess = (accesses: string[]) => {
  let allowed = false;
  const userInfo = JSON.parse(localStorage.getItem('__permifyUser') || '{}');

  if (userInfo?.roles?.length > 0) {
    accesses.forEach(access => {
      if (userInfo?.roles?.includes(access)) {
        allowed = true;
      }
    });
  }

  return allowed;
};

const RoleProvider = ({ children }: PropsWithChildren) => {
  const TypedProvider = PermifyProvider as FC<PropsWithChildren>;

  return <TypedProvider>{children}</TypedProvider>;
};

export { HasAccess, HAS_ACCESS, ROLES, RoleProvider, hasAccess };
