/* eslint-disable @typescript-eslint/no-explicit-any */
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const getXSRFToken = (): string => {
  return localStorage.getItem('csrf') || '';
};

export const redirectToLogin = (): void => {
  const baseURL = '/api/v1';
  let redirect_url = baseURL + '/user/login';

  //FIXME: temporary fix for local dev
  if (window.location.host == 'localhost:3000') {
    redirect_url = `https://localhost${redirect_url}`;
  }
  window.location.assign(redirect_url);
};

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}
