import { useFormContext, Controller } from 'react-hook-form';

import Select, { InternalSelectProps } from '../../../Select';

type FormSelectProps = {
  name: string;
  shouldUnregister?: boolean;
} & InternalSelectProps;

const FormSelect = (props: FormSelectProps) => {
  const { name, shouldUnregister, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <Select
          {...field}
          {...rest}
          error={!!errors[name]}
          helperText={
            errors[name] ? (errors[name]?.message as unknown as string) : ''
          }
        />
      )}
    />
  );
};

FormSelect.MenuItem = Select.MenuItem;

export default FormSelect;
