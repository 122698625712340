import MuiTypography from '@mui/material/Typography';
import React from 'react';
import { TableCell } from '@mui/material';

const LeavePeriodRow = ({ period }: { period: string }) => {
  return (
    <TableCell
      variant="head"
      align="justify"
      colSpan={4}
      sx={{
        paddingBottom: 0,
        color: '#00000061',
        zIndex: 0,
        top: '12px',
      }}
    >
      <MuiTypography
        variant="h6"
        textAlign="left"
        color="#00000061"
        fontSize="20px"
      >
        {period}
      </MuiTypography>
    </TableCell>
  );
};

export default LeavePeriodRow;
