import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, GlobalStyles } from '@mui/material';

import { store } from 'app-redux/store';
import { Secured } from 'containers/Layout/Secured/Secured';
import reportWebVitals from './reportWebVitals';
import './assets/default.scss';

import theme from 'theme';
import { RoleProvider } from 'utils/roleManagement';
import ApplicationRoutes from './ApplicationRoutes';

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
  <StrictMode>
    <Provider store={store}>
      <RoleProvider>
        <Secured>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles styles={{ a: { textDecoration: 'none' } }} />
            <ApplicationRoutes />
          </ThemeProvider>
        </Secured>
      </RoleProvider>
    </Provider>
  </StrictMode>
);

reportWebVitals();
