import { styled } from '@mui/material/styles';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';

type StyledIconButtonProps = MuiIconButtonProps & {
  invert?: boolean;
};

const StyledIconButton = styled(MuiIconButton)<StyledIconButtonProps>(
  ({ invert, theme }) => ({
    transition: 'all 0.3s ease',
    ...(invert && { color: theme.palette.ixColorTalc.main }),
    '&:hover': {
      backgroundColor: invert ? '#FFFFFF0D' : 'rgba(0, 0, 0, 0.04)',
    },
    '&:active': {
      backgroundColor: invert ? '#FFFFFF4D' : 'rgba(0, 0, 0, 0.04)',
    },
  })
);

const IconButton = (props: StyledIconButtonProps) => {
  const { children, invert = false } = props;

  return (
    <StyledIconButton {...props} invert={invert}>
      {children}
    </StyledIconButton>
  );
};

export default IconButton;
