import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';

import Navigation from 'components/molecules/Navigation';
import PageLayout from 'components/molecules/PageLayout';
import Typography from 'components/atoms/Typography';
import ProgressBarWrapper from 'containers/Layout/ProgressBarWrapper';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.5,
};

const ReportLayout = () => {
  const { pathname } = useLocation();

  return (
    <PageLayout
      sidebar={
        <Box>
          <Typography variant="h5" sx={{ marginBottom: '28px' }}>
            Reports
          </Typography>
          <Navigation
            direction="vertical"
            items={[
              {
                text: 'Payroll / Timesheet',
                href: `/reports/payroll-timesheet`,
              },
              {
                text: 'Vacations',
                href: `/reports/vacations`,
              },
              {
                text: 'Working Hours',
                href: `/reports/working-hours`,
              },
              {
                text: 'Leave Balance Analysis',
                href: `/reports/leave-balance-analysis`,
              },
              // {
              //   text: 'Log',
              //   href: `/reports/log`,
              // },
            ]}
          />
        </Box>
      }
      leftSlot={<ProgressBarWrapper />}
    >
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Box sx={{ padding: '24px' }}>
          <Outlet />
        </Box>
      </motion.div>
    </PageLayout>
  );
};

export default ReportLayout;
