import { Client, Colleague, UserDTO } from 'app-redux/apiSlice';

export function sortClientListAlphabetically(client1: Client, client2: Client) {
  if (client1.projectName.toLowerCase() < client2.projectName.toLowerCase()) {
    return -1;
  }
  if (client1.projectName.toLowerCase() > client2.projectName.toLowerCase()) {
    return 1;
  }

  return 0;
}

export function sortColleaguesListAlphabetically(
  colleague1: Colleague,
  colleague2: Colleague
) {
  if (colleague1.fullName.toLowerCase() < colleague2.fullName.toLowerCase()) {
    return -1;
  }
  if (colleague1.fullName.toLowerCase() > colleague2.fullName.toLowerCase()) {
    return 1;
  }

  return 0;
}

export function sortEmployeesListAlphabetically(
  colleague1: UserDTO,
  colleague2: UserDTO
) {
  if (
    (colleague1.lastName || '').toLowerCase() <
    (colleague2.lastName || '').toLowerCase()
  ) {
    return -1;
  }
  if (
    (colleague1.lastName || '').toLowerCase() >
    (colleague2.lastName || '').toLowerCase()
  ) {
    return 1;
  }

  return 0;
}
