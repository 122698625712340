import { Box, Grid } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useLocation, Navigate } from 'react-router-dom';

import Day from '../Day/Day';
import { RootState, useSelector } from 'app-redux/store';
import {
  areTheseDatesEqual,
  dateRangeOverlaps,
  dateToString,
  DAYS_OF_WEEK,
  getEndDateCalendar,
  getStartDateCalendar,
  isBankHoliday,
  isBetweenTwoDates,
  isDayOfWeekend,
  Leave,
  MONTHS,
  numberOfDaysBetweenTwoDates,
  numberOfDaysUntilTheEndOfWeek,
  useScreenDim,
} from 'utils/calendar-utils';
import theme from 'theme';
import {
  BankHolidayResponse,
  useGetBankHolidaysQuery,
} from 'app-redux/apiSlice';
import { LeaveProps } from '../Leave';
import Spinner from 'components/molecules/Spinner';
import { HolidayProps } from '../BankHoliday';
import './GridView.scss';
import Typography from 'components/atoms/Typography';

function getBorderAndWidth(
  nrDaysTilVacEnd: number,
  startVacThisDay?: boolean,
  nrDaysTilWeekEnd?: number
): { border: string; width: number } {
  if (nrDaysTilWeekEnd == undefined) {
    return nrDaysTilVacEnd > 6
      ? startVacThisDay
        ? { border: 'leftBorder', width: 690 }
        : { border: '0', width: 690 }
      : startVacThisDay
      ? { border: 'both', width: (nrDaysTilVacEnd + 1) * 100 - 10 }
      : { border: 'rightBorder', width: (nrDaysTilVacEnd + 1) * 100 - 10 };
  }

  return nrDaysTilVacEnd > nrDaysTilWeekEnd
    ? { border: 'leftBorder', width: (nrDaysTilWeekEnd + 1) * 100 - 15 }
    : { border: 'both', width: (nrDaysTilVacEnd + 1) * 100 - 15 };
}

function representVacationsMonthlyView(vacations: Array<Leave>, thisDay: Date) {
  const components: LeaveProps[] = [];
  const more: Array<Leave> = [];
  let i = 0;

  while (components.length < 2 && i < vacations.length) {
    vacations[i].startDate.setHours(0, 0, 0, 0);
    vacations[i].endDate.setHours(0, 0, 0, 0);
    const nrDaysTilVacEnd = numberOfDaysBetweenTwoDates(
      thisDay,
      vacations[i].endDate
    );
    const startVacThisDay = areTheseDatesEqual(thisDay, vacations[i].startDate);
    const leaveArgs = getBorderAndWidth(
      nrDaysTilVacEnd,
      startVacThisDay,
      undefined
    );
    components.push({
      width: leaveArgs.width,
      borderType: leaveArgs.border,
      vacInformation: vacations[i],
      nrDaysRepresented: nrDaysTilVacEnd > 6 ? 7 : nrDaysTilVacEnd + 1,
    });
    i += 1;
  }
  more.push(...vacations.slice(i));

  return { more: more, leaves: components };
}

function transformInLeave(thisDay: Date, endDate: Date) {
  const nrDaysTilVacEnd = numberOfDaysBetweenTwoDates(thisDay, endDate);
  const nrDaysTilWeekEnd = numberOfDaysUntilTheEndOfWeek(thisDay);
  const leaveArgs = getBorderAndWidth(
    nrDaysTilVacEnd,
    undefined,
    nrDaysTilWeekEnd
  );

  return {
    width: leaveArgs.width,
    borderType: leaveArgs.border,
    nrDaysRepresented:
      nrDaysTilVacEnd > nrDaysTilWeekEnd
        ? nrDaysTilWeekEnd + 1
        : nrDaysTilVacEnd + 1,
  };
}

function renderDaysMonthView2(
  month: number,
  year: number,
  dim: 'sm' | 'md' | 'lg',
  leaves: { [day: string]: Array<Leave> },
  bankHolidayList: Array<BankHolidayResponse>
): JSX.Element {
  const startData = getStartDateCalendar(month, year);
  const endDate = getEndDateCalendar(month, year);
  const holidays = getBankHolidaysMap(bankHolidayList);

  //add the names of the week days
  let days = DAYS_OF_WEEK[dim].map((element, index) => (
    <Grid
      key={element + String(index)}
      item
      xs={1}
      sx={{
        overflow: 'hidden',
        display: 'flex',
        height: '50px',
        alignItems: 'flex-end',
        textTransform: 'uppercase',
        borderBottom: '1px solid ' + theme.palette.calendarFrame.borderColor,
      }}
      className="day-name"
    >
      <Typography
        variant="caption"
        textAlign="left"
        paddingLeft="16px"
        paddingBottom="8px"
      >
        {element}
      </Typography>
    </Grid>
  ));

  let monthIndex = startData.getMonth();
  let monthName = false;
  let p1 = { nrDaysOccupied: 0, vacId: '' };
  let p2 = { nrDaysOccupied: 0, vacId: '' };

  while (startData.getTime() <= endDate.getTime()) {
    let leavePoz1: LeaveProps | undefined;
    let leavePoz2: LeaveProps | undefined;
    const moreLeaves: Leave[] = [];
    p1 =
      p1.nrDaysOccupied > 0
        ? { nrDaysOccupied: p1.nrDaysOccupied - 1, vacId: p1.vacId }
        : { nrDaysOccupied: 0, vacId: '' };
    p2 =
      p2.nrDaysOccupied > 0
        ? { nrDaysOccupied: p2.nrDaysOccupied - 1, vacId: p2.vacId }
        : { nrDaysOccupied: 0, vacId: '' };

    if (monthIndex == (month + 1) % 12 && startData.getDate() == 1) {
      //check if we start the next month
      monthName = true;
    }
    const index = dateToString(startData);
    if (index in leaves) {
      if (startData.getDay() === 1) {
        const c = representVacationsMonthlyView(leaves[index], startData);
        if (
          isLeaveOverlappingHoliday(
            c.leaves[0]?.vacInformation,
            bankHolidayList
          ) ||
          isLeaveOverlappingHoliday(
            c.leaves[1]?.vacInformation,
            bankHolidayList
          )
        ) {
          p1 = { nrDaysOccupied: 0, vacId: '' };
          p2 = {
            nrDaysOccupied: c.leaves[0]?.nrDaysRepresented ?? 0,
            vacId: c.leaves[0]?.vacInformation.id ?? '',
          };
          leavePoz1 = undefined;
          leavePoz2 = c.leaves[0] ? { ...c.leaves[0] } : undefined;
          if (c.leaves[1]?.vacInformation) {
            moreLeaves.unshift(c.leaves[1].vacInformation);
          }
          moreLeaves.push(...c.more);
        } else {
          p1 = {
            nrDaysOccupied: c.leaves[0]?.nrDaysRepresented ?? 0,
            vacId: c.leaves[0]?.vacInformation.id ?? '',
          };
          p2 = {
            nrDaysOccupied: c.leaves[1]?.nrDaysRepresented ?? 0,
            vacId: c.leaves[1]?.vacInformation.id ?? '',
          };
          leavePoz1 = c.leaves[0] ? { ...c.leaves[0] } : undefined;
          leavePoz2 = c.leaves[1] ? { ...c.leaves[1] } : undefined;
          moreLeaves.push(...c.more);
        }
      } else {
        leaves[index].map(vac => {
          const isOverlappingHoliday = isLeaveOverlappingHoliday(
            vac,
            bankHolidayList
          );
          if (areTheseDatesEqual(vac.startDate, startData)) {
            if (p1.nrDaysOccupied === 0 && !isOverlappingHoliday) {
              const l = {
                ...transformInLeave(startData, vac.endDate),
                vacInformation: vac,
              };
              leavePoz1 = l;
              p1 = {
                nrDaysOccupied: l.nrDaysRepresented,
                vacId: l.vacInformation.id,
              };
            } else if (p2.nrDaysOccupied === 0) {
              const l = {
                ...transformInLeave(startData, vac.endDate),
                vacInformation: vac,
              };
              leavePoz2 = l;
              p2 = {
                nrDaysOccupied: l.nrDaysRepresented,
                vacId: l.vacInformation.id,
              };
            } else {
              moreLeaves.push(vac);
            }
          } else {
            if (p1?.vacId != vac.id && p2?.vacId != vac.id) {
              moreLeaves.push(vac);
            }
          }
        });
      }
    }
    days = days.concat(
      <Grid
        sx={{ height: 'calc(20% - 10px)' }}
        key={
          String(startData.getDate()) +
          '-' +
          String(startData.getMonth()) +
          '-' +
          String(startData.getFullYear())
        }
        item
        xs={1}
        className="day-box"
      >
        <Day
          monthName={monthName}
          isFromCurrentMonth={month === startData.getMonth()}
          isFreeDay={
            isDayOfWeekend(startData) ||
            isBankHoliday(startData, bankHolidayList)
          }
          bankHoliday={getBankHolidayProps(startData, holidays[index])}
          day={startData.getDate()}
          month={startData.getMonth()}
          year={startData.getFullYear()}
          leavePoz1={leavePoz1 ?? undefined}
          leavePoz2={leavePoz2}
          more={moreLeaves}
          bankHolidays={bankHolidayList}
          vacations={index in leaves ? leaves[index] : undefined}
        />
      </Grid>
    );
    monthName = false;
    startData.setDate(startData.getDate() + 1);
    monthIndex = startData.getMonth();
  }

  return (
    <>
      <Grid
        role="calendarGridMonthlyView"
        container
        spacing={0}
        sx={{ overflow: 'auto', height: '100%', alignContent: 'baseline' }}
        columns={7}
      >
        {days}
      </Grid>
    </>
  );
}

function getBankHolidayProps(
  date: Date,
  holiday: BankHolidayResponse
): HolidayProps | undefined {
  if (holiday) {
    const start = new Date(holiday.startDate);
    const end = new Date(holiday.endDate);
    if (areTheseDatesEqual(date, new Date(holiday.startDate))) {
      return {
        ...transformInLeave(date, end),
        bankHolidayInformation: holiday,
      };
    } else if (date.getDay() === 1 && isBetweenTwoDates(start, end, date)) {
      const nrDaysTilVacEnd = numberOfDaysBetweenTwoDates(date, end);
      const leaveArgs = getBorderAndWidth(nrDaysTilVacEnd, false, undefined);

      return {
        width: leaveArgs.width,
        borderType: leaveArgs.border,
        nrDaysRepresented: nrDaysTilVacEnd > 6 ? 7 : nrDaysTilVacEnd + 1,
        bankHolidayInformation: holiday,
      };
    }
  }

  return;
}

function isLeaveOverlappingHoliday(
  vac: Leave,
  holidays: BankHolidayResponse[]
): boolean {
  if (!vac) {
    return false;
  }

  const start = vac.startDate;
  const end = vac.endDate;

  return holidays.some((holiday: BankHolidayResponse) => {
    const holidayStartDate = new Date(holiday.startDate);
    const holidayEndDate = new Date(holiday.endDate);
    holidayStartDate.setHours(0, 0, 0);
    holidayEndDate.setHours(0, 0, 0);

    return dateRangeOverlaps(start, end, holidayStartDate, holidayEndDate);
  });
}

function getBankHolidaysMap(holidays: Array<BankHolidayResponse> = []) {
  return holidays.reduce(
    (hashMap: { [day: string]: BankHolidayResponse }, holiday) => {
      const startDate = new Date(holiday.startDate);
      const endDate = new Date(holiday.endDate);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      while (startDate.getTime() <= endDate.getTime()) {
        const index = dateToString(startDate);
        if (!(index in hashMap)) {
          hashMap[index] = holiday;
        }
        startDate.setDate(startDate.getDate() + 1);
      }

      return hashMap;
    },
    {}
  );
}

function markVacationDays(leaves: Array<Leave>) {
  const leavesOnDays: { [day: string]: Array<Leave> } = {};
  leaves.map(element => {
    element.startDate.setHours(0, 0, 0, 0);
    element.endDate.setHours(0, 0, 0, 0);
    const startDate = new Date(element.startDate);
    const endDate = new Date(element.endDate);

    while (startDate.getTime() <= endDate.getTime()) {
      const index = dateToString(startDate);
      if (!(index in leavesOnDays)) {
        leavesOnDays[index] = [];
      }
      leavesOnDays[index].push(element);
      startDate.setDate(startDate.getDate() + 1);
    }
  });

  return leavesOnDays;
}

function renderDaysMonthsMinimized(
  month: number,
  year: number,
  leaves: { [day: string]: Array<Leave> },
  bankHolidayList: Array<BankHolidayResponse>,
  isYearView = false
): JSX.Element {
  const startData = getStartDateCalendar(month, year);

  //add the names of the week days
  let days = DAYS_OF_WEEK['sm'].map((element, index) => (
    <Grid
      key={element + String(index) + String(month) + String(year)}
      item
      xs={1}
      sx={{
        overflow: 'hidden',
        display: 'flex',
        height: '40px',
        alignItems: 'flex-end',
        textTransform: 'uppercase',
        borderBottom: '1px solid ' + theme.palette.info.dark,
        mb: '5px',
      }}
    >
      <Typography variant="caption" width="100%" fontWeight="600">
        {element}
      </Typography>
    </Grid>
  ));

  for (let i = 0; i < 42; i++) {
    const index = dateToString(startData);
    days = days.concat(
      <Grid
        sx={{ height: isYearView ? '15%' : 'calc(20% - 10px)' }}
        key={
          String(startData.getDate()) +
          '-' +
          String(startData.getMonth()) +
          '-' +
          String(startData.getFullYear())
        }
        item
        xs={1}
        className="day-box"
      >
        <Day
          isFromCurrentMonth={month === startData.getMonth()}
          isFreeDay={
            isDayOfWeekend(startData) ||
            isBankHoliday(startData, bankHolidayList)
          }
          day={startData.getDate()}
          month={startData.getMonth()}
          year={startData.getFullYear()}
          vacations={index in leaves ? leaves[index] : undefined}
          bankHolidays={bankHolidayList}
        />
      </Grid>
    );
    startData.setDate(startData.getDate() + 1);
  }

  return (
    <Grid
      container
      spacing={0}
      sx={{ overflow: 'auto', height: '100%', alignContent: 'baseline' }}
      columns={7}
    >
      {days}
    </Grid>
  );
}

function renderDaysYearView(
  year: number,
  leaves: { [day: string]: Array<Leave> },
  bankHolidayList: Array<BankHolidayResponse>
): JSX.Element {
  return (
    <>
      <Grid
        role="calendarGridYearlyView"
        container
        rowGap={4}
        spacing={2}
        sx={{
          overflow: 'auto',
          height: '90%',
          alignContent: 'baseline',
          justifyContent: 'space-evenly',
          margin: 0,
        }}
      >
        {MONTHS.map((element, index) => (
          <Grid
            key={element + String(year)}
            item
            xs={11.6}
            sm={5.6}
            md={3.6}
            lg={2.6}
          >
            <Box display="flex" flexDirection="column" role="calendarMonth">
              <Typography fontWeight={700} textAlign="center">
                {element}
              </Typography>
              <>
                {renderDaysMonthsMinimized(
                  index,
                  year,
                  leaves,
                  bankHolidayList,
                  true
                )}
              </>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function GridView({ leaves }: { leaves: Leave[] }) {
  const location = useLocation();
  const {
    selectedYear: year,
    selectedMonth: month,
    periodViewType: periodView,
  } = useSelector((state: RootState) => state.calendarFilter);

  const {
    data: bankHolidayList = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetBankHolidaysQuery('ROU');

  const screenDimen = useScreenDim();
  let leavesmarked;

  // TODO: update with current UI and color schema
  let content: JSX.Element = (
    <Box sx={{ marginTop: 24 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ position: 'relative', color: '#018786' }}>
          <SentimentDissatisfiedIcon />
        </Box>
        <Box fontSize={16} color="#018786">
          No Data Found
        </Box>
      </Box>
    </Box>
  );

  if (isError) {
    content = <Navigate to="/error" state={{ referrer: location.pathname }} />;
  } else if (isLoading) {
    content = (
      <Box sx={{ marginTop: 24 }}>
        <Spinner text="Loading..." />
      </Box>
    );
  } else if (isSuccess) {
    if (leaves) {
      leavesmarked = markVacationDays(leaves);
    } else {
      leavesmarked = {};
    }
    if (periodView === 'year') {
      content = renderDaysYearView(year, leavesmarked, bankHolidayList);
    } else {
      content = renderDaysMonthView2(
        month,
        year,
        screenDimen,
        leavesmarked,
        bankHolidayList
      );
    }
  }

  return content;
}

export default GridView;
