import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import ArrowDownIcon from 'components/atoms/Icons/ArrowDownIcon';
import Typography from 'components/atoms/Typography';
import { access } from 'utils/objectAccessor';
import { visuallyHidden } from '@mui/utils';

type Pagination = {
  pageSize?: number;
  pageSizeOptions?: number[];
};

type TableOptions<TData> = {
  sticky?: boolean;
  keyIndex?: string;
  showPagination?: boolean;
  pagination?: Pagination;
  defaultOrderBy?: keyof TData;
  defaultOrder?: Order;
};

export type Column<TData> = {
  label: string;
  /* root field to read data. 
     for example const data = { creationDate: "12-12-2022" }
     Here "creationDate" is a root field.
  */
  dataIndex: keyof TData;
  /* if root field is object use accessors. 
     for example const data = { user: { job: { id: "1", jobName: "Developer" } } }
     Here "user" is a root field.
     for accessing "Developer", accessor is "job.jobName"
     for accessing "id", accessor is "job.id"
  */
  accessor?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  width?: number;
  minWidth?: number;
  /* Custom render method */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (value: any, row: TData) => ReactNode;
  orderBy?: keyof TData;
};

type TableProps<TData> = {
  data: TData[];
  columns: Column<TData>[];
  options?: TableOptions<TData>;
  onSort?: (order: Order, orderBy: keyof TData) => void;
};

type Order = 'asc' | 'desc';

const Table = <T,>(props: TableProps<T>) => {
  const { data, columns, options = {}, onSort } = props;
  const {
    sticky = true,
    keyIndex = 'id',
    showPagination = true,
    defaultOrder = 'asc',
    defaultOrderBy,
  } = options;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(options.pagination?.pageSize || 10);
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState<keyof T | undefined>(defaultOrderBy);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const createSortHandler =
    (newOrderBy: keyof T) => (event: MouseEvent<unknown>) => {
      handleRequestSort(event, newOrderBy);
    };

  const handleRequestSort = useCallback(
    (_event: MouseEvent<unknown>, newOrderBy: keyof T) => {
      const isAsc = orderBy === newOrderBy && order === 'asc';
      const toggledOrder = isAsc ? 'desc' : 'asc';
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
      onSort?.(toggledOrder, newOrderBy);
    },
    [order, orderBy, onSort]
  );

  // TODO: refactor
  const renderData = () => {
    if (showPagination) {
      return data
        .slice(page * pageSize, page * pageSize + pageSize)
        .map(row => {
          return (
            <TableRow key={row[keyIndex as keyof typeof row] as string}>
              {columns.map(column => {
                let value = row[column.dataIndex];
                if (typeof value === 'object' && column.accessor) {
                  value = access(value, column.accessor);
                }

                return (
                  <TableCell
                    key={column.dataIndex as string}
                    align={column.align}
                    sx={theme => ({
                      borderBottom: `2px solid ${theme.palette.ixColorGrey5.main}`,
                      padding: '24px',
                    })}
                  >
                    {column.render ? (
                      (column.render(value, row) as ReactNode)
                    ) : (
                      <Typography variant="body-1">
                        {value as string}
                      </Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        });
    }

    return data.map(row => {
      return (
        <TableRow key={row[keyIndex as keyof typeof row] as string}>
          {columns.map(column => {
            let value = row[column.dataIndex];
            if (typeof value === 'object' && column.accessor) {
              value = access(value, column.accessor);
            }

            return (
              <TableCell
                key={column.dataIndex as string}
                align={column.align}
                sx={theme => ({
                  borderBottom: `2px solid ${theme.palette.ixColorGrey5.main}`,
                  padding: '24px',
                })}
              >
                {column.render ? (
                  (column.render(value, row) as ReactNode)
                ) : (
                  <Typography variant="body-1">{value as string}</Typography>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  return (
    <>
      <TableContainer>
        <MuiTable
          stickyHeader={sticky}
          aria-label={sticky ? 'sticky table' : 'table'}
        >
          <TableHead sx={{ position: 'relative', zIndex: '0' }}>
            <TableRow>
              {columns.map(column =>
                column.orderBy ? (
                  <TableCell
                    key={column.dataIndex as string}
                    align={column.align}
                    sx={theme => ({
                      backgroundColor: theme.palette.ixColorGrey5.main,
                      minWidth: column.minWidth,
                      padding: '16px',
                      position: 'sticky',
                      width: column.width || 'auto',
                      maxWidth: column.width || 'auto',
                    })}
                    sortDirection={orderBy === column.orderBy ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.orderBy}
                      direction={orderBy === column.orderBy ? order : 'asc'}
                      onClick={createSortHandler(column.orderBy)}
                    >
                      <Typography variant="subtitle-1" color="ixColorGrey90">
                        {column.label}
                      </Typography>
                      {orderBy === column.orderBy ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    key={column.dataIndex as string}
                    align={column.align}
                    sx={theme => ({
                      backgroundColor: theme.palette.ixColorGrey5.main,
                      minWidth: column.minWidth,
                      padding: '16px',
                      position: 'sticky',
                      width: column.width || 'auto',
                      maxWidth: column.width || 'auto',
                    })}
                  >
                    <Typography variant="subtitle-1" color="ixColorGrey90">
                      {column.label}
                    </Typography>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>{renderData()}</TableBody>
        </MuiTable>
      </TableContainer>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={pageSize || 10}
          page={page || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            sx: {
              paddingTop: '4px',
              paddingRight: '6px',
              '> .MuiSelect-standard': { paddingRight: '32px !important' },
            },
            IconComponent: () => (
              <ArrowDownIcon
                sx={{
                  position: 'absolute',
                  right: '8px',
                  top: '6px',
                  zIndex: '-1',
                }}
              />
            ),
          }}
        />
      )}
    </>
  );
};

export default Table;
