import { Leave } from 'utils/calendar-utils';
import React from 'react';
import { TableCell } from '@mui/material';
import { vacationPeriodDisplay } from './utils';

const VacationPeriodCell = ({ vacation }: { vacation: Leave }) => {
  return <TableCell>{vacationPeriodDisplay(vacation)}</TableCell>;
};

export default VacationPeriodCell;
