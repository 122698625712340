import { createSvgIcon } from '@mui/material';

const SearchIcon = createSvgIcon(
  <>
    <path id="Path_257" data-name="Path 257" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_258"
      data-name="Path 258"
      d="M18.031,16.617,22.314,20.9,20.9,22.314l-4.282-4.283a9,9,0,1,1,1.414-1.414Zm-2.006-.742a7,7,0,1,0-.15.15l.15-.15Z"
      fill="currentColor"
    />
  </>,
  'Search'
);

export default SearchIcon;
