import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ButtonGroup from 'components/molecules/ButtonGroup';
import MenuIcon from 'components/atoms/Icons/MenuIcon';
import GridIcon from 'components/atoms/Icons/GridIcon';
import {
  CALENDAR_VIEW_TYPES,
  setSelectedCalendarViewType,
} from 'app-redux/storeCalendarFilterSlice';

const GridListViewChanger = () => {
  const dispatch = useDispatch();

  const handleSelectViewType = (idx: number) => {
    if (idx === 0) {
      dispatch(setSelectedCalendarViewType(CALENDAR_VIEW_TYPES.LIST));
    } else {
      dispatch(setSelectedCalendarViewType(CALENDAR_VIEW_TYPES.GRID));
    }
  };

  useEffect(() => {
    dispatch(setSelectedCalendarViewType(CALENDAR_VIEW_TYPES.GRID));
  }, [dispatch]);

  return (
    <ButtonGroup defaultActiveIndex={1} onClick={handleSelectViewType}>
      <ButtonGroup.Button>
        <MenuIcon />
      </ButtonGroup.Button>
      <ButtonGroup.Button>
        <GridIcon />
      </ButtonGroup.Button>
    </ButtonGroup>
  );
};

export default GridListViewChanger;
