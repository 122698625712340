import { Box } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

import Spinner from 'components/molecules/Spinner';
import Typography from 'components/atoms/Typography';
import Select from 'components/atoms/Select';
import Button from 'components/atoms/Button';
import Separator from 'components/atoms/Separator';
import { useDownloadVacationReportMutation } from 'app-redux/apiSlice';
import {
  openErrorSnackbar,
  openSuccessSnackbar,
} from 'app-redux/storeSnackbarSlice';
import { range } from 'utils/arraysOfRange';
import { isErrorWithMessage, isFetchBaseQueryError } from 'utils/api';

const VacationsView = () => {
  const currentYear = new Date().getFullYear();

  const dispatch = useDispatch();
  const [year, setYear] = useState(new Date().getFullYear().toString());
  // const [client, setClient] = useState('');
  // const [projectId, setProjectId] = useState('');

  // const [isXLSFormat, setIsXLSFormat] = useState(true);

  // const { data: allProjects } = useGetAllClientsQuery();
  const [downloadVacationReport, resultDownloadVacationReport] =
    useDownloadVacationReportMutation();

  // const clients = useMemo(() => {
  //   if (allProjects && allProjects.length > 0) {
  //     return [...new Set(allProjects.map(project => project.companyAccount))];
  //   }
  //   return [];
  // }, [allProjects]);

  // const clientProjects = useMemo(() => {
  //   if (allProjects && clients && clients.length > 0 && client) {
  //     return allProjects.filter(project => project.companyAccount === client);
  //   }
  //   return [];
  // }, [client, clients]);

  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    setYear(e.target.value as string);
  };

  // const handleClientChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setProjectId('');
  //   setClient(e.target.value);
  // };

  // const handleProjectChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setProjectId(e.target.value);
  // };

  // const handleIsXLSFormatChange = (
  //   _e: ChangeEvent<HTMLInputElement>,
  //   checked: boolean,
  // ) => {
  //   setIsXLSFormat(checked);
  // };

  const handleDownloadReport = async () => {
    try {
      //TODO: Add here isXLSFormat and project filter parameters when BE is ready
      await downloadVacationReport(year).unwrap();
      dispatch(openSuccessSnackbar('Report was downloaded successfully.'));
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        const errMsg = (err.data as { error: string }).error;
        dispatch(openErrorSnackbar(errMsg));
      } else if (isErrorWithMessage(err)) {
        dispatch(openErrorSnackbar(err.message));
      }
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ marginBottom: '32px' }}>
        Vacations
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Select
          sx={{ width: 130, marginRight: '24px', marginBottom: 0 }}
          value={year}
          onChange={handleYearChange}
          searchable={false}
          label="Year"
        >
          {range(2021, currentYear + 1).map(year => (
            <Select.MenuItem key={year} value={year.toString()}>
              {year}
            </Select.MenuItem>
          ))}
        </Select>
        {/* <Select
          sx={{ width: 280, marginRight: '24px', marginBottom: 0 }}
          value={client}
          onChange={handleClientChange}
          searchable={false}
          label='Clients'
        >
          {clients.map(client => (
            <Select.MenuItem key={client} value={client}>
              {client}
            </Select.MenuItem>
          ))}
        </Select>
        <Select
          sx={{ width: 280, marginRight: '24px', marginBottom: 0 }}
          value={projectId}
          onChange={handleProjectChange}
          searchable={false}
          label='Projects'
        >
          {clientProjects.map(project => (
            <Select.MenuItem key={project.projectId} value={project.projectId}>
              {project.projectName}
            </Select.MenuItem>
          ))}
          {clientProjects.length === 0 && (
            <Select.MenuItem>Please select client.</Select.MenuItem>
          )}
        </Select> */}
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={isXLSFormat}
              onChange={handleIsXLSFormatChange}
            />
          }
          label={<Typography variant='body-2'>Export in XLS format</Typography>}
        /> */}
      </Box>
      <Separator sx={{ margin: '24px 0' }} />
      <Button onClick={handleDownloadReport}>Download Report</Button>
      {resultDownloadVacationReport.isLoading && (
        <Spinner fullPage text="Report is being prepared..." />
      )}
    </Box>
  );
};

export default VacationsView;
