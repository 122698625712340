import { usePermify } from '@permify/react-role';
import { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

export const SecuredRoute = ({ roles }: { roles: string[] }) => {
  const { isAuthorized } = usePermify();
  const [hasAccess, setHasAccess] = useState<boolean>();

  useEffect(() => {
    const checkHasAccess = async () => {
      setHasAccess(await isAuthorized(roles));
    };

    checkHasAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = hasAccess ? <Outlet /> : <Navigate to="/" />;

  return hasAccess !== undefined ? content : <></>;
};
