import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import SubHeader from '../SubHeader';
import ExternalLink from '../../atoms/ExternalLink';

type LayoutProps = {
  leftSlot?: ReactNode;
  sidebar?: ReactNode;
  children: ReactNode;
};

type GridWrapperProps = {
  hasSidebar: boolean;
};

type HeaderProps = {
  hasSidebar: boolean;
};

const GridWrapper = styled('div')<GridWrapperProps>(({ hasSidebar }) => ({
  minHeight: 'calc(100vh - 80px)',
  gridTemplateRows: '84px calc(100vh - 164px)',
  display: 'grid',
  rowGap: 0,
  columnGap: 0,
  gridTemplateColumns: hasSidebar ? '344px 3fr' : '1fr',
  gridTemplateAreas: `"header header"
                        "${hasSidebar ? 'sidebar' : 'content'} content"`,
}));

const Header = styled('header')<HeaderProps>(({ hasSidebar, theme }) => ({
  borderBottom: hasSidebar ? '2px solid' : 'none',
  borderBottomColor: theme.palette.ixColorGrey30.main,
  gridArea: 'header',
}));

const Sidebar = styled('aside')(({ theme }) => ({
  gridArea: 'sidebar',
  padding: '24px 16px 74px 64px',
  borderRight: '2px solid',
  borderRightColor: theme.palette.ixColorGrey30.main,
  overflow: 'auto',
}));

const Main = styled('main')(() => ({
  gridArea: 'content',
  overflow: 'auto',
}));

const PageLayout = (props: LayoutProps) => {
  const { sidebar, leftSlot, children } = props;

  return (
    <GridWrapper hasSidebar={!!sidebar}>
      <Header hasSidebar={!!sidebar}>
        <SubHeader leftSlot={leftSlot} />
      </Header>
      {sidebar && (
        <Sidebar>
          {sidebar}
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              padding: '24px 0',
              width: '342px',
              backgroundColor: theme => theme.palette.ixColorTalc.main,
              textAlign: 'center',
            }}
          >
            <ExternalLink.Group>
              <ExternalLink
                href="https://central.thoughtworks.net/home/global-employee-privacy-notice"
                target="_blank"
              >
                Privacy Notice
              </ExternalLink>
              <ExternalLink
                href="https://forms.gle/9iCCnAusnw9bKhtP7"
                target="_blank"
              >
                Send us feedback
              </ExternalLink>
            </ExternalLink.Group>
          </Box>
        </Sidebar>
      )}
      <Main>{children}</Main>
    </GridWrapper>
  );
};

export default PageLayout;
