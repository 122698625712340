/* eslint-disable @typescript-eslint/no-explicit-any */
export type Order = 'asc' | 'desc';

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  descendingComparator: (
    a: { [key in Key]: any },
    b: { [key in Key]: any },
    orderBy: Key
  ) => any
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
