import './ListView.scss';

import { Backdrop, Fade, Paper, Popper, TableContainer } from '@mui/material';
import { HAS_ACCESS, hasAccess } from 'utils/roleManagement';
import { useDispatch, useSelector } from 'react-redux';

import { LEAVE_TYPE_MAP } from 'containers/Layout/forms/BookLeaveForm';
import { Leave } from 'utils/calendar-utils';
import LeaveForm from 'containers/Layout/forms/BookLeaveForm/LeaveForm';
import ListBody from './ListBody';
import ListHeader from './ListHeader';
import { RootState } from 'app-redux/store';
import Table from '@mui/material/Table';
import Typography from 'components/atoms/Typography';
import { closeEditForm } from 'app-redux/storeCalendarSlice';

const ListView = ({ leaves }: { leaves: Leave[] }) => {
  const isUser = hasAccess(HAS_ACCESS.USER);
  const dispatch = useDispatch();

  const { selectedVacation, editFormAnchorEl } = useSelector(
    (state: RootState) => state.calendar
  );

  const handleClose = () => {
    dispatch(closeEditForm());
  };

  return (
    <>
      <TableContainer>
        <Table
          sx={{ marginTop: 2, overflow: 'auto' }}
          data-testid="CalendarListView"
          stickyHeader
        >
          <ListHeader />
          <ListBody leaves={leaves} />
        </Table>
      </TableContainer>
      <Backdrop
        open={!!selectedVacation}
        sx={{ zIndex: 2 }}
        onClick={event => {
          const isBackdrop =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            event.target.classList.contains('MuiBackdrop-root');
          isBackdrop && handleClose();
        }}
      >
        <Popper
          anchorEl={editFormAnchorEl}
          placement="left-end"
          transition
          open={!!selectedVacation}
          sx={{ zIndex: 3 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div>
                <Paper
                  sx={{
                    padding: '24px',
                    marginRight: '12px',
                  }}
                >
                  {selectedVacation && (
                    <>
                      <Typography
                        color="ixColorGrey60"
                        variant="h6"
                        sx={{ marginBottom: '32px' }}
                      >
                        {(selectedVacation.isMyself
                          ? "You're "
                          : selectedVacation.fullname + ' is ') + 'on holiday'}
                        {isUser &&
                          selectedVacation.isMyself &&
                          ` - ${
                            LEAVE_TYPE_MAP[
                              selectedVacation.type as keyof typeof LEAVE_TYPE_MAP
                            ]
                          }`}
                      </Typography>
                      <LeaveForm
                        vacation={selectedVacation}
                        isEditing
                        leaveType={
                          LEAVE_TYPE_MAP[
                            selectedVacation.type as keyof typeof LEAVE_TYPE_MAP
                          ]
                        }
                        onCloseEdit={handleClose}
                      />
                    </>
                  )}
                </Paper>
              </div>
            </Fade>
          )}
        </Popper>
      </Backdrop>
    </>
  );
};

export default ListView;
