import { ChangeEvent, useState } from 'react';
import { Box, FormControlLabel, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import Typography from 'components/atoms/Typography';
import Button from 'components/atoms/Button';
import Separator from 'components/atoms/Separator';
import DatePicker from 'components/atoms/DatePicker';
import Checkbox from 'components/atoms/Checkbox';
import {
  openErrorSnackbar,
  openSuccessSnackbar,
} from 'app-redux/storeSnackbarSlice';
import { isErrorWithMessage, isFetchBaseQueryError } from 'utils/api';
import Spinner from 'components/molecules/Spinner';
import { useDownloadWorkingHoursReportMutation } from 'app-redux/apiSlice';

const WorkingHoursView = () => {
  const [date, setDate] = useState<Date | null>(new Date());
  const [includeSummary, setIncludeSummary] = useState<boolean>(false);
  // TODO: Open here when BE implemeted
  const dispatch = useDispatch();
  const [downloadWorkingHoursReport, resultWorkingHoursReport] =
    useDownloadWorkingHoursReportMutation();

  const handleChangeDate = (value: Date | null) => {
    setDate(value);
  };

  const handleIncludeSummary = (
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setIncludeSummary(true);
    } else {
      setIncludeSummary(false);
    }
  };

  const handleDownloadReport = async () => {
    // TODO: Open here when BE implemeted
    try {
      await downloadWorkingHoursReport({
        date: format(date || new Date(), 'dd.MM.yyyy') || '',
        includeSummary,
      }).unwrap();
      dispatch(openSuccessSnackbar('Report was downloaded successfully.'));
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        const errMsg = (err.data as { error: string }).error;
        dispatch(openErrorSnackbar(errMsg));
      } else if (isErrorWithMessage(err)) {
        dispatch(openErrorSnackbar(err.message));
      }
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ marginBottom: '32px' }}>
        Working Hours
      </Typography>
      <Stack direction="row" spacing={3}>
        <Box
          sx={{
            width: '280px',
          }}
        >
          <DatePicker
            label="Date"
            onChange={handleChangeDate}
            value={date}
            placeholder="Pick a date"
            inputFormat="dd.MM.yyyy"
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeSummary}
              onChange={handleIncludeSummary}
            />
          }
          label={
            <Typography variant="body-2">Add a monthly summary</Typography>
          }
        />
      </Stack>
      <Separator sx={{ margin: '24px 0' }} />
      <Button onClick={handleDownloadReport}>Download Report</Button>
      {/*TODO: Open here when BE implemeted*/}
      {resultWorkingHoursReport.isLoading && (
        <Spinner fullPage text="Report is being prepared..." />
      )}
    </Box>
  );
};

export default WorkingHoursView;
