import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';

import { getXSRFToken, redirectToLogin } from 'utils/api';

export const baseURL = '/api/v1';
const baseQuery = fetchBaseQuery({ baseUrl: baseURL });

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError | { status: number; data: { error: string } },
  Record<string, unknown>,
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  if (typeof args !== 'string' && !!args?.method && args.method !== 'GET') {
    args.headers = {
      ...args.headers,
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getXSRFToken(),
    };
  }
  const result = await baseQuery(args, api, extraOptions);

  // refresh user session
  if (
    result.error &&
    (result.error.status === 401 ||
      (result.meta?.response?.redirected &&
        result.meta?.response?.url.includes('saml2/authenticate/okta')))
  ) {
    redirectToLogin();
  }

  return result;
};
