import {
  useFormContext,
  Controller,
  FieldError,
  FieldErrors,
} from 'react-hook-form';

import TagInput, { TagInputProps } from '../../../TagInput';

type FormTagInputProps = {
  name: string;
  shouldUnregister?: boolean;
} & TagInputProps;

const generateHelperText = (errors: FieldError | FieldErrors | undefined) => {
  if (!errors) {
    return undefined;
  }
  if (Array.isArray(errors)) {
    return errors.map(error => error.message).join(',');
  }

  return errors.message?.toString();
};

const FormTagInput = (props: FormTagInputProps) => {
  const { name, shouldUnregister, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <TagInput
          {...field}
          {...rest}
          error={!!errors[name]}
          helperText={generateHelperText(errors[name])}
        />
      )}
    />
  );
};

export default FormTagInput;
