import { ChangeEvent, KeyboardEvent, ReactNode, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment, Chip } from '@mui/material';

import CloseIcon from '../Icons/CloseIcon';
import Input from '../Input';

export type TagInputProps = {
  options?: string[];
  onSearch?: (query: string) => void;
  label?: ReactNode;
  helperText?: string;
  disabled?: boolean;
  prefixIcon?: ReactNode;
  error?: boolean;
  placeholder?: string;
  onChange?: (value: string[]) => void;
  readonly?: boolean;
  value?: string[];
};

const TagInput = (props: TagInputProps) => {
  const {
    placeholder,
    label,
    helperText,
    disabled,
    prefixIcon,
    error,
    onChange,
    options,
    onSearch,
  } = props;
  const [value, setValue] = useState<string[]>(props.value || []);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: ChangeEvent<any>,
    values: string[]
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onChange?.(values);
    setValue(values);
    setInputValue('');
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    newInputValue: string
  ) => {
    const options = newInputValue.split(/[ ,]+/);
    const fieldValue = value
      .concat(options)
      .map(x => x.trim())
      .filter(x => x);
    if (options.length > 1) {
      handleChange(event, fieldValue);
    } else {
      setInputValue(newInputValue);
      onSearch?.(newInputValue);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case ' ':
      case ',':
      case ';': {
        if (inputValue.length > 0 && !value.includes(inputValue)) {
          handleChange(event, value.concat([inputValue]));
        }
        event.preventDefault();
        event.stopPropagation();
        break;
      }
      default:
    }
  };

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (inputValue.length > 0 && !value.includes(inputValue)) {
      handleChange(event, value.concat([inputValue]));
    } else {
      setInputValue('');
    }
  };

  return (
    <Autocomplete
      readOnly={props.readonly}
      freeSolo
      multiple
      options={(options || []) as unknown as readonly string[][]}
      sx={{ width: '100%' }}
      disableClearable
      disabled={disabled}
      value={value}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderTags={(options, getTagProps) =>
        options.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            size="small"
            deleteIcon={<CloseIcon />}
            variant="filled"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => {
        params.inputProps.onKeyDown = handleKeyDown;
        params.inputProps.onBlur = handleBlur;
        params.inputProps.value = inputValue;
        params.inputProps.prefix = prefixIcon?.toString();

        return (
          <Input
            {...params}
            style={{
              width: '100%',
              padding: 0,
            }}
            label={label}
            variant="outlined"
            fullWidth
            helperText={helperText}
            error={error}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">{prefixIcon}</InputAdornment>
                  <>{params.InputProps.startAdornment}</>
                </>
              ),
            }}
            id="outlined-basic"
            shouldShrink={value.length > 0}
            tagValue={value}
          />
        );
      }}
    />
  );
};

export default TagInput;
