/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Backdrop,
  Box,
  ClickAwayListener,
  Fade,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { HAS_ACCESS, hasAccess } from '../../../../../../utils/roleManagement';
import {
  Leave,
  isBetweenTwoDates,
} from '../../../../../../utils/calendar-utils';
import { MouseEvent, useEffect, useState } from 'react';
import { RootState, useSelector } from '../../../../../../app-redux/store';
import {
  closeEditForm,
  resetStartRangeDaySelection,
} from '../../../../../../app-redux/storeCalendarSlice';

import EditIcon from '@mui/icons-material/Edit';
import { LEAVE_TYPE_MAP } from '../../../../../Layout/forms/BookLeaveForm';
import LeaveForm from '../../../../../Layout/forms/BookLeaveForm/LeaveForm';
import theme from '../../../../../../theme';
import { useDispatch } from 'react-redux';

function getBackground(vacations: Array<Leave>) {
  const isMyselfIncluded = vacations.find(element => element.isMyself === true);
  const areOthersIncluded = vacations.find(
    element => element.isMyself === false
  );

  return areOthersIncluded && isMyselfIncluded
    ? theme.palette.primary.light
    : areOthersIncluded
    ? 'white'
    : theme.palette.primary.light;
}

export const YearlyGridViewDay = ({
  isFreeDay,
  vacations,
  opacity,
  textColor,
  text,
  dateStored,
}: {
  isFreeDay?: boolean;
  vacations?: Array<Leave>;
  opacity: string;
  textColor?: string;
  text: string;
  dateStored: Date;
}) => {
  // used for popper visibility control
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [selectedVacation, setSelectedVacation] = useState<Leave | null>(null);
  const isUser = hasAccess(HAS_ACCESS.USER);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (vacations && vacations?.length > 0) {
      if (anchorEl) {
        setAnchorEl(null);
        event.currentTarget.style.zIndex = '0';
        event.currentTarget.style.position = 'relative';
      } else {
        setAnchorEl(event.currentTarget);
        event.currentTarget.style.zIndex = '3';
        event.currentTarget.style.position = 'relative';
      }
    }
  };

  const dispatch = useDispatch();
  const selection = useSelector(
    (state: RootState) => state.calendar.dateSelection
  );

  const handleClose = () => {
    setAnchorEl(null);
    const days = document.querySelectorAll<HTMLDivElement>('.year-day');
    days.forEach(day => (day.style.zIndex = '0'));
  };

  // the following block is used to make sure days with users leaves are not included in day range selection
  const isMyselfIncludedInLeaves = vacations?.find(
    element => element.isMyself === true
  );

  if (selection.selectionInProgress && isMyselfIncludedInLeaves) {
    const isInTheFuture = dateStored.getTime() > new Date().getTime();
    const isInASelectionRangeDay =
      isBetweenTwoDates(
        new Date(selection.dateStart),
        new Date(selection.dateEnd),
        isMyselfIncludedInLeaves.startDate
      ) ||
      isBetweenTwoDates(
        new Date(selection.dateStart),
        new Date(selection.dateEnd),
        isMyselfIncludedInLeaves.endDate
      );

    if (isInTheFuture && isInASelectionRangeDay) {
      if (isMyselfIncludedInLeaves.startDate.getTime() > selection.dateStart) {
        const newStartDate = new Date(isMyselfIncludedInLeaves.endDate);
        newStartDate.setDate(newStartDate.getDate() + 1);
        dispatch(resetStartRangeDaySelection(newStartDate.getTime()));
      } else {
        const newStartDate = new Date(isMyselfIncludedInLeaves.startDate);
        newStartDate.setDate(newStartDate.getDate() - 1);
        dispatch(resetStartRangeDaySelection(newStartDate.getTime()));
      }
    }
  }

  useEffect(() => {
    if (vacations === undefined || vacations?.length === 0) {
      handleClose();
    }
  }, [vacations]);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Box
          data-testid={dateStored}
          margin={'0px 6px'}
          borderRadius="20%"
          color={textColor}
          sx={{
            backgroundColor: isFreeDay
              ? theme.palette.calendarFrame.dayOff
              : 'white',
            py: vacations ? '3px' : '4px',
            width: '80%',
            cursor: vacations ? 'pointer' : 'inherit',
          }}
        >
          <Box
            role={'yearlyGridDay'}
            onClick={handleClick}
            borderRadius="20%"
            sx={{
              background: vacations ? getBackground(vacations) : 'none',
              opacity: opacity,
              backgroundColor: vacations ? 'white' : 'none',
              border: vacations ? 1 : 0,
              borderColor: '#414343',
              px: '4px',
              cursor: vacations ? 'pointer' : 'inherit',
            }}
            className="year-day"
          >
            {text}
          </Box>
        </Box>
        <Backdrop
          open={!!anchorEl}
          sx={{ zIndex: 2 }}
          onClick={event => {
            const isBackdrop =
              //@ts-ignore
              event.target.classList.contains('MuiBackdrop-root');
            isBackdrop && handleClose();
          }}
        >
          <Popper
            anchorEl={anchorEl}
            placement="bottom"
            transition
            open={!!anchorEl}
            sx={theme => ({
              zIndex: 3,
              '&[data-popper-placement="bottom"] .arrow': {
                top: 0,
                left: 0,
                marginTop: '-0.9em',
                width: '3em',
                height: '1em',
                '&::before': {
                  borderWidth: '0 1em 1em 1em',
                  borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
                },
              },
              '&[data-popper-placement="top"] .arrow': {
                bottom: 0,
                left: 0,
                marginBottom: '-0.9em',
                width: '3em',
                height: '1em',
                '&::before': {
                  borderWidth: '1em 1em 0 1em',
                  borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
                },
              },
            })}
            modifiers={[
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef,
                },
              },
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [12, 12],
                },
              },
            ]}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div>
                  <Box
                    className="arrow"
                    sx={{
                      position: 'absolute',
                      fontSize: 7,
                      background: 'transparent',
                      width: '3em',
                      height: '3em',

                      '&::before': {
                        content: '""',
                        margin: 'auto',
                        display: 'block',
                        width: 0,
                        height: 0,
                        borderStyle: 'solid',
                      },
                    }}
                    ref={setArrowRef}
                  />
                  <Paper>
                    {selectedVacation ? (
                      <Box sx={{ padding: '24px' }}>
                        <Typography
                          color="ixColorGrey60"
                          variant="h6"
                          sx={{ marginBottom: '32px' }}
                        >
                          {(selectedVacation.isMyself
                            ? "You're "
                            : selectedVacation.fullname + ' is ') +
                            'on holiday'}
                          {isUser &&
                            selectedVacation.isMyself &&
                            ` - ${
                              LEAVE_TYPE_MAP[
                                selectedVacation.type as keyof typeof LEAVE_TYPE_MAP
                              ]
                            }`}
                        </Typography>
                        <LeaveForm
                          isEditing
                          vacation={selectedVacation}
                          leaveType={
                            LEAVE_TYPE_MAP[
                              selectedVacation.type as keyof typeof LEAVE_TYPE_MAP
                            ]
                          }
                          onCloseEdit={() => {
                            handleClose();
                            dispatch(closeEditForm());
                          }}
                        />
                      </Box>
                    ) : (
                      <List>
                        <Typography
                          sx={{
                            padding: '12px 24px',
                            fontSize: '14px',
                            color: '#1e1e1e',
                            borderBottom: '1px solid #ccc',
                            fontWeight: 'bold',
                            marginBottom: '8px',
                          }}
                        >
                          {"Who's on leave?"}
                        </Typography>
                        {vacations?.map(vacation => {
                          return (
                            <ListItem
                              key={vacation.fullname}
                              sx={{
                                padding: '0px 24px ',
                                color: 'black',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <ListItemText
                                primary={
                                  vacation.isMyself ? 'You' : vacation.fullname
                                }
                                sx={{}}
                              />
                              {vacation.isMyself && (
                                <EditIcon
                                  onClick={() => {
                                    setSelectedVacation(vacation);
                                  }}
                                  sx={{
                                    width: '16px',
                                    opacity: 1,
                                    transition: 'opacity 0.3s ease',
                                  }}
                                />
                              )}
                            </ListItem>
                          );
                        })}
                      </List>
                    )}
                  </Paper>
                </div>
              </Fade>
            )}
          </Popper>
        </Backdrop>
      </div>
    </ClickAwayListener>
  );
};
