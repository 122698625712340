import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ButtonGroup from 'components/molecules/ButtonGroup';
import {
  PERIOD_VIEW_TYPES,
  setSelectedPeriodViewType,
} from 'app-redux/storeCalendarFilterSlice';

const MonthYearViewChanger = () => {
  const dispatch = useDispatch();

  const handleSelectViewType = (idx: number) => {
    if (idx === 0) {
      dispatch(setSelectedPeriodViewType(PERIOD_VIEW_TYPES.MONTH));
    } else {
      dispatch(setSelectedPeriodViewType(PERIOD_VIEW_TYPES.YEAR));
    }
  };

  useEffect(() => {
    dispatch(setSelectedPeriodViewType(PERIOD_VIEW_TYPES.MONTH));
  }, [dispatch]);

  return (
    <ButtonGroup defaultActiveIndex={0} onClick={handleSelectViewType}>
      <ButtonGroup.Button>MONTH</ButtonGroup.Button>
      <ButtonGroup.Button>YEAR</ButtonGroup.Button>
    </ButtonGroup>
  );
};

export default MonthYearViewChanger;
