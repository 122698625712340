import { TextFieldProps } from '@mui/material/TextField';
import { useFormContext, Controller } from 'react-hook-form';
import Input from '../../../Input';

type FormInputProps = {
  name: string;
  shouldUnregister?: boolean;
} & TextFieldProps;

const FormInput = (props: FormInputProps) => {
  const { name, shouldUnregister, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <Input
          {...field}
          {...rest}
          error={!!errors[name]}
          helperText={
            errors[name] ? (errors[name]?.message as unknown as string) : ''
          }
        />
      )}
    />
  );
};

export default FormInput;
