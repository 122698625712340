import {
  Children,
  cloneElement,
  ComponentProps,
  ReactNode,
  useState,
} from 'react';
import { styled } from '@mui/material/styles';

interface ButtonGroupProps {
  defaultActiveIndex?: number;
  onClick?: (idx: number) => void;
  children: ReactNode;
}

interface ButtonProps extends ComponentProps<'button'> {
  active?: boolean;
  hasIcon?: boolean;
  children: ReactNode;
}

const StyledButtonGroupWrapper = styled('div')(() => ({
  display: 'inline-flex',
  flexDirection: 'row',
  '> *:first-child': {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  '> *:last-child': {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  '> *:not(:last-child)': {
    borderRight: 0,
  },
}));

const StyledButton = styled('button')<ButtonProps>(
  ({ active, hasIcon, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: hasIcon ? '12px' : '16px',
    transition: 'all 0.3s ease',
    color: active
      ? theme.palette.ixColorWave.main
      : theme.palette.ixColorGrey70.main,
    backgroundColor: active
      ? theme.palette.ixColorMist.main
      : theme.palette.ixColorTalc.main,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.ixColorGrey30.main}`,
    verticalAlign: 'middle',
    position: 'relative',
    userSelect: 'none',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: '0.875rem',
  })
);

const Button = (props: ButtonProps) => {
  const { children, active, ...rest } = props;

  return (
    <StyledButton
      hasIcon={typeof children === 'object'}
      active={active}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

const ButtonGroup = (props: ButtonGroupProps) => {
  const { defaultActiveIndex, children, onClick } = props;
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const items = Children.map(children, (child: any, index: number) =>
    child.type.displayName === 'Button'
      ? cloneElement(child, {
          active: activeIndex === index,
          onClick: () => {
            const isActive = activeIndex === index;
            if (!isActive) {
              onClick?.(index);
              child.props?.onClick?.();
              setActiveIndex(index);
            }
          },
        })
      : null
  );

  return <StyledButtonGroupWrapper>{items}</StyledButtonGroupWrapper>;
};

Button.displayName = 'Button';
ButtonGroup.Button = Button;

export default ButtonGroup;
