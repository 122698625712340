import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Selector from 'components/molecules/Selector';
import { RootState } from 'app-redux/store';
import {
  PERIOD_VIEW_TYPES,
  decreasePeriod,
  increasePeriod,
} from 'app-redux/storeCalendarFilterSlice';
import { MONTHS } from 'utils/calendar-utils';
import { setCurrentDate } from 'app-redux/storeCalendarSlice';

const MonthAndYearStepper = () => {
  const dispatch = useDispatch();

  const {
    selectedMonth: currentMonth,
    selectedYear: year,
    periodViewType: periodView,
  } = useSelector((state: RootState) => state.calendarFilter);

  const isMonthView = periodView === PERIOD_VIEW_TYPES.MONTH;

  const handleNext = () => {
    dispatch(increasePeriod());
  };

  const handlePrev = () => {
    dispatch(decreasePeriod());
  };

  const renderLabel = () =>
    isMonthView ? `${MONTHS[currentMonth]} ${year}` : year;

  useEffect(() => {
    dispatch(setCurrentDate());
  }, [dispatch]);

  return (
    <Selector label={renderLabel()} onNext={handleNext} onPrev={handlePrev} />
  );
};

export default MonthAndYearStepper;
