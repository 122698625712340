import { useLocation } from 'react-router-dom';

import StatusBanner from 'components/molecules/StatusBanner';
import AppHeader from 'components/molecules/AppHeader';

type LocationState = {
  referrer: string;
};

const ErrorPage = () => {
  const location = useLocation();
  const { referrer } = (location.state as LocationState) || { referrer: '/' };

  const goBack = () => {
    // navigate(referrer, { replace: true });
    window.location.href = referrer;
  };

  return (
    <>
      <AppHeader />
      <StatusBanner
        message="Something’s wrong here."
        action="RELOAD THE PAGE"
        sx={{ height: 'calc(100vh - 64px)' }}
        onActionClick={goBack}
      />
    </>
  );
};

export default ErrorPage;
