import { Box, FormControlLabel, InputAdornment, Stack } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { HAS_ACCESS, HasAccess, hasAccess } from 'utils/roleManagement';
import HourSlider, {
  HourSliderValueType,
} from 'components/molecules/HourSlider';
import Table, { Column } from 'components/molecules/Table';
import { isErrorWithMessage, isFetchBaseQueryError } from 'utils/api';
import { minuteToTime, timeToMinute } from 'utils/calendar-utils';
import {
  openErrorSnackbar,
  openSuccessSnackbar,
} from 'app-redux/storeSnackbarSlice';
import {
  selectUserEmployeeData,
  useCreateWorkDaysByEmployeeIdMutation,
  useGetWorkDaysByEmployeeIdQuery,
} from 'app-redux/apiSlice';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import ErrorIcon from 'components/atoms/Icons/ErrorIcon';
import Input from 'components/atoms/Input';
import Spinner from 'components/molecules/Spinner';
import Typography from 'components/atoms/Typography';
import { useParams } from 'react-router-dom';

// TODO: refactor this - we already have constants for the days
const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
const DEFAULT_MIN_VALUE = 0;

type DayRowType = {
  name: (typeof DAYS)[number];
  startingHour: string;
  finishHour: string;
  lunchBreak: number;
  useCustomOfficeSchedule: boolean;
};

const WeekDaysForm = () => {
  const isHRorAdmin = hasAccess([...HAS_ACCESS.HR, ...HAS_ACCESS.ADMIN]);
  const dispatch = useDispatch();
  const { employeeId } = useParams();
  const [values, setValues] = useState<DayRowType[]>([]);
  const userProfile = useSelector(selectUserEmployeeData);
  const isCurrentUser = userProfile?.employeeNumber === employeeId;

  const columns: Column<DayRowType>[] = [
    {
      label: 'Day',
      dataIndex: 'name',
    },
    {
      label: 'Working Hours',
      dataIndex: 'startingHour',
      render: (_value, row) => (
        <Typography>
          {row.startingHour} - {row.finishHour}
        </Typography>
      ),
    },
    {
      label: 'Lunch Break(min)',
      dataIndex: 'lunchBreak',
    },
  ];

  const {
    data: workDays,
    isLoading: isLoadingWorkDays,
    isSuccess: isSuccessWorkDays,
  } = useGetWorkDaysByEmployeeIdQuery(employeeId || '', {
    skip: userProfile?.employeeNumber !== employeeId && !isHRorAdmin,
  });

  const [createWorkDays, createWorkdaysMutationResult] =
    useCreateWorkDaysByEmployeeIdMutation();

  const getUseCustomSchedule = (day: (typeof DAYS)[number]): boolean => {
    const currDay = values.find(value => value.name === day);

    return currDay?.useCustomOfficeSchedule || false;
  };

  const getSliderValue = (day: (typeof DAYS)[number]): HourSliderValueType => {
    const currDay = values.find(value => value.name === day);

    return {
      start: currDay?.startingHour || '09:00',
      end: currDay?.finishHour || '18:00',
    };
  };

  const getLunchBreakValue = (day: (typeof DAYS)[number]): number => {
    const currDay = values.find(value => value.name === day);

    return currDay?.lunchBreak || 0;
  };

  const handleChangeUseCustomSchedule = (
    value: ChangeEvent<HTMLInputElement>,
    day: (typeof DAYS)[number]
  ) => {
    setValues(currValues => {
      const updatedRows = currValues.map(item => {
        const updatedItem = { ...item };

        if (item.name === day) {
          updatedItem.useCustomOfficeSchedule = value.target.checked;

          return updatedItem;
        }

        return updatedItem;
      });

      return updatedRows;
    });
  };

  const handleChangeSlider = (
    value: HourSliderValueType,
    day: (typeof DAYS)[number]
  ) => {
    setValues(currValues => {
      const updatedRows = currValues.map(item => {
        const updatedItem = { ...item };

        if (item.name === day) {
          if (value.activeThumb === 0) {
            const { hours, minutes } = minuteToTime(
              Math.min(
                timeToMinute(value.start),
                timeToMinute(value.end) - (DEFAULT_MIN_VALUE + item.lunchBreak)
              )
            );
            updatedItem.startingHour = `${hours}:${minutes}`;
            updatedItem.finishHour = value.end;
          } else {
            const { hours, minutes } = minuteToTime(
              Math.max(
                timeToMinute(value.end),
                timeToMinute(value.start) +
                  (DEFAULT_MIN_VALUE + item.lunchBreak)
              )
            );
            updatedItem.startingHour = value.start;
            updatedItem.finishHour = `${hours}:${minutes}`;
          }

          return updatedItem;
        }

        return updatedItem;
      });

      return updatedRows;
    });
  };

  const handleChangeInput = (
    event: ChangeEvent<HTMLInputElement>,
    day: (typeof DAYS)[number]
  ) => {
    const { value } = event.target;
    const parsedValue = parseInt(value);
    let validValue = parsedValue;

    if (parsedValue > 120) {
      validValue = 120;
    } else if (parsedValue <= 0) {
      validValue = 0;
    } else if (!parsedValue) {
      validValue = 0;
    }

    setValues(currValues => {
      const updatedRows = currValues.map(item => {
        const updatedItem = { ...item };
        if (item.name === day) {
          updatedItem.lunchBreak = validValue;
          const { hours, minutes } = minuteToTime(
            timeToMinute(updatedItem.finishHour) + validValue - item.lunchBreak
          );
          updatedItem.finishHour = `${hours}:${minutes}`;

          return updatedItem;
        }

        return updatedItem;
      });

      return updatedRows;
    });
  };

  const handleUpdate = async () => {
    if (employeeId) {
      try {
        await createWorkDays({
          employeeId,
          workingDays: [...values],
        });
        dispatch(openSuccessSnackbar('Weekdays updated successfully.'));
      } catch (err) {
        if (isFetchBaseQueryError(err)) {
          const errMsg = (err.data as { error: string }).error;
          dispatch(openErrorSnackbar(errMsg));
        } else if (isErrorWithMessage(err)) {
          dispatch(openErrorSnackbar(err.message));
        }
      }
    }
  };

  const seedDefaultValues = () => {
    const defaultValues: DayRowType[] = DAYS.map(day => ({
      name: day,
      startingHour: '09:00',
      finishHour: '18:00',
      lunchBreak: 60,
      useCustomOfficeSchedule: false,
    }));

    setValues(defaultValues);
  };

  useEffect(() => {
    if (workDays && workDays.length > 0) {
      setValues([...workDays]);
    }
  }, [workDays]);

  if (isLoadingWorkDays) {
    return <Spinner fullPage text="Weekdays loading..." />;
  }

  return (
    <div>
      {createWorkdaysMutationResult.isLoading && (
        <Spinner fullPage text="Updating weekdays" />
      )}
      <Typography variant="h5" sx={{ marginBottom: '24px' }}>
        Weekdays
      </Typography>
      {values.length === 0 && isSuccessWorkDays && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            <ErrorIcon sx={{ width: '100px', height: '100px' }} />
          </Box>
          <Box sx={{ marginBottom: '16px' }}>
            <Typography>
              {isCurrentUser
                ? 'You have no week days recorded.'
                : 'This employee has no week days recorded.'}
            </Typography>
            {isCurrentUser && !isHRorAdmin && (
              <Typography>Please contact your HR department.</Typography>
            )}
          </Box>
          <HasAccess roles={HAS_ACCESS.HR}>
            <Button onClick={seedDefaultValues} variant="contained">
              Add New Record
            </Button>
          </HasAccess>
        </Box>
      )}
      {values.length > 0 &&
        isCurrentUser &&
        !isHRorAdmin &&
        isSuccessWorkDays && (
          <Table<DayRowType>
            options={{ showPagination: false }}
            data={values}
            columns={columns}
          />
        )}
      {values.length > 0 &&
        isHRorAdmin &&
        DAYS.map(day => (
          <Box
            key={day}
            sx={{
              ':not(:last-child)': { marginBottom: '32px' },
            }}
          >
            <Stack direction={'row'} spacing={2} sx={{ display: 'flex' }}>
              <Typography
                variant="subtitle-2"
                sx={{ margin: 'revert', minWidth: '100px' }}
              >
                {day}
              </Typography>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={
                  <Checkbox
                    value={getUseCustomSchedule(day)}
                    checked={getUseCustomSchedule(day)}
                    onChange={value => {
                      handleChangeUseCustomSchedule(value, day);
                    }}
                  />
                }
                label="Custom Office Schedule"
              />
            </Stack>
            <Box
              sx={{
                padding: '0 20px',
                ':not(:last-child)': { marginBottom: '32px' },
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ flex: 1, marginRight: '60px' }}>
                  <HourSlider
                    sx={theme => {
                      const useCustomSchedule = getUseCustomSchedule(day);

                      return {
                        color: useCustomSchedule
                          ? theme.palette.ixColorTurmeric.main
                          : theme.palette.ixColorWave.main,
                      };
                    }}
                    valueLabelDisplay={isHRorAdmin ? 'auto' : 'on'}
                    disabled={!isHRorAdmin}
                    value={getSliderValue(day)}
                    onChange={value => {
                      handleChangeSlider(value, day);
                    }}
                    minValueThreshold={getLunchBreakValue(day)}
                  />
                </Box>
                <Input
                  sx={{ width: '110px', marginBottom: 0 }}
                  disabled={!isHRorAdmin}
                  label="Lunch break"
                  value={getLunchBreakValue(day)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChangeInput(event, day);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">min</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>
        ))}
      {values.length > 0 && (
        <HasAccess roles={HAS_ACCESS.HR}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '20px',
            }}
          >
            <Button onClick={handleUpdate}>Update</Button>
          </Box>
        </HasAccess>
      )}
    </div>
  );
};

export default WeekDaysForm;
