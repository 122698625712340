/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Children, ReactNode, useState } from 'react';
import { Box, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';

import Button from 'components/atoms/Button';
import Separator from 'components/atoms/Separator';
import { ButtonProps } from 'components/atoms/Button';
import { HasAccess } from 'utils/roleManagement';

type TabsProps = {
  defaultIndex?: number;
  onTabsChange?: (idx: number) => void;
  children: ReactNode[] | ReactNode;
  seperator?: boolean;
};

export type TabItemProps = {
  label?: string;
  disabled?: boolean;
  hasAccess?: string[];
  children: ReactNode;
};

const ItemWrapper = styled('div')<TabItemProps>();
const TabButton = styled(Button)<
  { isActive: boolean; seperator: boolean } & ButtonProps
>(({ theme, isActive, seperator }) => ({
  marginRight: '16px',
  marginBottom: seperator ? '24px' : '32px',
  backgroundColor: isActive ? theme.palette.ixColorMist.main : 'transparent',
  '&:hover': {
    backgroundColor: isActive
      ? theme.palette.ixColorMist.main
      : theme.palette.ixColorGrey5.main,
  },
}));

const Tabs = (props: TabsProps) => {
  const { defaultIndex = 0, onTabsChange, seperator = false, children } = props;
  const [bindIndex, setBindIndex] = useState(defaultIndex);

  const changeTab = (newIndex: number) => {
    if (bindIndex !== newIndex) {
      if (typeof onTabsChange === 'function') {
        onTabsChange(newIndex);
      }
      setBindIndex(newIndex);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const items = Children.map(children, (child: any) =>
    child?.type?.displayName === 'Item' ? child : null
  );

  return (
    <div>
      {items
        ?.filter(Boolean)
        // @ts-ignore
        .map(({ props: { label, hasAccess } }, index: number) =>
          hasAccess ? (
            <HasAccess roles={hasAccess} key={index}>
              <TabButton
                variant="text"
                onClick={() => changeTab(index)}
                isActive={bindIndex === index}
                seperator={seperator}
              >
                {label}
              </TabButton>
            </HasAccess>
          ) : (
            <TabButton
              variant="text"
              key={index}
              onClick={() => changeTab(index)}
              isActive={bindIndex === index}
              seperator={seperator}
            >
              {label}
            </TabButton>
          )
        )}
      {seperator && (
        <Box sx={{ marginBottom: '24px' }}>
          <Separator />
        </Box>
      )}
      {/* @ts-ignore */}
      {items?.filter(Boolean).map(({ props: { children } }, index: number) => (
        <Fade key={index} in={bindIndex === index}>
          {<Box>{bindIndex === index ? children : ''}</Box>}
        </Fade>
      ))}
    </div>
  );
};

const Item = ({ label, disabled, hasAccess, children }: TabItemProps) => {
  return (
    <ItemWrapper label={label} disabled={disabled} hasAccess={hasAccess}>
      {children}
    </ItemWrapper>
  );
};

Item.displayName = 'Item';
Tabs.Item = Item;

export default Tabs;
