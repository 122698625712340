import { RootState } from './store';
import { baseQueryWithReauth } from './baseQueryWithReauth';
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSelector } from '@reduxjs/toolkit';
import { downloadFile } from '../utils/download';

export type BankHolidayResponse = {
  id?: string;
  name: string;
  startDate: string;
  endDate: string;
};

export type UserInfoResponse = {
  email: string;
  firstName: string;
  lastName: string;
  employeeNumber: string;
  roleList: string[];
};

export type UserDTO = {
  id: string;
  email: string;
  secondaryEmail: string;
  firstName: string;
  lastName: string;
  middleName: string;
  workingOffice: string;
  employeeId: string;
  payrollId: string;
  vacationDays: number;
  bookedDays: number;
  hireDate: string;
  role: string;
  careerStartDate: string;
  position: string;
  department: string;
  contractType: string;
  contractEndDate: Date | null;
  active: boolean;
  workingHoursPerDay: string;
  cutbackDays: string | null;
  assignmentSet: AssignmentDTO[];
};

export type AssignmentDTO = {
  id: number;
  projectName: string;
  companyAccount: string;
};

export type Client = {
  projectId: string;
  projectName: string;
  companyAccount: string;
};

export type Colleague = {
  employeeId: string;
  fullName: string;
  email: string;
  remainingVacationDays: number;
  assignmentIdList?: number[];
};

export type SpecialLeaveType = {
  id: number;
  name: string;
  duration: number;
  jigsaw_equivalent: string;
  description: string;
};

export type VacationResponse = {
  userId: string;
  userFullName: string;
  startDate: Date;
  endDate: Date;
  type: string;
  specialLeaveType?: SpecialLeaveType;
  comment: string;
  cc: string;
  id: string;
  status: 'APPROVED' | 'CANCELED';
  numberOfBookedDays: number;
  docusignEnvelopeStatus?: string;
};

export type VacationModel = {
  type: string;
  specialLeaveType?: SpecialLeaveType;
  startDate: Date;
  endDate: Date;
  comment: string;
  cc: string;
  status: 'approved' | 'canceled';
  emailRequester: string;
  emailEmployee?: string;
  employeeNumber: string;
  docusignEnvelopeStatus?: string;
};

export type ProgressBarInfoResponse = {
  totalDays: number;
  availableDays: number;
  plannedDays: number;
  takenDays: number;
  carryOverDays: number;
  daysTakenInAdvance: { [key: number]: number };
};

export type ProgressBarInfoRequest = {
  employeeId: string;
  year: number;
};

export type WorkDayResponse = {
  name: string;
  startingHour: string;
  finishHour: string;
  lunchBreak: number;
  useCustomOfficeSchedule: boolean;
};

export type WorkDayDTO = {
  employeeId: string;
  workingDays: WorkDayResponse[];
};

export type AppointmentDTO = {
  id?: string;
  employeeNumber: string;
  recurrence: string;
  weekdayMonthly: string | null;
  weekdaysList: string[] | null;
  dayOccurrence: string | null;
  monthYearly: string | null;
  daysNumber: number | null;
  monthNumber: number | null;
  weekNumber: number | null;
  endDate: Date | null;
  startDate: Date | null;
  stopAfterNumberOfOccurrences: number | null;
  monthlyOccurrence?: boolean;
};

export type PayrollRequestDTO = {
  year: string;
  month: string;
};

export type GoogleCredentialsDTO = {
  api_key: string;
  client_id: string;
};

export type GetAllUserParams = {
  query?: string;
  status?: 'active' | 'inactive';
};

export type OfficeDaysBody = {
  employeeId: string;
  officeDateList: string[];
  officeRecurrence: string[];
  officeDaysYear: number;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Vacation',
    'ProgressBar',
    'UserDTO',
    'WeekDays',
    'AppointmentDTO',
    'SingleAppointmentDTO',
    'SpecialLeaveType',
    'RemainingDays',
    'OfficeDays',
  ],
  endpoints: builder => ({
    getClientsByEmployeeNumber: builder.query<Client[], string>({
      query: employeeId =>
        `/assignment/getUserClients?employeeId=${employeeId}`,
    }),
    getAllClients: builder.query<Client[], void>({
      query: () => '/assignment/getAllClients',
    }),
    getColleaguesByAssignmentIds: builder.query<Colleague[], string>({
      query: assignmentId => `/user/getEmployees?assignmentIds=${assignmentId}`,
    }),
    getUserInfo: builder.query<UserDTO, string>({
      query: employeeId => `/user/getUserInfo/${employeeId}`,
      providesTags: ['UserDTO'],
    }),
    getEmails: builder.query<string[], string>({
      query: email_substring =>
        `/user/getEmployeesEmail?name=${email_substring}`,
    }),
    getUserProfile: builder.query<UserInfoResponse, void>({
      query: () => `/user/profile`,
    }),
    getBankHolidays: builder.query<BankHolidayResponse[], string>({
      query: countryCode => `/bank_holidays/get?countryCode=${countryCode}`,
    }),
    getVacations: builder.query<VacationResponse[], string>({
      query: userId => `/vacations/getAll?employeeId=${userId}`,
      providesTags: ['Vacation'],
    }),
    getAllEmployees: builder.query<Colleague[], number | undefined | null>({
      query: (year?: number | undefined) => {
        if (!year) {
          const date = new Date();

          return `/user/getActiveEmployees?year=${date.getFullYear()}`;
        }

        return `/user/getActiveEmployees?year=${year}`;
      },
      providesTags: ['RemainingDays'],
    }),
    getAllUsersInfo: builder.query<UserDTO[], GetAllUserParams>({
      query: ({ query, status }) => ({
        url: `/user/getAllUsersInfo`,
        params: { query, status },
      }),
    }),
    getAppointment: builder.query<AppointmentDTO, number>({
      query: (id: number) => `/appointment/getAppointment/${id}`,
      providesTags: ['SingleAppointmentDTO'],
    }),
    getAllRecurrences: builder.query<AppointmentDTO[], string>({
      query: userId => `/appointment/getAllAppointmentByUser/${userId}`,
      providesTags: ['AppointmentDTO'],
    }),
    deleteAppointment: builder.mutation<AppointmentDTO, AppointmentDTO>({
      query: ({ ...appointment }) => ({
        url: `/appointment/delete/${appointment.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AppointmentDTO', 'SingleAppointmentDTO'],
    }),
    createVacation: builder.mutation<
      VacationResponse,
      VacationModel | { error: string }
    >({
      query: ({ ...vacation }) => ({
        url: `/vacations/create`,
        method: 'POST',
        body: JSON.stringify(vacation),
      }),
      invalidatesTags: ['Vacation', 'ProgressBar'],
    }),
    updateUserProfile: builder.mutation<UserDTO, UserDTO>({
      query: ({ ...body }) => ({
        url: `user/editUser/${body.employeeId}`,
        method: 'PUT',
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['UserDTO'],
    }),
    updateUserProfileByHrOrAdmin: builder.mutation<UserDTO, UserDTO>({
      query: ({ ...body }) => ({
        url: `user/editUserByAdmin/${body.employeeId}`,
        method: 'PUT',
        body: JSON.stringify(body),
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under its results.
      invalidatesTags: ['UserDTO', 'ProgressBar'],
    }),
    updateOfficeAppointment: builder.mutation<AppointmentDTO, AppointmentDTO>({
      query: ({ ...body }) => ({
        url: `appointment/editAppointment/${body.id}`,
        method: 'PUT',
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['AppointmentDTO', 'SingleAppointmentDTO'],
    }),
    createVacationOnBehalfOfEmployee: builder.mutation<
      VacationResponse,
      VacationModel | { error: string }
    >({
      query: ({ ...vacation }) => ({
        url: `/vacations/createVacationOnBehalfOfEmployee`,
        method: 'POST',
        body: JSON.stringify(vacation),
      }),
      invalidatesTags: result =>
        result?.id ? ['Vacation', 'ProgressBar', 'RemainingDays'] : [],
    }),
    createOfficeAppointment: builder.mutation<AppointmentDTO, AppointmentDTO>({
      query: ({ ...appointment }) => ({
        url: `/appointment/addAppointment/${appointment.employeeNumber}`,
        method: 'POST',
        body: JSON.stringify(appointment),
      }),
      invalidatesTags: ['AppointmentDTO'],
    }),
    resendDocumentToSign: builder.mutation<void, string>({
      query: vacationId => ({
        url: `/vacations/resendDocumentToSign`,
        method: 'POST',
        body: vacationId,
      }),
      invalidatesTags: ['Vacation', 'ProgressBar', 'RemainingDays'],
    }),
    cancelVacation: builder.mutation<void, string>({
      query: vacationId => ({
        url: `/vacations/cancel`,
        method: 'POST',
        body: vacationId,
      }),
      invalidatesTags: ['Vacation', 'ProgressBar', 'RemainingDays'],
    }),
    cancelVacationAsAdminOrHr: builder.mutation<void, string>({
      query: vacationId => ({
        url: `/vacations/cancelByAdminOrHR`,
        method: 'POST',
        body: vacationId,
      }),
      invalidatesTags: ['Vacation', 'ProgressBar', 'RemainingDays'],
    }),
    getProgressBarStatus: builder.query<
      ProgressBarInfoResponse,
      ProgressBarInfoRequest
    >({
      query: (body: ProgressBarInfoRequest) => ({
        url: `/progressBar/progressBarInfo`,
        method: 'POST',
        body,
      }),
      providesTags: ['ProgressBar'],
    }),
    getVacationsForMultipleUsers: builder.query<
      VacationResponse[],
      string[] | string
    >({
      query: ([...vacation]) => ({
        url: `/vacations/getAllForMultipleUsers`,
        method: 'POST',
        body: JSON.stringify({ list: vacation }),
      }),
      providesTags: result =>
        // is result available?
        result
          ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'Vacation', id } as const)),
              { type: 'Vacation', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Vacation', id: 'LIST' }` is invalidated
            [{ type: 'Vacation', id: 'LIST' }],
      extraOptions: { maxRetries: 1 },
    }),
    getWorkDaysByEmployeeId: builder.query<WorkDayResponse[], string>({
      query: employeeId => `/workingDay/get?employeeId=${employeeId}`,
      providesTags: ['WeekDays'],
    }),
    createWorkDaysByEmployeeId: builder.mutation<WorkDayResponse, WorkDayDTO>({
      query: ({ employeeId, workingDays }) => ({
        url: '/workingDay/create',
        params: {
          employeeId,
        },
        method: 'POST',
        body: JSON.stringify(workingDays),
      }),
      invalidatesTags: ['WeekDays'],
    }),
    getSpecialLeaveTypes: builder.query<SpecialLeaveType[], void>({
      query: () => `/vacations/getAllSpecialLeaveTypes`,
      providesTags: ['SpecialLeaveType'],
    }),
    getGoogleCredentials: builder.query<GoogleCredentialsDTO, void>({
      query: () => `/calendar/gcredentials`,
    }),
    getCsrfToken: builder.query<string, void>({
      query: () => ({
        url: '/csrf',
        responseHandler: 'text',
      }),
      transformResponse: (response: string) => {
        localStorage.setItem('csrf', response);

        return response || '';
      },
    }),
    downloadVacationReport: builder.mutation<unknown, string>({
      queryFn: async (year, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/reports/exportVacationExcel`,
          body: year,
          method: 'POST',
          responseHandler: response => response.blob(),
        });
        if (result.data) {
          const filename = result?.meta?.response?.headers
            ?.get('content-disposition')
            ?.split('filename=')[1];
          downloadFile(result.data as Blob, filename);

          return { data: null };
        }

        return {
          error: {
            status: 500,
            data: { error: (result.error?.data as { error: string }).error },
          },
        };
      },
    }),
    downloadPayrollReport: builder.mutation<
      unknown,
      { year: string; month: string }
    >({
      queryFn: async ({ year, month }, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/reports/exportPayrollExcel?year=${year}&month=${month}`,
          method: 'POST',
          responseHandler: response => {
            if (response.ok) {
              return response.blob();
            }

            return response.json();
          },
        });
        if (result.data) {
          const filename = result?.meta?.response?.headers
            ?.get('content-disposition')
            ?.split('filename=')[1];
          downloadFile(result.data as Blob, filename);

          return { data: null };
        }

        return {
          error: {
            status: 500,
            data: { error: (result.error?.data as { error: string }).error },
          },
        };
      },
    }),
    downloadLeaveBalanceAnalysisReport: builder.mutation<
      unknown,
      { year: string; month: string }
    >({
      queryFn: async ({ year, month }, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/reports/exportLeaveBalanceAnalysisExcel?year=${year}&month=${month}`,
          method: 'POST',
          responseHandler: response => {
            if (response.ok) {
              return response.blob();
            }

            return response.json();
          },
        });
        if (result.data) {
          const filename = result?.meta?.response?.headers
            ?.get('content-disposition')
            ?.split('filename=')[1];
          downloadFile(result.data as Blob, filename);

          return { data: null };
        }

        return {
          error: {
            status: 500,
            data: { error: (result.error?.data as { error: string }).error },
          },
        };
      },
    }),
    downloadWorkingHoursReport: builder.mutation<
      unknown,
      { date: string; includeSummary: boolean }
    >({
      queryFn: async (
        { date, includeSummary },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `reports/exportWorkdayExcel?date=${date}&includeSummary=${includeSummary}`,
          method: 'POST',
          responseHandler: response => {
            if (response.ok) {
              return response.blob();
            }

            return response.json();
          },
        });
        if (result.data) {
          const filename = result?.meta?.response?.headers
            ?.get('content-disposition')
            ?.split('filename=')[1];
          downloadFile(result.data as Blob, filename);

          return { data: null };
        }

        return {
          error: {
            status: 500,
            data: { error: (result.error?.data as { error: string }).error },
          },
        };
      },
    }),
    getOfficeDays: builder.query<
      OfficeDaysBody,
      { employeeId: string; year: number }
    >({
      query: ({ employeeId, year }) =>
        `/officeDays?employeeId=${employeeId}&year=${year}`,
      providesTags: ['OfficeDays'],
    }),
    updateOfficeDays: builder.mutation<OfficeDaysBody, OfficeDaysBody>({
      query: ({ ...body }) => ({
        url: `/officeDays`,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['OfficeDays'],
    }),
  }),
});

export const selectUserProfile = apiSlice.endpoints.getUserProfile.select();

export const selectUserEmail = createSelector(
  selectUserProfile,
  userProfile => userProfile.data?.email ?? ''
);

export const selectUserRoleList = createSelector(
  selectUserProfile,
  userProfile => userProfile.data?.roleList ?? []
);

export const selectUserEmployeeNumber = createSelector(
  selectUserProfile,
  userProfile => userProfile.data?.employeeNumber ?? ''
);

export const selectUserEmployeeData = createSelector(
  selectUserProfile,
  userProfile => userProfile.data
);

export const selectDays = (progressBarInfoRequest: ProgressBarInfoRequest) =>
  apiSlice.endpoints.getProgressBarStatus.select(progressBarInfoRequest);

export const selectAvailableDays = (
  progressBarInfoRequest: ProgressBarInfoRequest
) =>
  createSelector(
    selectDays(progressBarInfoRequest),
    vacation => vacation.data?.availableDays ?? 0
  );

export const selectBookedDays = (
  progressBarInfoRequest: ProgressBarInfoRequest
) =>
  createSelector(
    selectDays(progressBarInfoRequest),
    vacation => vacation.data?.takenDays ?? 0
  );

export const selectTotalDays = (
  progressBarInfoRequest: ProgressBarInfoRequest
) =>
  createSelector(
    selectDays(progressBarInfoRequest),
    vacation => vacation.data?.totalDays ?? 0
  );

export const selectCarryOverDays = (
  progressBarInfoRequest: ProgressBarInfoRequest
) =>
  createSelector(
    selectDays(progressBarInfoRequest),
    vacation => vacation.data?.carryOverDays ?? 0
  );

export const selectAppointment = (appointment: AppointmentDTO) =>
  apiSlice.endpoints.getAllRecurrences.select(appointment.employeeNumber);

export const selectAppointmentId = (appointments: AppointmentDTO) =>
  createSelector(selectAppointment(appointments), appointmentDTO =>
    appointmentDTO.data?.map((recurrence: AppointmentDTO) => {
      return recurrence.id ?? '';
    })
  );

export const selectOfficeDaysByYear = (
  state: RootState,
  year: number,
  employeeId: string
) =>
  apiSlice.endpoints.getOfficeDays.select({ year, employeeId })(state).data ?? {
    officeDateList: [],
  };

export const {
  useGetClientsByEmployeeNumberQuery,
  useGetAllClientsQuery,
  useGetColleaguesByAssignmentIdsQuery,
  useGetUserProfileQuery,
  useGetBankHolidaysQuery,
  useGetVacationsQuery,
  useCreateVacationMutation,
  useResendDocumentToSignMutation,
  useCancelVacationMutation,
  useCancelVacationAsAdminOrHrMutation,
  useGetProgressBarStatusQuery,
  useGetVacationsForMultipleUsersQuery,
  useCreateVacationOnBehalfOfEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetUserInfoQuery,
  useGetAllUsersInfoQuery,
  useUpdateUserProfileMutation,
  useUpdateUserProfileByHrOrAdminMutation,
  useCreateWorkDaysByEmployeeIdMutation,
  useGetWorkDaysByEmployeeIdQuery,
  useGetAllRecurrencesQuery,
  useCreateOfficeAppointmentMutation,
  useDeleteAppointmentMutation,
  useUpdateOfficeAppointmentMutation,
  useGetAppointmentQuery,
  useGetSpecialLeaveTypesQuery,
  useGetGoogleCredentialsQuery,
  useGetCsrfTokenQuery,
  useDownloadVacationReportMutation,
  useDownloadPayrollReportMutation,
  useDownloadLeaveBalanceAnalysisReportMutation,
  useDownloadWorkingHoursReportMutation,
  useGetOfficeDaysQuery,
  useUpdateOfficeDaysMutation,
  useGetEmailsQuery,
} = apiSlice;
