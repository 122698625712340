import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';
import {
  useSelector as rawUseSelector,
  TypedUseSelectorHook,
  useDispatch as rawUseDispatch,
} from 'react-redux';

import storeCalendarReducer from './storeCalendarSlice';
import storeUserReducer from './storeUserSlice';
import storeSnackbarReducer from './storeSnackbarSlice';
import storeDisclosureSlice from './storeDisclosureSlice';
import storeCalendarFilterSlice from './storeCalendarFilterSlice';

import { apiSlice } from './apiSlice';

export const store = configureStore({
  reducer: {
    calendar: storeCalendarReducer,
    user: storeUserReducer,
    snackbar: storeSnackbarReducer,
    disclosure: storeDisclosureSlice,
    calendarFilter: storeCalendarFilterSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export const setupStoreForTests = (
  preloadedState?: PreloadedState<RootState>
) => {
  return configureStore({
    reducer: {
      calendar: storeCalendarReducer,
      user: storeUserReducer,
      snackbar: storeSnackbarReducer,
      disclosure: storeDisclosureSlice,
      calendarFilter: storeCalendarFilterSlice,
      [apiSlice.reducerPath]: apiSlice.reducer,
    },
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(apiSlice.middleware),
  });
};

export const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  calendar: storeCalendarReducer,
  user: storeUserReducer,
  snackbar: storeSnackbarReducer,
  disclosure: storeDisclosureSlice,
  calendarFilter: storeCalendarFilterSlice,
});

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStoreForTests>;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
export const useDispatch: () => AppDispatch = rawUseDispatch;
