/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Backdrop, Box, Fade, Paper, Popper } from '@mui/material';
import { useDispatch } from 'react-redux';

import './Calendar.scss';
import { RootState, useSelector } from 'app-redux/store';
import {
  Leave,
  sortVacations,
  transformArrayOfLeaveTypeFromResponse,
} from 'utils/calendar-utils';
import ListView from './ListView/ListView';
import GridView from './GridView/GridView';
import { useGetVacationsForMultipleUsersQuery } from 'app-redux/apiSlice';
import { closeEditForm } from 'app-redux/storeCalendarSlice';
import MonthAndYearStepper from '../MonthYearStepper';
import MonthYearViewChanger from '../MonthYearViewChanger';
import GridListViewChanger from '../GridListViewChanger';
import { LEAVE_TYPE_MAP } from 'containers/Layout/forms/BookLeaveForm';
import LeaveForm from 'containers/Layout/forms/BookLeaveForm/LeaveForm';
import Typography from 'components/atoms/Typography';
import { HAS_ACCESS, hasAccess } from 'utils/roleManagement';

function RenderCalendar({ displayStyle }: { displayStyle: 'list' | 'grid' }) {
  const colleaguesFromFilter = useSelector(
    (state: RootState) => state.calendarFilter.selectedColleagues
  );
  const { selectedColleagues, selectedProjectId, employeeNumber } = useSelector(
    (state: RootState) => state.user
  );

  const leaves: Leave[] = [];
  const usersId: string[] = [];
  selectedColleagues[selectedProjectId]?.forEach((e: string) => {
    usersId.push(e);
  });
  const { data, isSuccess } = useGetVacationsForMultipleUsersQuery(
    colleaguesFromFilter.map(colleague => colleague.employeeId),
    {
      skip: colleaguesFromFilter.length === 0,
    }
  );
  if (isSuccess) {
    leaves.push(...transformArrayOfLeaveTypeFromResponse(data, employeeNumber));
  }

  if (displayStyle === 'list') {
    return <ListView leaves={sortVacations(leaves)} />;
  }

  return <GridView leaves={leaves} />;
}

function Calendar(): JSX.Element {
  const isUser = hasAccess(HAS_ACCESS.USER);
  const dispatch = useDispatch();

  const displayStyle = useSelector(
    (state: RootState) => state.calendarFilter.calendarViewType
  );

  const { selectedVacation, editFormAnchorEl } = useSelector(
    (state: RootState) => state.calendar
  );

  const handleClose = () => {
    if (selectedVacation) {
      const allVacations = document.querySelectorAll<HTMLElement>(
        `[data-vacationId="${selectedVacation.id}"]`
      );
      allVacations.forEach(el => {
        el.style.zIndex = '0';
      });
    }
    dispatch(closeEditForm());
  };

  return (
    <Box
      role="calendarFrame"
      className="Calendar"
      display="flex"
      flexDirection={'column'}
      overflow={'auto'}
      width={'100%'}
    >
      <Box
        paddingTop={'24px'}
        paddingLeft={'24px'}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="calendar-filter"
      >
        <MonthAndYearStepper />
        <Box className="Toggles">
          <Box paddingRight="20px">
            <MonthYearViewChanger />
          </Box>
          <Box paddingRight="64px">
            <GridListViewChanger />
          </Box>
        </Box>
      </Box>
      <RenderCalendar displayStyle={displayStyle} />
      {displayStyle === 'grid' && (
        <Backdrop
          open={!!selectedVacation}
          sx={{ zIndex: 2 }}
          onClick={event => {
            const isBackdrop =
              //@ts-ignore
              event.target.classList.contains('MuiBackdrop-root');
            isBackdrop && handleClose();
          }}
        >
          <Popper
            anchorEl={editFormAnchorEl}
            placement="top-start"
            transition
            open={!!selectedVacation}
            sx={{ zIndex: 3 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  sx={{
                    padding: '24px',
                    marginBottom: '12px',
                    marginTop: '12px',
                  }}
                >
                  {selectedVacation && (
                    <>
                      <Typography
                        color="ixColorGrey60"
                        variant="h6"
                        sx={{ marginBottom: '32px' }}
                      >
                        {(selectedVacation.isMyself
                          ? "You're "
                          : selectedVacation.fullname + ' is ') + 'on holiday'}
                        {isUser &&
                          selectedVacation.isMyself &&
                          ` - ${
                            LEAVE_TYPE_MAP[
                              selectedVacation.type as keyof typeof LEAVE_TYPE_MAP
                            ]
                          }`}
                      </Typography>
                      <LeaveForm
                        vacation={selectedVacation}
                        isEditing
                        leaveType={
                          LEAVE_TYPE_MAP[
                            selectedVacation.type as keyof typeof LEAVE_TYPE_MAP
                          ]
                        }
                        onCloseEdit={handleClose}
                      />
                    </>
                  )}
                </Paper>
              </Fade>
            )}
          </Popper>
        </Backdrop>
      )}
    </Box>
  );
}

export default Calendar;
