import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import Form, {
  FormProvider,
  useForm,
  yupResolver,
} from 'components/atoms/Form';
import FormSelect from 'components/atoms/Form/components/FormSelect';
import FormDatePicker from 'components/atoms/Form/components/FormDatePicker';
import FormInput from 'components/atoms/Form/components/FormInput';
import FormSubmit from 'components/atoms/Form/components/FormSubmit';
import Typography from 'components/atoms/Typography';
import Spinner from 'components/molecules/Spinner';
import {
  UserDTO,
  useUpdateUserProfileByHrOrAdminMutation,
} from 'app-redux/apiSlice';
import {
  openErrorSnackbar,
  openSuccessSnackbar,
} from 'app-redux/storeSnackbarSlice';
import { isErrorWithMessage, isFetchBaseQueryError } from 'utils/api';
import { HAS_ACCESS, hasAccess } from 'utils/roleManagement';
import { convertToUTCTime } from 'utils/convertToUTCTime';

type IJobDetailForm = {
  position?: string;
  department?: string;
  role: string;
  contractType: string;
  employeeId?: string;
  contractEndDate: Date | null;
  active?: string;
  workingHoursPerDay: string;
  cutbackDays: string | null;
};

type JobDetailsFormProps = {
  defaultValues?: UserDTO;
};

const JobDetailsForm = (props: JobDetailsFormProps) => {
  const { defaultValues } = props;
  const dispatch = useDispatch();
  const isUser = hasAccess(HAS_ACCESS.USER);
  const isAdmin = hasAccess(HAS_ACCESS.ADMIN);

  const [updateUserByHrOrAdmin, resultUpdateUserByHrOrAdmin] =
    useUpdateUserProfileByHrOrAdminMutation();

  const formSchema = Yup.object().shape({
    position: Yup.string(),
    department: Yup.string(),
    role: Yup.string().oneOf(['ADMIN', 'HR', 'USER', 'REPORTER']).required(),
    contractType: Yup.string().oneOf(['permanent', 'part-time']).nullable(),
    employeeId: Yup.string().required(),
    contractEndDate: Yup.date().nullable(),
    active: Yup.string().oneOf(['1', '0']).required(),
    workingHoursPerDay: Yup.number()
      .min(0, 'Field must be more than or equal to 0')
      .max(24, 'Field must be less than or equal to 24')
      .nullable(),
    cutbackDays: Yup.number()
      .min(0, 'Field must be more than or equal to 0')
      .nullable(),
  });

  const methods = useForm<IJobDetailForm>({
    resolver: yupResolver(formSchema),
    shouldUnregister: true,
    mode: 'onChange',
    defaultValues: {
      active: defaultValues?.active ? '1' : '0',
      contractEndDate: defaultValues?.contractEndDate,
      contractType: defaultValues?.contractType,
      department: defaultValues?.department,
      employeeId: defaultValues?.employeeId,
      position: defaultValues?.position,
      role: defaultValues?.role,
      workingHoursPerDay: defaultValues?.workingHoursPerDay,
      cutbackDays: defaultValues?.cutbackDays,
    },
  });

  const disableSubmit = !methods.formState.isDirty;
  const activeValue = methods.watch(
    'active',
    defaultValues?.active ? '1' : '0'
  );
  const contractEndDateValue = methods.watch(
    'contractEndDate',
    defaultValues?.contractEndDate
  );

  const handleDisableDates = (day: Date) => {
    if (contractEndDateValue) {
      if (typeof contractEndDateValue === 'string') {
        if (activeValue === '1') {
          return day.getTime() < new Date(contractEndDateValue).getTime();
        } else {
          return day.getTime() > new Date(contractEndDateValue).getTime();
        }
      }
    }

    return false;
  };

  const onSubmitHandler = methods.handleSubmit(async values => {
    try {
      if (defaultValues) {
        await updateUserByHrOrAdmin({
          ...defaultValues,
          role: values.role,
          contractType: values.contractType,
          contractEndDate: values.contractEndDate
            ? convertToUTCTime(values.contractEndDate)
            : null,
          active: values.active === '1' ? true : false,
          workingHoursPerDay: values.workingHoursPerDay,
          cutbackDays: values.cutbackDays,
        }).unwrap();
        dispatch(openSuccessSnackbar('User information updated successfully.'));
        methods.reset({}, { keepValues: true });
      }
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        const errMsg = (err.data as { error: string }).error;
        dispatch(openErrorSnackbar(errMsg));
      } else if (isErrorWithMessage(err)) {
        dispatch(openErrorSnackbar(err.message));
      }
    }
  });

  return (
    <FormProvider {...methods}>
      {resultUpdateUserByHrOrAdmin.isLoading && (
        <Spinner fullPage text="User information is updating..." />
      )}
      <Typography variant="h5" sx={{ marginBottom: '24px' }}>
        Job Details
      </Typography>
      <Box sx={{ width: '433px' }}>
        <Form onSubmit={onSubmitHandler} noValidate autoComplete="off">
          <FormInput
            name="position"
            label="Position"
            InputProps={{ readOnly: true }}
          />
          <FormInput
            name="department"
            label="Department"
            InputProps={{ readOnly: true }}
          />
          <FormSelect
            disabled={!isAdmin}
            name="role"
            label="Role"
            searchable={false}
          >
            {isAdmin && (
              <FormSelect.MenuItem value="ADMIN">Admin</FormSelect.MenuItem>
            )}
            <FormSelect.MenuItem value="HR">HR</FormSelect.MenuItem>
            <FormSelect.MenuItem value="USER">User</FormSelect.MenuItem>
            <FormSelect.MenuItem value="REPORTER">Reporter</FormSelect.MenuItem>
          </FormSelect>
          <FormSelect
            searchable={false}
            disabled={isUser}
            name="contractType"
            label="Contract Type"
          >
            <FormSelect.MenuItem value="permanent">
              Permanent
            </FormSelect.MenuItem>
            <FormSelect.MenuItem value="part-time">
              Part-time
            </FormSelect.MenuItem>
          </FormSelect>
          <FormInput
            name="employeeId"
            label="Employee ID"
            InputProps={{ readOnly: true }}
          />
          <FormDatePicker
            label="End Date"
            name="contractEndDate"
            helperText="Vacations after this date will be deleted."
            readOnly={isUser}
            shouldDisableDate={handleDisableDates}
            disablePast={activeValue === '1'}
            disableFuture={activeValue === '0'}
          />
          <FormSelect
            name="active"
            label="Active"
            disabled={isUser}
            searchable={false}
          >
            <FormSelect.MenuItem value="1">True</FormSelect.MenuItem>
            <FormSelect.MenuItem value="0">False</FormSelect.MenuItem>
          </FormSelect>
          <FormInput
            name="workingHoursPerDay"
            label="Working hours per day"
            InputProps={{ readOnly: isUser }}
            type="number"
            inputProps={{
              min: '0',
              max: '24',
              step: '1',
            }}
          />
          <FormInput
            name="cutbackDays"
            label="Cutback Days"
            InputProps={{ readOnly: isUser }}
            disabled={isUser}
            type="number"
            inputProps={{
              min: '0',
              step: '1',
            }}
          />
          <FormSubmit disabled={disableSubmit} variant="contained">
            Update
          </FormSubmit>
        </Form>
      </Box>
    </FormProvider>
  );
};

export default JobDetailsForm;
