import { Box, CircularProgress, circularProgressClasses } from '@mui/material';

type SpinnerProps = {
  text?: string;
  fullPage?: boolean;
};

//TODO: Move spinner to new atom components folder
const Spinner = (props: SpinnerProps) => {
  const { text, fullPage = false } = props;
  const fullPageStyles = {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgba(255,255,255,0.5)',
    backdropFilter: 'blur(4px)',
    zIndex: 999,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        ...(fullPage && { ...fullPageStyles }),
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: theme => theme.palette.grey[200],
          }}
          size={25}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: '#002936',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={25}
          thickness={4}
        />
      </Box>
      {text ? <Box sx={{ marginTop: 1 }}>{text}</Box> : null}
    </Box>
  );
};

export default Spinner;
