import { createSvgIcon } from '@mui/material';

const AlertIcon = createSvgIcon(
  <>
    <path id="Path_282" data-name="Path 282" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_283"
      data-name="Path 283"
      d="M12.866,3l9.526,16.5a1,1,0,0,1-.866,1.5H2.474a1,1,0,0,1-.866-1.5L11.134,3a1,1,0,0,1,1.732,0ZM4.206,19H19.794L12,5.5,4.206,19ZM11,16h2v2H11Zm0-7h2v5H11Z"
      fill="currentColor"
    />
  </>,
  'Alert'
);

export default AlertIcon;
