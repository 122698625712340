import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'app-redux/store';
import { clearSnackbar } from 'app-redux/storeSnackbarSlice';
import Alert, { AlertProps } from '../Alert';

type SnackbarProps = {
  open: boolean;
  message: string;
  severity: AlertProps['variant'];
};

export const Notification = () => {
  const dispatch = useDispatch();
  const state: SnackbarProps = useSelector(
    (state: RootState) => state.snackbar
  );

  const handleClose = () => {
    dispatch(clearSnackbar());
  };

  return (
    <Snackbar
      ClickAwayListenerProps={{ onClickAway: () => null }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
      open={state.open}
      onClose={handleClose}
    >
      <Box>
        <Alert
          variant={state.severity}
          message={state.message}
          onClose={handleClose}
        />
      </Box>
    </Snackbar>
  );
};
