import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

type ResizeableProps = {
  height?: number;
  collapsed?: boolean;
} & BoxProps;

type WrapperProps = {
  hasBottom: boolean;
  hasTop: boolean;
  dragged: boolean;
};

const generateBoxShadowValue = (hasBottom: boolean, hasTop: boolean) => {
  if (hasTop && hasBottom) {
    return `inset 0 15px 20px -20px rgba(0,0,0,0.25),
            inset 0 -15px 20px -20px rgba(0,0,0,0.25)`;
  }
  if (hasBottom) {
    return 'inset 0 -15px 20px -20px rgba(0,0,0,0.25)';
  }
  if (hasTop) {
    return 'inset 0 15px 20px -20px rgba(0,0,0,0.25)';
  }

  return 'none';
};

const Wrapper = styled(Box)<WrapperProps>(
  ({ hasBottom, hasTop, height, dragged }) => ({
    willChange: 'height',
    overflow: 'auto',
    height: `${height}px`,
    transition: dragged ? 'none' : 'all 0.3s ease',
    boxShadow: generateBoxShadowValue(hasBottom, hasTop),
  })
);

const Handler = styled(Box)(({ theme }) => ({
  cursor: 'ns-resize',
  height: '2px',
  transition: 'height 0.3s ease',
  backgroundColor: theme.palette.ixColorGrey20.main,
  '&:hover': {
    height: '4px',
  },
}));

const Resizeable = (props: ResizeableProps) => {
  const { height = 250, minHeight = 250, maxHeight = 500, collapsed } = props;
  const [dragged, setDragged] = useState(false);
  const [internalHeight, setInternalHeight] = useState(height);
  const [hasTop, setHasTop] = useState(false);
  const [hasBottom, setHasBottom] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (ref.current) {
      setHasTop(ref.current.scrollTop > 0);
      setHasBottom(
        ref.current.scrollHeight -
          ref.current.scrollTop -
          ref.current.clientHeight >
          0
      );
    }
  };

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    setHasBottom(
      element.scrollHeight - element.scrollTop - element.clientHeight > 0
    );
    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Wrapper
        {...props}
        hasBottom={hasBottom}
        hasTop={hasTop}
        ref={ref}
        height={collapsed ? 0 : internalHeight}
        minHeight={collapsed ? 0 : minHeight}
        maxHeight={maxHeight}
        dragged={dragged}
      >
        {props.children}
      </Wrapper>
      {!collapsed && (
        <Handler
          onMouseDown={e => {
            const originalHeight = internalHeight;
            const originalMouseY = e.pageY;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const resize = (e: any) => {
              const newHeight = originalHeight + (e.pageY - originalMouseY);
              if (newHeight > Number(maxHeight)) {
                setInternalHeight(Number(maxHeight));
              } else if (newHeight < Number(minHeight)) {
                setInternalHeight(Number(minHeight));
              } else {
                setInternalHeight(newHeight);
              }
              setDragged(true);
              handleScroll();
            };
            document.getElementsByTagName('body')[0].style.userSelect = 'none';
            document.getElementsByTagName('body')[0].style.cursor = 'ns-resize';

            document.addEventListener('mousemove', resize);
            document.addEventListener('mouseup', () => {
              setDragged(false);
              document.getElementsByTagName('body')[0].style.userSelect =
                'auto';
              document.getElementsByTagName('body')[0].style.cursor = 'default';
              document.removeEventListener('mousemove', resize);
            });
          }}
        />
      )}
    </>
  );
};

export default Resizeable;
