import { SxProps, styled } from '@mui/material/styles';

type SeparatorProps = {
  thick?: boolean;
  sx?: SxProps;
};

const StyledLine = styled('div')<SeparatorProps>(({ theme, thick }) => ({
  width: '100%',
  backgroundColor: theme.palette.ixColorGrey20.main,
  height: thick ? '4px' : '2px',
}));

const Separator = (props: SeparatorProps) => {
  return <StyledLine {...props} />;
};

export default Separator;
