import { createSvgIcon } from '@mui/material';

const ErrorIcon = createSvgIcon(
  <>
    <path id="Path_287" data-name="Path 287" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Icons_error"
      data-name="Icons / error"
      d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm-1-5h2v2H11Zm0-8h2v6H11Z"
      fill="currentColor"
    />
  </>,
  'Error'
);

export default ErrorIcon;
