import { useSelector } from './app-redux/store';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import { selectUserEmployeeData } from 'app-redux/apiSlice';
import { SecuredRoute } from 'containers/Layout/Secured/SecuredRoute';
import AppHeader from 'components/molecules/AppHeader';
import { Notification } from 'components/molecules/Notification/Notification';
import Home from 'containers/Home';
import ErrorPage from 'containers/ErrorPage';
import PersonalDetailsView from 'containers/EmployeeSettings/PersonalDetails';
import JobDetailsView from 'containers/EmployeeSettings/JobDetails';
import OfficeDaysView from 'containers/EmployeeSettings/OfficeDays';
import WeekDaysView from 'containers/EmployeeSettings/WeekDays';
import EmployeeSettingsLayout from 'containers/EmployeeSettings/shared/EmployeeSettingsLayout';
import ReportLayout from 'containers/Report/shared/ReportLayout';
import PayrollTimesheetView from 'containers/Report/PayrollTimesheet';
import VacationsView from 'containers/Report/Vacations';
import WorkingHoursView from 'containers/Report/WorkingHours';
import LeaveBalanceAnalysisView from 'containers/Report/LeaveBalanceAnalysis';
import LogView from 'containers/Report/Log';
import EmployeeList from 'containers/EmployeeList';

function ApplicationRoutes(): JSX.Element {
  const currentUser = useSelector(selectUserEmployeeData);
  const currentUserFullName = `${currentUser?.firstName} ${currentUser?.lastName}`;

  return (
    <Router>
      <Notification />
      <AppHeader
        employeeNumber={currentUser?.employeeNumber}
        employeeFullName={currentUserFullName}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route element={<SecuredRoute roles={['HR', 'ADMIN']} />}>
          <Route path="/employees" element={<EmployeeList />} />
        </Route>
        <Route element={<EmployeeSettingsLayout />}>
          {/* Personal Details View Route */}
          <Route
            path="/employees/personal-details/:employeeId"
            element={<PersonalDetailsView />}
          />
          <Route
            path="/employees/personal-details"
            element={
              <Navigate
                to={`/employees/personal-details/${currentUser?.employeeNumber}`}
              />
            }
          />
          {/* Job Details View Route */}
          <Route
            path="/employees/job-details/:employeeId"
            element={<JobDetailsView />}
          />
          <Route
            path="/employees/job-details"
            element={
              <Navigate
                to={`/employees/job-details/${currentUser?.employeeNumber}`}
              />
            }
          />
          {/* Week Days View Route */}
          <Route
            path="/employees/week-days/:employeeId"
            element={<WeekDaysView />}
          />
          <Route
            path="/employees/week-days"
            element={
              <Navigate
                to={`/employees/week-days/${currentUser?.employeeNumber}`}
              />
            }
          />
          {/* Office Days View Route */}
          <Route
            path="/employees/office-days/:employeeId"
            element={<OfficeDaysView />}
          />
          <Route
            path="/employees/office-days"
            element={
              <Navigate
                to={`/employees/office-days/${currentUser?.employeeNumber}`}
              />
            }
          />
        </Route>
        <Route element={<SecuredRoute roles={['HR', 'ADMIN', 'REPORTER']} />}>
          <Route element={<ReportLayout />}>
            <Route
              path="/reports"
              element={<Navigate to="/reports/payroll-timesheet" />}
            />
            <Route
              path="/reports/payroll-timesheet"
              element={<PayrollTimesheetView />}
            />
            <Route path="/reports/vacations" element={<VacationsView />} />
            <Route
              path="/reports/working-hours"
              element={<WorkingHoursView />}
            />
            <Route
              path="/reports/leave-balance-analysis"
              element={<LeaveBalanceAnalysisView />}
            />
            <Route path="/reports/log" element={<LogView />} />
          </Route>
        </Route>
        <Route path="error" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to={'/404.html'} />} />
      </Routes>
    </Router>
  );
}

export default ApplicationRoutes;
