import MuiMenu, { MenuProps } from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

const Menu = (props: MenuProps) => {
  const { children } = props;

  return <MuiMenu {...props}>{children}</MuiMenu>;
};

Menu.Item = MuiMenuItem;
Menu.Divider = Divider;

export default Menu;
