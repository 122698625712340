import { Alert, Box, Snackbar } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BankHolidayResponse,
  selectUserRoleList,
  useGetVacationsQuery,
  VacationResponse,
} from '../../../../../../../app-redux/apiSlice';
import { RootState, useSelector } from '../../../../../../../app-redux/store';
import {
  clearRangeSelection,
  moveRangeDaySelection,
  startRangeDaySelection,
  stopRangeDaySelection,
} from '../../../../../../../app-redux/storeCalendarSlice';
import theme from '../../../../../../../theme';
import {
  isBankHoliday,
  isBetweenTwoDates,
  isDayOfWeekend,
  isToday,
  Leave as LeaveType,
  MONTHS,
} from '../../../../../../../utils/calendar-utils';
import BankHoliday, { HolidayProps } from '../BankHoliday';
import Leave, { LeaveProps } from '../Leave';
import { YearlyGridViewDay } from '../YearlyGridViewDay';
import './Day.scss';
import { open as openBookLeaveForm } from '../../../../../../../app-redux/storeDisclosureSlice';
import Typography from '../../../../../../../components/atoms/Typography';
import MoreLeaves from '../../More';

type DayProps = {
  isFreeDay?: boolean;
  bankHoliday?: HolidayProps;
  isFromCurrentMonth?: boolean;
  day: number;
  month: number;
  year: number;
  monthName?: boolean;
  vacations?: Array<LeaveType>;
  leavePoz1?: LeaveProps;
  leavePoz2?: LeaveProps;
  more?: Array<LeaveType>;
  bankHolidays?: BankHolidayResponse[];
};

const checkDayHasLeave = (
  date: Date,
  vacations: Array<VacationResponse> = []
) => {
  return vacations?.find(element => {
    const startDate = new Date(element.startDate);
    const endDate = new Date(element.endDate);

    return isBetweenTwoDates(startDate, endDate, date);
  });
};

const checkRangeHasVacation = (
  startDate: Date,
  endDate: Date,
  vacations: Array<VacationResponse> = []
) => {
  return vacations.some(vacation =>
    isBetweenTwoDates(startDate, endDate, new Date(vacation.startDate))
  );
};

const getFirstValidDate = (
  date: Date,
  bankHolidays: BankHolidayResponse[] = [],
  vacations?: Array<VacationResponse>
) => {
  if (
    isDayOfWeekend(date) ||
    isBankHoliday(date, bankHolidays) ||
    checkDayHasLeave(date, vacations)
  ) {
    date.setDate(date.getDate() - 1);
    getFirstValidDate(date, bankHolidays, vacations);
  }

  return date;
};

const bookLeaveFormPopperStoreKey = 'bookLeaveFormPopper';

// TODO: refactor everything here
const Day = (props: DayProps) => {
  const [isOpenRangeError, setIsOpenRangeError] = useState(false);
  const dispatch = useDispatch();
  const selection = useSelector(
    (state: RootState) => state.calendar.dateSelection
  );
  const periodView = useSelector(
    (state: RootState) => state.calendarFilter.periodViewType
  );
  const employeeRoleList = useSelector(selectUserRoleList);
  const employeeNumber = useSelector(
    (state: RootState) => state.user.employeeNumber
  );
  const { data: currentUserVacations } = useGetVacationsQuery(employeeNumber, {
    skip: !employeeNumber,
  });

  const today =
    props.isFromCurrentMonth && isToday(props.day, props.month, props.year);
  const dateStored = new Date(props.year, props.month, props.day);
  const text =
    props.monthName && !props.isFromCurrentMonth
      ? MONTHS[props.month] + ' ' + String(props.day)
      : String(props.day);
  const hasVacations =
    props.leavePoz1 || props.leavePoz2 || props.more || props.bankHoliday;
  //check if is allowed to have a leave this day
  const isMyselfIncludedInLeaves =
    props.vacations?.find(element => element.isMyself === true) &&
    employeeRoleList[0] === 'USER';
  const allowRangeBookLeave =
    dateStored.getTime() > new Date().getTime() &&
    (!props.vacations || !isMyselfIncludedInLeaves);
  const isThisDayIncludedInSelectionRange = isBetweenTwoDates(
    new Date(selection.dateStart),
    new Date(selection.dateEnd),
    dateStored
  );

  const endDateSelectionIsValid = !(
    props.isFreeDay || checkDayHasLeave(dateStored, currentUserVacations)
  );

  const setBorderColor = () => {
    if (periodView === 'year') {
      return 'none';
    } else if (today) {
      return `1px solid ${theme.palette.calendarFrame.todayTextColor}`;
    } else if (isThisDayIncludedInSelectionRange) {
      return `1px solid ${theme.palette.calendarFrame.selectionBorderColor}`;
    }

    return `1px solid ${theme.palette.calendarFrame.borderColor}`;
  };

  const setBackgroundColor = () => {
    if (isThisDayIncludedInSelectionRange && props.isFreeDay) {
      return theme.palette.calendarFrame.selectionColorSpecial;
    } else if (isThisDayIncludedInSelectionRange && allowRangeBookLeave) {
      return theme.palette.calendarFrame.selectionColor;
    } else if (isThisDayIncludedInSelectionRange && isMyselfIncludedInLeaves) {
      return theme.palette.calendarFrame.selectionColor;
    } else if (props.isFreeDay) {
      return theme.palette.calendarFrame.dayOff;
    }

    return 'white';
  };
  const borderStyle = setBorderColor();

  return (
    <Box
      onMouseDown={(event: MouseEvent<HTMLDivElement>) => {
        const target = event.target as HTMLDivElement;
        const isDayTarget = target.getAttribute('role') === 'day';

        if (
          allowRangeBookLeave &&
          (isDayTarget || selection.selectionInProgress)
        ) {
          if (!props.isFreeDay) {
            dispatch(startRangeDaySelection(dateStored.getTime()));
          }
        }
      }}
      onMouseUp={() => {
        let hasVacationBetweenRanges = false;
        if (selection.selectionInProgress) {
          // first check end date is valid
          if (endDateSelectionIsValid) {
            hasVacationBetweenRanges = checkRangeHasVacation(
              new Date(selection.dateStart),
              dateStored,
              currentUserVacations
            );
            // check has vacation selection dates. role should be USER.
            if (hasVacationBetweenRanges && employeeRoleList[0] === 'USER') {
              dispatch(clearRangeSelection());
              setIsOpenRangeError(true);
            } else {
              dispatch(stopRangeDaySelection(dateStored.getTime()));
              dispatch(openBookLeaveForm(bookLeaveFormPopperStoreKey));
            }
          } else {
            // get first valid date before invalid selection(includes weekends/holidays/already booked)
            const firstValidDate = getFirstValidDate(
              dateStored,
              props.bankHolidays,
              employeeRoleList[0] === 'USER' ? currentUserVacations : []
            );
            hasVacationBetweenRanges = checkRangeHasVacation(
              new Date(selection.dateStart),
              firstValidDate,
              currentUserVacations
            );
            if (hasVacationBetweenRanges && employeeRoleList[0] === 'USER') {
              dispatch(clearRangeSelection());
              setIsOpenRangeError(true);
            } else {
              dispatch(stopRangeDaySelection(firstValidDate.getTime()));
              dispatch(openBookLeaveForm(bookLeaveFormPopperStoreKey));
            }
          }
        }
      }}
      onMouseOver={() => {
        if (allowRangeBookLeave && selection.selectionInProgress) {
          dispatch(moveRangeDaySelection(dateStored.getTime()));
        }
      }}
      role={'calendarDay'}
      data-testid={
        String(props.day) + '-' + String(props.month) + '-' + String(props.year)
      }
      sx={{
        userSelect: 'none',
        cursor:
          isMyselfIncludedInLeaves ||
          !allowRangeBookLeave ||
          (props.isFreeDay && !selection.selectionInProgress)
            ? 'not-allowed'
            : 'pointer',
        transition: 'all 0.3s ease',
        border: borderStyle,
        borderLeft: dateStored.getDay() === 1 ? 'none' : borderStyle,
        textAlign: 'center',
        height: '100%',
        backgroundColor: setBackgroundColor(),
        color: today
          ? theme.palette.calendarFrame.todayTextColor
          : props.isFromCurrentMonth
          ? 'info.main'
          : 'info.light',
      }}
    >
      {periodView === 'month' ? (
        <Box height={'100%'} role={'day'}>
          <Snackbar
            onClose={() => setIsOpenRangeError(false)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={isOpenRangeError}
            autoHideDuration={3000}
            action={<div>ac</div>}
          >
            <Alert severity="error" onClose={() => setIsOpenRangeError(false)}>
              You cannot create a leave for already booked days. Please check
              your leave history.
            </Alert>
          </Snackbar>
          <Typography variant="caption">{text}</Typography>
          {hasVacations && (
            <Box
              height={'75%'}
              role={'day'}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {props.bankHoliday && <BankHoliday {...props.bankHoliday} />}
              {props.leavePoz1 && <Leave {...props.leavePoz1} />}
              {!props.leavePoz1 && !props.bankHoliday && (
                <Box
                  role={'day'}
                  marginLeft="5%"
                  visibility="hidden"
                  height="24px"
                  minHeight="24px"
                  className="empty-leave-1"
                />
              )}
              {props.leavePoz2 && (
                <Leave
                  {...props.leavePoz2}
                  sx={{ marginTop: '8px', marginBottom: '8px' }}
                  secondLeave
                />
              )}
              {!props.leavePoz2 && (
                <Box
                  role={'day'}
                  marginLeft="5%"
                  visibility="hidden"
                  height="40px"
                  minHeight="40px"
                  className="empty-leave-2"
                />
              )}
              {props.more && props.more.length > 0 ? (
                <MoreLeaves vacations={props.more} />
              ) : (
                <Box
                  role={'day'}
                  marginLeft="5%"
                  visibility="hidden"
                  height="24px"
                  minHeight="24px"
                  className="empty-leave-3"
                />
              )}
            </Box>
          )}
        </Box>
      ) : (
        <YearlyGridViewDay
          isFreeDay={props.isFreeDay}
          vacations={props.vacations}
          opacity={props.vacations && !props.isFromCurrentMonth ? '0.5' : '1'}
          text={text}
          textColor={
            props.vacations &&
            (today ? theme.palette.calendarFrame.todayTextColor : 'dark')
          }
          dateStored={dateStored}
        />
      )}
    </Box>
  );
};

export default Day;
