import { ReactNode, Ref, forwardRef } from 'react';
import { TextFieldProps } from '@mui/material';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGBLocale from 'date-fns/locale/en-GB';

import Input from '../Input';

export type InternalDatePickerProps = Omit<
  DatePickerProps<Date, Date>,
  'renderInput'
> & {
  name?: string;
  helperText?: ReactNode;
  error?: boolean;
  placeholder?: string;
};

const DatePicker = forwardRef(
  (props: InternalDatePickerProps, ref: Ref<HTMLDivElement>) => {
    const { value, onChange, label, helperText, error, placeholder, ...rest } =
      props;

    const handleRenderInput = (params: TextFieldProps, value: Date | null) => {
      return (
        <Input
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder,
          }}
          type="text"
          value={value}
          label={label}
          helperText={helperText}
          error={error}
          sx={{
            ...params.sx,
            marginBottom: 0,
          }}
        />
      );
    };

    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={enGBLocale}
      >
        <MuiDatePicker
          {...rest}
          ref={ref}
          value={value}
          onChange={onChange}
          renderInput={params => handleRenderInput(params, value)}
          PaperProps={{
            sx: theme => ({
              '.MuiDayPicker-weekDayLabel': {
                color: theme.palette.ixColorGrey70.main,
                fontWeight: 600,
                fontSize: '16px',
              },
              '.PrivatePickersYear-yearButton:hover': {
                backgroundColor: theme.palette.ixColorGrey70.main,
              },
              '.PrivatePickersYear-yearButton.Mui-selected': {
                backgroundColor: theme.palette.ixColorWave.main,
              },
              '.MuiPickersCalendarHeader-label': {
                color: theme.palette.ixColorGrey90.main,
                fontSize: '16px',
                fontWeight: 600,
              },
              '.MuiPickersDay-root': {
                fontSize: '16px',
                transition: 'all 0.3s ease',
              },
              '.MuiPickersDay-root:hover': {
                backgroundColor: theme.palette.ixColorGrey70.main,
                color: theme.palette.ixColorTalc.main,
              },
              '.MuiPickersDay-root.Mui-selected': {
                backgroundColor: theme.palette.ixColorWave.main,
              },
              '.MuiPickersDay-root.Mui-selected.Mui-disabled': {
                color: theme.palette.ixColorTalc.main,
              },
            }),
          }}
        />
      </LocalizationProvider>
    );
  }
);

export default DatePicker;
