import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { Theme, styled } from '@mui/material/styles';

import Typography from '../Typography';

export type ButtonProps = Omit<
  MuiButtonProps,
  | 'color'
  | 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'disableFocusRipple'
> & {
  size?: 'large' | 'medium' | 'small';
  variant?: 'contained' | 'text' | 'outlined' | 'destructive';
  invert?: boolean;
  component?: string;
};

type SizeKeys = keyof typeof sizeTextMap;
type TextVariantValues = (typeof sizeTextMap)[SizeKeys];

const generateVariantStyles = (
  variant: ButtonProps['variant'],
  invert: boolean,
  theme: Theme
) => {
  switch (variant) {
    case 'contained':
      return {
        border: '1px solid transparent',
        backgroundColor: invert
          ? theme.palette.ixColorTalc.main
          : theme.palette.ixColorWave.main,
        '> span': {
          color: invert
            ? theme.palette.ixColorWave.main
            : theme.palette.ixColorTalc.main,
        },
        '&:hover': {
          backgroundColor: invert
            ? theme.palette.ixColorGrey20.main
            : theme.palette.ixColorWave70.main,
        },
        '&:active': {
          backgroundColor: invert
            ? theme.palette.ixColorGrey40.main
            : theme.palette.ixColorWaveDarker.main,
        },
        '&:disabled': {
          backgroundColor: invert
            ? theme.palette.ixColorGrey90.main
            : theme.palette.ixColorTalc.main,
          '> span': {
            color: invert
              ? theme.palette.ixColorGrey80.main
              : theme.palette.ixColorGrey40.main,
          },
        },
      };
    case 'outlined':
      return {
        borderColor: invert
          ? theme.palette.ixColorTalc.main
          : theme.palette.ixColorWave.main,
        '> span': {
          color: invert
            ? theme.palette.ixColorTalc.main
            : theme.palette.ixColorWave.main,
        },
        '&:hover': {
          backgroundColor: invert
            ? '#FFFFFF1A'
            : theme.palette.ixColorWave.main,
          borderColor: invert
            ? theme.palette.ixColorTalc.main
            : theme.palette.ixColorWave.main,
          '> span': {
            color: theme.palette.ixColorTalc.main,
          },
        },
        '&:active': {
          backgroundColor: invert
            ? '#FFFFFF4D'
            : theme.palette.ixColorWaveDarker.main,
          borderColor: invert
            ? theme.palette.ixColorTalc.main
            : theme.palette.ixColorWave.main,
          '> span': {
            color: theme.palette.ixColorTalc.main,
          },
        },
        '&:disabled': {
          borderColor: invert
            ? theme.palette.ixColorGrey90.main
            : theme.palette.ixColorWave.main,
          '> span': {
            color: invert
              ? theme.palette.ixColorGrey80.main
              : theme.palette.ixColorGrey40.main,
          },
        },
      };
    case 'text':
      return {
        border: '1px solid transparent',
        '> span': {
          color: invert
            ? theme.palette.ixColorTalc.main
            : theme.palette.ixColorWave.main,
        },
        '&:hover': {
          backgroundColor: invert
            ? '#FFFFFF1A'
            : theme.palette.ixColorGrey5.main,
          '> span': {
            color: invert
              ? theme.palette.ixColorTalc.main
              : theme.palette.ixColorWave.main,
          },
        },
        '&:active': {
          backgroundColor: invert
            ? '#FFFFFF4D'
            : theme.palette.ixColorMist.main,
          '> span': {
            color: invert
              ? theme.palette.ixColorTalc.main
              : theme.palette.ixColorWave.main,
          },
        },
        '&:disabled': {
          '> span': {
            color: invert
              ? theme.palette.ixColorGrey80.main
              : theme.palette.ixColorGrey40.main,
          },
        },
      };
    case 'destructive':
      return {
        backgroundColor: theme.palette.ixColorFlamingo50.main,
        '> span': {
          color: theme.palette.ixColorTalc.main,
        },
        '&:hover': {
          backgroundColor: theme.palette.ixColorFlamingo30.main,
        },
        '&:active': {
          backgroundColor: theme.palette.ixColorFlamingoDarker.main,
        },
        '&:disabled': {
          backgroundColor: theme.palette.ixColorTalc.main,
          '> span': {
            color: theme.palette.ixColorGrey40.main,
          },
        },
      };
    default:
      return {};
  }
};

const StyledButton = styled(MuiButton)<ButtonProps>(
  ({ theme, variant = 'contained', invert = false }) => ({
    ...generateVariantStyles(variant, invert, theme),
    '&.MuiButton-sizeSmall': {
      padding: '4px 4px',
    },
    '&.MuiButton-sizeMedium': {
      padding: '6px 8px',
    },
    '&.MuiButton-sizeLarge': {
      padding: '8px 12px',
    },
    minWidth: '12px',
    height: 'max-content',
  })
);

const sizeTextMap = {
  large: 'button-l',
  medium: 'button-m',
  small: 'button-s',
} as const;

const Button = (props: ButtonProps) => {
  const {
    children,
    size = 'medium',
    variant = 'contained',
    invert = false,
  } = props;

  return (
    <StyledButton {...props} size={size} variant={variant} invert={invert}>
      <Typography variant={sizeTextMap[size] as TextVariantValues}>
        {children}
      </Typography>
    </StyledButton>
  );
};

export default Button;
