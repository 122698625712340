import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leave } from 'utils/calendar-utils';

export type CalendarProjectsFilterState = {
  [key: string]: Array<string>;
};

export type DateSelection = {
  selectionInProgress: boolean;
  dateStart: number;
  dateEnd: number;
  openModal?: boolean;
  openModalEmployee?: boolean;
  dateStartTemp?: number;
  dateEndTemp?: number;
};

export type CalendarState = {
  filterProjects?: CalendarProjectsFilterState;
  filterMonth: number;
  filterYear: number;
  filterPeriodView: 'year' | 'month';
  filterStyleView: 'list' | 'grid';
  dateSelection: DateSelection;
  bookFormAnchorEl?: HTMLButtonElement | null;
  editFormAnchorEl?: HTMLDivElement | null;
  selectedVacation?: Leave;
};

const current_date = new Date();
const initialState: CalendarState = {
  filterProjects: {
    // "1": [],
    // "2": [],
  },
  filterMonth: current_date.getMonth(),
  filterYear: current_date.getFullYear(),
  filterPeriodView: 'month',
  filterStyleView: 'grid',
  dateSelection: {
    selectionInProgress: false,
    dateStart: 0,
    dateEnd: 0,
    openModal: false,
    openModalEmployee: false,
    dateStartTemp: 0,
    dateEndTemp: 0,
  },
  bookFormAnchorEl: null,
  editFormAnchorEl: null,
  selectedVacation: undefined,
};

export const storeCalendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setFilterProjects: (
      state,
      action: PayloadAction<CalendarProjectsFilterState>
    ) => {
      state.filterProjects = action.payload;
    },
    setCurrentDate: state => {
      state.filterMonth = current_date.getMonth();
      state.filterYear = current_date.getFullYear();
    },
    increasePeriod: state => {
      if (state.filterPeriodView === 'year') {
        state.filterYear += 1;
      } else {
        if (state.filterMonth == 11) {
          state.filterMonth = 0;
          state.filterYear += 1;
        } else {
          state.filterMonth += 1;
        }
      }
    },
    decreasePeriod: state => {
      if (state.filterPeriodView === 'year') {
        state.filterYear -= 1;
      } else {
        if (state.filterMonth == 0) {
          state.filterMonth = 11;
          state.filterYear -= 1;
        } else {
          state.filterMonth -= 1;
        }
      }
    },
    setPeriodView: (state, action: PayloadAction<'year' | 'month'>) => {
      state.filterPeriodView = action.payload;
    },
    setStyleView: (state, action: PayloadAction<'list' | 'grid'>) => {
      state.filterStyleView = action.payload;
    },
    setSingleDaySelection: (state, action: PayloadAction<number>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.bookFormAnchorEl = document.getElementById('openBookForm');
      state.dateSelection = {
        selectionInProgress: false,
        dateStart: action.payload,
        dateEnd: action.payload,
        openModal: true,
      };
    },
    closeBookModal: state => {
      state.bookFormAnchorEl = null;
      state.dateSelection = {
        dateStart: 0,
        dateEnd: 0,
        selectionInProgress: false,
        openModal: false,
        openModalEmployee: false,
      };
    },
    openBookModal: state => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.bookFormAnchorEl = document.getElementById('openBookForm');
      state.dateSelection = {
        dateStart: 0,
        dateEnd: 0,
        selectionInProgress: false,
        openModal: true,
      };
    },
    openBookModalForEmployee: state => {
      state.dateSelection = {
        dateStart: 0,
        dateEnd: 0,
        selectionInProgress: false,
        openModalEmployee: true,
      };
    },
    startRangeDaySelection: (state, action: PayloadAction<number>) => {
      state.bookFormAnchorEl = null;
      state.dateSelection = {
        selectionInProgress: true,
        dateStart: action.payload,
        dateEnd: action.payload,
        openModal: false,
        dateStartTemp: action.payload,
        dateEndTemp: action.payload,
      };
    },
    stopRangeDaySelection: (state, action: PayloadAction<number>) => {
      const startd = state.dateSelection.dateStart;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.bookFormAnchorEl = document.getElementById('openBookForm');
      if (action.payload < startd) {
        state.dateSelection = {
          selectionInProgress: false,
          dateStart: action.payload,
          dateEnd: startd,
          openModal: true,
          dateStartTemp: action.payload,
          dateEndTemp: startd,
        };
      } else {
        state.dateSelection = {
          ...state.dateSelection,
          selectionInProgress: false,
          dateEnd: action.payload,
          openModal: true,
          dateEndTemp: action.payload,
        };
      }
    },
    moveRangeDaySelection: (state, action: PayloadAction<number>) => {
      state.bookFormAnchorEl = null;
      state.dateSelection = {
        ...state.dateSelection,
        selectionInProgress: true,
        dateEnd: action.payload,
        openModal: false,
      };
    },
    resetStartRangeDaySelection: (state, action: PayloadAction<number>) => {
      state.bookFormAnchorEl = null;
      state.dateSelection = {
        ...state.dateSelection,
        selectionInProgress: true,
        dateStart: action.payload,
        openModal: false,
      };
    },
    setSelectionInProgress: (state, action: PayloadAction<boolean>) => {
      state.dateSelection = {
        ...state.dateSelection,
        selectionInProgress: action.payload,
      };
    },
    clearRangeSelection: state => {
      state.dateSelection = {
        ...state.dateSelection,
        selectionInProgress: false,
        dateStart: 0,
        dateEnd: 0,
      };
    },
    setStartDateSelection: (state, action: PayloadAction<number>) => {
      state.dateSelection = {
        ...state.dateSelection,
        dateStart: action.payload,
      };
    },
    setEndDateSelection: (state, action: PayloadAction<number>) => {
      state.dateSelection = { ...state.dateSelection, dateEnd: action.payload };
    },
    setStartAndEndDateSelection: (
      state,
      action: PayloadAction<{ startDate: number; endDate: number }>
    ) => {
      state.dateSelection = {
        ...state.dateSelection,
        dateEnd: action.payload.endDate,
        dateStart: action.payload.startDate,
      };
    },
    setEndDateTemp: (state, action: PayloadAction<number>) => {
      state.dateSelection = {
        ...state.dateSelection,
        dateEndTemp: action.payload,
      };
    },
    openEditForm: (
      state,
      action: PayloadAction<{
        vacation?: Leave;
        el: HTMLDivElement | HTMLButtonElement | null;
      }>
    ) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.editFormAnchorEl = action.payload.el;
      state.selectedVacation = action.payload.vacation;
    },
    closeEditForm: state => {
      state.editFormAnchorEl = null;
      state.selectedVacation = undefined;
      state.dateSelection = {
        ...state.dateSelection,
        selectionInProgress: false,
        dateStart: 0,
        dateEnd: 0,
      };
    },
  },
});

export const {
  setCurrentDate,
  increasePeriod,
  decreasePeriod,
  setFilterProjects,
  setPeriodView,
  setStyleView,
  setSingleDaySelection,
  closeBookModal,
  openBookModal,
  openBookModalForEmployee,
  startRangeDaySelection,
  stopRangeDaySelection,
  moveRangeDaySelection,
  resetStartRangeDaySelection,
  setSelectionInProgress,
  clearRangeSelection,
  setEndDateSelection,
  setStartDateSelection,
  setStartAndEndDateSelection,
  setEndDateTemp,
  openEditForm,
  closeEditForm,
} = storeCalendarSlice.actions;

export default storeCalendarSlice.reducer;
