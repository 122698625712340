import { isErrorWithMessage, isFetchBaseQueryError } from 'utils/api';
import {
  openErrorSnackbar,
  openSuccessSnackbar,
} from 'app-redux/storeSnackbarSlice';

import Button from 'components/atoms/Button';
import { HasAccess } from 'utils/roleManagement';
import { Leave } from 'utils/calendar-utils';
import Spinner from 'components/molecules/Spinner';
import Stack from '../../../../../../../components/atoms/Stack';
import { TableCell } from '@mui/material';
import { inThePast } from './utils';
import { openEditForm } from 'app-redux/storeCalendarSlice';
import { useDispatch } from 'react-redux';
import { useResendDocumentToSignMutation } from 'app-redux/apiSlice';

const ActionsCell = ({ vacation }: { vacation: Leave }) => {
  const dispatch = useDispatch();
  const [resendDocumentToSign, resendDocumentToSignResult] =
    useResendDocumentToSignMutation();
  const isEnvelopeNotSigned = vacation.docusignEnvelopeStatus !== 'completed';

  const handleResendDocumentToSign = async () => {
    try {
      if (!vacation) {
        return;
      }

      await resendDocumentToSign(vacation.id);
      dispatch(
        openSuccessSnackbar('Document resent for signing successfully.')
      );
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        const errMsg = (err.data as { error: string }).error;
        dispatch(openErrorSnackbar(errMsg));
      } else if (isErrorWithMessage(err)) {
        dispatch(openErrorSnackbar(err.message));
      }
    }
  };

  return (
    <TableCell>
      <Stack gap="medium" direction="vertical">
        {resendDocumentToSignResult.isLoading && (
          <Spinner fullPage text="Resending Document Vacation..." />
        )}
        <HasAccess roles={['HR', 'ADMIN']}>
          {isEnvelopeNotSigned ? (
            <Button
              data-testid="resendDocumentButton"
              variant="outlined"
              sx={{ marginRight: '12px' }}
              onClick={handleResendDocumentToSign}
            >
              Resend Document
            </Button>
          ) : (
            <div />
          )}
        </HasAccess>
        <Stack gap="small" direction="horizontal">
          <HasAccess
            roles={
              vacation.isMyself ? ['USER', 'HR', 'ADMIN'] : ['HR', 'ADMIN']
            }
          >
            {vacation.specialLeaveType == 'Medical / sick leave' ||
            vacation.specialLeaveType == 'Parental leave' ? (
              <div />
            ) : (
              <Button
                data-testid="pdfButton"
                variant="outlined"
                sx={{ marginRight: '12px' }}
                href={`/api/v1/vacations/generatePDF?vacationId=${vacation.id}`}
              >
                View PDF
              </Button>
            )}
          </HasAccess>
          <HasAccess
            roles={
              vacation.isMyself && !inThePast(vacation)
                ? ['USER', 'HR', 'ADMIN']
                : ['HR', 'ADMIN']
            }
          >
            <Button
              onClick={event => {
                dispatch(openEditForm({ vacation, el: event.currentTarget }));
              }}
              variant="outlined"
            >
              Modify
            </Button>
          </HasAccess>
        </Stack>
      </Stack>
    </TableCell>
  );
};

export default ActionsCell;
