import { ComponentProps } from 'react';
import { styled } from '@mui/material/styles';

const StyledAvatar = styled('img')(({ theme }) => ({
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  border: `1px solid ${theme.palette.ixColorFlamingo.main}`,
}));

const Avatar = (props: ComponentProps<'img'>) => {
  return <StyledAvatar {...props} />;
};

export default Avatar;
