import {
  ProgressBarInfoRequest,
  selectUserEmployeeNumber,
  useGetProgressBarStatusQuery,
} from 'app-redux/apiSlice';

import ProgressBar from 'components/molecules/ProgressBar';
import { RootState } from 'app-redux/store';
import { useSelector } from 'react-redux';

const ProgressBarWrapper = () => {
  const employeeId = useSelector(selectUserEmployeeNumber);
  const year = useSelector(
    (state: RootState) => state.calendarFilter.selectedYear
  );

  const progressBarInfoRequest: ProgressBarInfoRequest = {
    employeeId,
    year,
  };

  const { data: progressBarInfo, isSuccess } = useGetProgressBarStatusQuery(
    progressBarInfoRequest
  );

  if (!isSuccess) {
    return null;
  }

  return (
    <ProgressBar
      availableDays={progressBarInfo.availableDays}
      takenDays={progressBarInfo.takenDays}
      plannedDays={progressBarInfo.plannedDays}
      totalDays={progressBarInfo.totalDays}
      carryOverDays={progressBarInfo.carryOverDays}
      daysTakenInAdvance={progressBarInfo.daysTakenInAdvance}
    />
  );
};

export default ProgressBarWrapper;
