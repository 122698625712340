import { Box, FormControlLabel } from '@mui/material';
import {
  Client,
  Colleague,
  selectUserEmployeeData,
  useGetAllClientsQuery,
  useGetClientsByEmployeeNumberQuery,
  useGetColleaguesByAssignmentIdsQuery,
} from 'app-redux/apiSlice';
import { HAS_ACCESS, hasAccess } from 'utils/roleManagement';
import {
  setSelectedClients,
  setSelectedColleagues,
} from 'app-redux/storeCalendarFilterSlice';
import {
  sortClientListAlphabetically,
  sortColleaguesListAlphabetically,
} from 'utils/SortArrayAlphatbetically';
import { useDispatch, useSelector } from 'react-redux';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo, useRef, useState } from 'react';

import Checkbox from 'components/atoms/Checkbox';
import Resizeable from 'components/atoms/Resizeable';
import { RootState } from 'app-redux/store';
import SearchableTitle from './components/SearchableTitle';
import Spinner from 'components/molecules/Spinner';
import Typography from 'components/atoms/Typography';
import { groupBy } from 'utils/groupBy';

export const TW_CLIENT_NAME = 'Thoughtworks Romania';
export const OS_PROJECT_NAME = 'Operational Service';
export const BEACH_PROJECT_NAME = 'Beach';

const moveTopMyself = (colleagues: Colleague[], myEmployeeId?: string) => {
  const myself = colleagues.find(coll => coll.employeeId === myEmployeeId);
  const withoutMyself = colleagues
    .filter(coll => coll.employeeId !== myEmployeeId)
    .sort(sortColleaguesListAlphabetically);
  if (myself) {
    return [myself, ...withoutMyself];
  }

  return [...colleagues];
};

const EmployeeFilter = () => {
  const firstTimeRef = useRef(true);
  const isUser = hasAccess(HAS_ACCESS.USER);
  const [colleagueQuery, setColleagueQuery] = useState('');
  const [clientQuery, setClientQuery] = useState('');
  const [isClientListCollapsed, setIsClientListCollapsed] = useState(false);
  const [isColleagueListCollapsed, setIsColleagueListCollapsed] =
    useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector(selectUserEmployeeData);
  const { selectedClients, selectedColleagues } = useSelector(
    (state: RootState) => state.calendarFilter
  );

  const { data: myClients = [], isSuccess: isSuccessMyClients } =
    useGetClientsByEmployeeNumberQuery(currentUser?.employeeNumber || '', {
      skip: isUser,
    });

  const { data: allClients = [], isLoading: isLoadingAllClients } =
    useGetAllClientsQuery();

  const { data: employeeClients = [], isLoading: isLoadingEmployeeClients } =
    useGetClientsByEmployeeNumberQuery(currentUser?.employeeNumber || '', {
      skip: !currentUser?.employeeNumber,
    });

  const employeeClientProjectIds = useMemo(
    () => employeeClients.map(employeeClient => employeeClient.projectId),
    [employeeClients]
  );

  const employeeCompanyAccount = useMemo(
    () => employeeClients.map(client => client.companyAccount),
    [employeeClients]
  );

  const filteredClients: Client[] = useMemo(
    () =>
      allClients.filter(client =>
        employeeCompanyAccount.includes(client.companyAccount)
      ),
    [allClients, employeeCompanyAccount]
  );

  const { clients, isLoadingClients } = isUser
    ? { clients: filteredClients, isLoadingClients: isLoadingEmployeeClients }
    : { clients: allClients, isLoadingClients: isLoadingAllClients };

  const { data: colleagues = [] } = useGetColleaguesByAssignmentIdsQuery(
    selectedClients.map(client => client.projectId).join(','),
    {
      skip: selectedClients.length === 0,
    }
  );

  const handleCheckedClients = (client: Client) => {
    const prev = [...selectedClients];
    const itemIndex = prev.findIndex(c => c.projectId === client.projectId);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(client);
    }
    dispatch(setSelectedClients([...prev]));
  };

  const handleCheckedAllClients = () => {
    const prev = [...filteredClientList];
    const selectedInternalClients = selectedClients.filter(
      client => client.companyAccount === TW_CLIENT_NAME
    );

    if (
      selectedClients.length ===
      prev.length + selectedInternalClients.length
    ) {
      dispatch(setSelectedClients([...selectedInternalClients]));
    } else {
      dispatch(setSelectedClients([...prev, ...selectedInternalClients]));
    }
  };

  const handleCheckedAllColleagues = () => {
    const prev = [...filteredColleagueList];
    if (selectedColleagues.length === prev.length) {
      dispatch(setSelectedColleagues([]));
    } else {
      dispatch(setSelectedColleagues([...prev]));
    }
  };

  const handleCheckedColleague = (colleague: Colleague) => {
    const prev = [...selectedColleagues];
    const itemIndex = prev.findIndex(
      c => c.employeeId === colleague.employeeId
    );
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(colleague);
    }
    dispatch(setSelectedColleagues([...prev]));
  };

  const internalClients: Client[] = useMemo(() => {
    if (clients.length > 0) {
      return clients
        .filter(client => client.companyAccount === TW_CLIENT_NAME)
        .sort(sortClientListAlphabetically);
    }

    return [];
  }, [clients]);

  const externalClients: Client[] = useMemo(() => {
    if (clients.length > 0) {
      return clients
        .filter(client => client.companyAccount !== TW_CLIENT_NAME)
        .sort(sortClientListAlphabetically);
    }

    return [];
  }, [clients]);

  const filteredColleagueList = moveTopMyself(
    colleagues,
    currentUser?.employeeNumber
  ).filter(coll =>
    coll.fullName.toLowerCase().includes(colleagueQuery.trim().toLowerCase())
  );

  const filteredClientList = externalClients.filter(
    client =>
      client.companyAccount
        .toLowerCase()
        .includes(clientQuery.trim().toLowerCase()) ||
      client.projectName
        .toLowerCase()
        .includes(clientQuery.trim().toLowerCase())
  );

  const groupedClientList = groupBy(
    filteredClientList,
    client => client.companyAccount
  );

  const selectedExternalClients = selectedClients.filter(
    client => client.companyAccount !== TW_CLIENT_NAME
  );

  // Set default internal client
  useEffect(() => {
    if (internalClients.length === 0) {
      return;
    }

    const beachClient = internalClients.find(
      client => client.projectName === BEACH_PROJECT_NAME
    )!;
    const osClient = internalClients.find(
      client => client.projectName === OS_PROJECT_NAME
    )!;

    if (isUser) {
      dispatch(
        setSelectedClients(
          [{ ...beachClient }, { ...osClient }].filter(
            client => client.projectId
          )
        )
      );
    } else {
      if (isSuccessMyClients) {
        const selectedAdminProjects = myClients.filter(
          (client: { projectId: string }) =>
            client.projectId === beachClient.projectId ||
            client.projectId === osClient.projectId
        );
        dispatch(setSelectedClients([...selectedAdminProjects]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalClients, isSuccessMyClients]);

  // Set default external client
  useEffect(() => {
    if (externalClients.length === 0) {
      return;
    }

    if (isUser) {
      const firstExternalClient =
        externalClients.find(externalClient =>
          employeeClientProjectIds.includes(externalClient.projectId)
        ) || externalClients[0];

      dispatch(setSelectedClients([{ ...firstExternalClient }]));
    } else {
      if (isSuccessMyClients) {
        dispatch(setSelectedClients([{ ...myClients[0] }]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalClients, isSuccessMyClients]);

  // set default colleagues as myself for one time
  useEffect(() => {
    // TODO: refactor here for running once
    if (
      colleagues.length > 0 &&
      currentUser &&
      selectedClients.length > 0 &&
      firstTimeRef.current
    ) {
      const myself = colleagues.find(
        coll => coll.employeeId === currentUser.employeeNumber
      );
      if (myself) {
        dispatch(setSelectedColleagues([{ ...myself }]));
        firstTimeRef.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colleagues]);

  // set selected colleagues when clients changes
  useEffect(() => {
    if (selectedClients.length === 0) {
      dispatch(setSelectedColleagues([]));
    } else {
      const remainedColleagues: Colleague[] = [];
      selectedClients.forEach(client => {
        const filteredColleagues = selectedColleagues.filter(coll =>
          coll.assignmentIdList?.includes(Number(client.projectId))
        );
        remainedColleagues.push(...filteredColleagues);
      });
      const arrayUniqueByKey = [
        ...new Map(
          remainedColleagues.map(item => [item.employeeId, item])
        ).values(),
      ];

      dispatch(setSelectedColleagues(arrayUniqueByKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClients]);

  if (isLoadingClients) {
    return <Spinner fullPage text="Loading..." />;
  }

  // TODO: refactor - add separate onClick functions and create components for renders

  return (
    <Box>
      {internalClients.length > 0 && (
        <Box sx={{ marginBottom: '24px' }}>
          <Typography
            variant="subtitle-1"
            color="ixColorWave"
            sx={{ marginBottom: '20px' }}
          >
            {TW_CLIENT_NAME}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {internalClients.map(client => (
              <FormControlLabel
                sx={{ marginLeft: '-6px', marginBottom: '4px' }}
                key={client.projectId}
                label={
                  <Typography
                    variant="body-2"
                    color="ixColorGrey70"
                    sx={{ marginLeft: '6px' }}
                  >
                    {client.projectName.toUpperCase()}
                  </Typography>
                }
                control={
                  <Checkbox
                    disabled={internalClients.length === 1}
                    value={client.projectId}
                    checked={
                      !!selectedClients.find(
                        c => c.projectId === client.projectId
                      )
                    }
                    onChange={() => handleCheckedClients(client)}
                  />
                }
              />
            ))}
          </Box>
        </Box>
      )}
      {externalClients.length > 0 && (
        <Box>
          <Box>
            <SearchableTitle
              title="Clients"
              onSearch={setClientQuery}
              onCollapse={setIsClientListCollapsed}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '24px',
            }}
          >
            <Resizeable collapsed={isClientListCollapsed}>
              {filteredClientList.length === 0 && (
                <Typography variant="body-2" color="ixColorGrey60">
                  No result found.
                </Typography>
              )}
              {filteredClientList.length > 0 && (
                <FormControlLabel
                  sx={{ marginLeft: '-6px', marginBottom: '4px' }}
                  label={
                    <Typography
                      variant="body-2"
                      color="ixColorGrey70"
                      sx={{ marginLeft: '6px' }}
                    >
                      SELECT ALL
                    </Typography>
                  }
                  control={
                    <Checkbox
                      value="all"
                      checked={
                        selectedExternalClients.length ===
                        filteredClientList.length
                      }
                      indeterminate={
                        selectedExternalClients.length > 0 &&
                        selectedExternalClients.length !==
                          filteredClientList.length
                      }
                      onChange={() => handleCheckedAllClients()}
                    />
                  }
                />
              )}
              {Object.keys(groupedClientList).map(key => (
                <Box
                  key={key}
                  sx={{
                    ':not(:last-child)': {
                      marginBottom: '16px',
                    },
                  }}
                >
                  <Typography sx={{ marginBottom: '8px' }}>{key}</Typography>
                  {groupedClientList[key].map(client => (
                    <FormControlLabel
                      sx={{ marginLeft: '-6px', marginBottom: '4px' }}
                      key={client.projectId}
                      label={
                        <Typography
                          variant="body-2"
                          color="ixColorGrey70"
                          sx={{ marginLeft: '6px' }}
                        >
                          {client.projectName.toUpperCase()}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          disabled={internalClients.length === 1}
                          value={client.projectId}
                          checked={
                            !!selectedClients.find(
                              c => c.projectId === client.projectId
                            )
                          }
                          onChange={() => handleCheckedClients(client)}
                        />
                      }
                    />
                  ))}
                </Box>
              ))}
            </Resizeable>
          </Box>
        </Box>
      )}
      <Box>
        <Box>
          <SearchableTitle
            title="Colleagues"
            onSearch={setColleagueQuery}
            onCollapse={setIsColleagueListCollapsed}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Resizeable
            collapsed={isColleagueListCollapsed}
            height={isUser ? 500 : 250}
          >
            <Typography
              sx={{
                marginBottom: '4px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              title={selectedClients
                .map(client => client.projectName)
                .join(', ')}
            >
              {selectedClients.map(client => client.projectName).join(', ')}
            </Typography>
            {filteredColleagueList.length === 0 && (
              <Typography variant="body-2" color="ixColorGrey60">
                No result found.
              </Typography>
            )}
            {filteredColleagueList.length > 0 && (
              <FormControlLabel
                sx={{ marginLeft: '-6px', marginBottom: '4px' }}
                label={
                  <Typography
                    variant="body-2"
                    color="ixColorGrey70"
                    sx={{ marginLeft: '6px' }}
                  >
                    SELECT ALL
                  </Typography>
                }
                control={
                  <Checkbox
                    value="all"
                    checked={
                      selectedColleagues.length === filteredColleagueList.length
                    }
                    indeterminate={
                      selectedColleagues.length > 0 &&
                      selectedColleagues.length !== filteredColleagueList.length
                    }
                    onChange={() => handleCheckedAllColleagues()}
                  />
                }
              />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {filteredColleagueList.map(colleague => (
                <FormControlLabel
                  sx={{ marginLeft: '-6px', marginBottom: '4px' }}
                  key={colleague.employeeId}
                  label={
                    <Typography
                      variant="body-2"
                      color="ixColorGrey70"
                      sx={{ marginLeft: '6px' }}
                    >
                      {colleague.employeeId === currentUser?.employeeNumber
                        ? `${colleague.fullName} (myself)`
                        : colleague.fullName}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      value={colleague.employeeId}
                      checked={
                        !!selectedColleagues.find(
                          c => c.employeeId === colleague.employeeId
                        )
                      }
                      onChange={() => handleCheckedColleague(colleague)}
                    />
                  }
                />
              ))}
            </Box>
          </Resizeable>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeFilter;
