import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AlertProps } from 'components/molecules/Alert';

export type SnackbarState = {
  open: boolean;
  message: string;
  severity: AlertProps['variant'];
};

export const initialState: SnackbarState = {
  open: false,
  message: '',
  severity: 'information',
};

export const storeSnackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSuccessSnackbar: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.message = action.payload;
      state.severity = 'confirmation';
    },
    openErrorSnackbar: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.message = action.payload;
      state.severity = 'error';
    },
    clearSnackbar: state => {
      state.open = false;
    },
  },
});

export const { openSuccessSnackbar, openErrorSnackbar, clearSnackbar } =
  storeSnackbarSlice.actions;

export default storeSnackbarSlice.reducer;
