import InformationIcon from 'components/atoms/Icons/InformationIcon';
import { Popover } from '@mui/material';
import Separator from 'components/atoms/Separator';
import Typography from 'components/atoms/Typography';
import { useState } from 'react';

type ProgressBarInfoBubbleProps = {
  carryOverDays?: number;
  daysTakenInAdvance?: { [key: number]: number };
};

const ProgressBarInfoBubble = (props: ProgressBarInfoBubbleProps) => {
  const { carryOverDays, daysTakenInAdvance } = props;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const closePopover = () => {
    setAnchorEl(null);
  };

  const carryOverDaysText = () => {
    if (!carryOverDays) {
      return null;
    }

    return (
      <>
        <Typography variant="overline">
          {carryOverDays} available days are from last year.
        </Typography>
        <Separator />
      </>
    );
  };

  const daysTakenInAdvanceText = () => {
    if (!daysTakenInAdvance) {
      return null;
    }

    const keys: Array<number> = Object.keys(daysTakenInAdvance).map(key =>
      Number(key)
    );

    return keys.map((key: number) => {
      const dayTakenInAdvance = daysTakenInAdvance[key];

      return (
        <Typography key={key} variant="overline">
          You have taken {dayTakenInAdvance} days from {key}
        </Typography>
      );
    });
  };

  return (
    <>
      <InformationIcon
        sx={{ cursor: 'pointer' }}
        onClick={e => setAnchorEl(e.currentTarget)}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            borderRadius: '4px',
            padding: '12px',
            minWidth: '220px',
          },
        }}
      >
        {carryOverDaysText()}
        {daysTakenInAdvanceText()}
      </Popover>
    </>
  );
};

export default ProgressBarInfoBubble;
