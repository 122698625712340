import ActionsCell from './ActionsCell';
import DocumentStatusCell from './DocumentStatusCell';
import { Leave } from 'utils/calendar-utils';
import LeaveTypeCell from './LeaveTypeCell';
import { TableRow } from '@mui/material';
import UserCell from './UserCell';
import VacationPeriodCell from './VacationPeriodCell';

const VacationRow = ({ vacation }: { vacation: Leave }) => {
  return (
    <TableRow hover sx={{ borderBottom: '2px solid #E3E3E3' }}>
      <UserCell vacation={vacation} />
      <VacationPeriodCell vacation={vacation} />
      <LeaveTypeCell vacation={vacation} />
      <DocumentStatusCell vacation={vacation} />
      <ActionsCell vacation={vacation} />
    </TableRow>
  );
};

export default VacationRow;
