import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { format } from 'date-fns';

import Form, {
  FormProvider,
  useForm,
  yupResolver,
} from 'components/atoms/Form';
import Typography from 'components/atoms/Typography';
import FormInput from 'components/atoms/Form/components/FormInput';
import FormSubmit from 'components/atoms/Form/components/FormSubmit';
import Spinner from 'components/molecules/Spinner';
import {
  UserDTO,
  useUpdateUserProfileByHrOrAdminMutation,
  useUpdateUserProfileMutation,
} from 'app-redux/apiSlice';
import {
  openErrorSnackbar,
  openSuccessSnackbar,
} from 'app-redux/storeSnackbarSlice';
import { isErrorWithMessage, isFetchBaseQueryError } from 'utils/api';
import { HAS_ACCESS, hasAccess } from 'utils/roleManagement';

type IPersonalDetailForm = {
  firstName?: string;
  lastName?: string;
  email?: string;
  secondaryEmail?: string;
  employeeId?: string;
  payrollId?: string;
  hireDate?: string;
  careerStartDate?: string;
  vacationDays?: string;
};

type PersonalDetailsFormProps = {
  defaultValues?: UserDTO;
};

const PersonalDetailsForm = (props: PersonalDetailsFormProps) => {
  const { defaultValues } = props;
  const dispatch = useDispatch();
  const isUser = hasAccess(HAS_ACCESS.USER);

  const [updateUser, resultUpdateUser] = useUpdateUserProfileMutation();
  const [updateUserByHrOrAdmin, resultUpdateUserByHrOrAdmin] =
    useUpdateUserProfileByHrOrAdminMutation();

  const formSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email('Field must be a valid email').required(),
    secondaryEmail: Yup.string()
      .email('Field must be a valid email')
      .nullable(),
    employeeId: Yup.string().required(),
    payrollId: Yup.string().nullable(),
    hireDate: Yup.string().required(),
    careerStartDate: Yup.string().nullable(),
    vacationDays: Yup.string().required(),
  });

  const methods = useForm<IPersonalDetailForm>({
    resolver: yupResolver(formSchema),
    shouldUnregister: true,
    defaultValues: {
      careerStartDate: defaultValues?.careerStartDate
        ? format(new Date(defaultValues?.careerStartDate), 'dd.MM.yyyy')
        : undefined,
      email: defaultValues?.email,
      employeeId: defaultValues?.employeeId,
      firstName: defaultValues?.firstName,
      hireDate: defaultValues?.hireDate
        ? format(new Date(defaultValues?.hireDate), 'dd.MM.yyyy')
        : undefined,
      lastName: defaultValues?.lastName,
      payrollId: defaultValues?.payrollId,
      secondaryEmail: defaultValues?.secondaryEmail,
      vacationDays: defaultValues?.vacationDays?.toString(),
    },
  });

  const disableSubmit = !methods.formState.isDirty;

  const onSubmitHandler = methods.handleSubmit(async values => {
    try {
      if (defaultValues) {
        if (isUser) {
          await updateUser({
            ...defaultValues,
            secondaryEmail: values.secondaryEmail || '',
          }).unwrap();
        } else {
          await updateUserByHrOrAdmin({
            ...defaultValues,
            secondaryEmail: values.secondaryEmail || '',
            payrollId: values.payrollId || '',
            vacationDays: Number.parseInt(values.vacationDays || '0'),
          }).unwrap();
        }
        dispatch(openSuccessSnackbar('User information updated successfully.'));
        methods.reset({}, { keepValues: true });
      }
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        const errMsg = (err.data as { error: string }).error;
        dispatch(openErrorSnackbar(errMsg));
      } else if (isErrorWithMessage(err)) {
        dispatch(openErrorSnackbar(err.message));
      }
    }
  });

  return (
    <FormProvider {...methods}>
      {(resultUpdateUser.isLoading ||
        resultUpdateUserByHrOrAdmin.isLoading) && (
        <Spinner fullPage text="User information is updating..." />
      )}
      <Typography variant="h5" sx={{ marginBottom: '24px' }}>
        Personal Details
      </Typography>
      <Box sx={{ width: '433px' }}>
        <Form onSubmit={onSubmitHandler} noValidate autoComplete="off">
          <FormInput
            name="firstName"
            label="First Name"
            InputProps={{ readOnly: true }}
          />
          <FormInput
            name="lastName"
            label="Last Name"
            InputProps={{ readOnly: true }}
          />
          <FormInput
            name="email"
            label="Primary email"
            InputProps={{ readOnly: true }}
          />
          <FormInput name="secondaryEmail" label="Secondary email" />
          <FormInput
            name="employeeId"
            label="Employee ID"
            InputProps={{ readOnly: true }}
          />
          <FormInput
            name="payrollId"
            label="Payroll ID"
            InputProps={{ readOnly: isUser }}
          />
          <FormInput
            name="hireDate"
            label="Start Date"
            InputProps={{ readOnly: true }}
          />
          <FormInput
            name="careerStartDate"
            label="Career Start Date"
            InputProps={{ readOnly: true }}
          />
          <FormInput
            type="number"
            name="vacationDays"
            label="Vacation Days"
            InputProps={{ readOnly: isUser }}
          />
          <FormSubmit disabled={disableSubmit} variant="contained">
            Update
          </FormSubmit>
        </Form>
      </Box>
    </FormProvider>
  );
};

export default PersonalDetailsForm;
