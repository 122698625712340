import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';

const Checkbox = (props: CheckboxProps) => (
  <MuiCheckbox
    {...props}
    sx={theme => ({
      opacity: props.disabled ? '0.5' : 1,
      padding: '3px',
      color: theme.palette.ixColorGrey40.main,
      '&:hover': {
        backgroundColor: theme.palette.ixColorGrey10.main,
      },
      '&.MuiCheckbox-root.Mui-checked': {
        color: theme.palette.ixColorWave.main,
      },
      '&.MuiCheckbox-indeterminate': {
        color: theme.palette.ixColorWave.main,
      },
    })}
  />
);

export default Checkbox;
