import { createSvgIcon } from '@mui/material';

const UserIcon = createSvgIcon(
  <>
    <path id="Path_265" data-name="Path 265" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_266"
      data-name="Path 266"
      d="M4,22a8,8,0,1,1,16,0H18A6,6,0,1,0,6,22Zm8-9a6,6,0,1,1,6-6A6,6,0,0,1,12,13Zm0-2A4,4,0,1,0,8,7,4,4,0,0,0,12,11Z"
      fill="currentColor"
    />
  </>,
  'User'
);

export default UserIcon;
