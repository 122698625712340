import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DisclosureState = {
  [key: string]: boolean;
};

export const initialState: DisclosureState = {};

export const storeDisclosureSlice = createSlice({
  name: 'disclosure',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<string>) => {
      state[action.payload] = true;
    },
    close: (state, action: PayloadAction<string>) => {
      state[action.payload] = false;
    },
    toggle: (state, action: PayloadAction<string>) => {
      state[action.payload] = !state[action.payload];
    },
  },
});

export const { open, close, toggle } = storeDisclosureSlice.actions;

export default storeDisclosureSlice.reducer;
