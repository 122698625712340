import { ForwardedRef, forwardRef, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

type InternalInputProps = {
  shouldShrink?: boolean;
  tagValue?: string | string[];
} & TextFieldProps;

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '.MuiFormHelperText-root.Mui-error': {
    fontSize: '12px',
  },
  '.MuiInputBase-root': {
    minHeight: '48px',
    maxHeight: '48px',

    '&.MuiAutocomplete-inputRoot': {
      padding: '9px 12px',
      maxHeight: 'unset',

      '&.Mui-focused': {
        input: {
          height: '18px !important',
        },
      },

      '.MuiAutocomplete-tag ~ input': {
        height: '0',
      },

      input: {
        height: '18px',
        padding: '0',
        minWidth: '240px',
        paddingLeft: '16px',
      },
    },
  },
  '.MuiInputBase-root svg': {
    transition: 'color 0.3s ease',
    color: theme.palette.ixColorGrey70.main,

    '&.MuiChip-deleteIconMedium': {
      color: theme.palette.ixColorGrey40.main,
    },
  },
  '.MuiInputBase-root.Mui-focused svg': {
    color: theme.palette.ixColorGrey90.main,

    '&.MuiChip-deleteIconMedium': {
      color: theme.palette.ixColorGrey60.main,
    },
  },
  '.MuiInputBase-root.Mui-disabled svg': {
    color: theme.palette.ixColorGrey40.main,
  },
  '.MuiInputBase-root.Mui-focused fieldset': {
    borderColor: `${theme.palette.ixColorSapphire.main} !important`,
    borderWidth: '1px !important',
  },
  '.MuiInputBase-root.Mui-error fieldset': {
    borderColor: `${theme.palette.ixColorError.main} !important`,
    borderWidth: '1px !important',
  },
  '.MuiInputBase-root.Mui-disabled fieldset': {
    borderColor: `${theme.palette.ixColorGrey10.main} !important`,
    borderWidth: '1px !important',
  },
  fieldset: {
    transition: 'border-color 0.3s ease',
    borderColor: theme.palette.ixColorGrey30.main,
  },
  label: {
    top: '-3px',
    color: theme.palette.ixColorGrey70.main + '!important',

    '&.MuiInputLabel-shrink': {
      color: theme.palette.ixColorGrey90.main + '!important',
    },
    '&.MuiInputLabel-shrink.Mui-error': {
      color: theme.palette.ixColorError.main + '!important',
    },
    '&.Mui-disabled': {
      color: theme.palette.ixColorGrey40.main + '!important',
    },
  },
  input: {
    fontSize: '16px',
    padding: '12px 16px',
    color: theme.palette.ixColorGrey70.main,

    '&:focus': {
      color: theme.palette.ixColorGrey90.main,
    },

    '&::placeholder': {
      color: theme.palette.ixColorGrey50.main,
      opacity: 1,
    },
  },
}));

const Input = forwardRef(
  (props: InternalInputProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { shouldShrink = false } = props;
    const [focused, setFocused] = useState(false);

    const calculateLabelLeftValue = () => {
      if (
        focused ||
        props.value ||
        (props.tagValue && props.tagValue?.length > 0)
      ) {
        return '0';
      }

      if (props.InputProps?.startAdornment || props.inputProps?.prefix) {
        return '48px';
      }

      return '0';
    };

    return (
      <StyledTextField
        {...props}
        ref={ref}
        FormHelperTextProps={{
          title: props.helperText?.toString(),
          sx: { cursor: 'default' },
        }}
        inputProps={{
          ...props.inputProps,
          onFocus: e => {
            setFocused(true);
            props.inputProps?.onFocus?.(e);
          },
          onBlur: e => {
            setFocused(false);
            props.inputProps?.onBlur?.(e);
          },
        }}
        InputLabelProps={{
          ...props.InputLabelProps,
          sx: {
            transition:
              'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            left: calculateLabelLeftValue(),
          },
          shrink:
            focused ||
            !!props.value ||
            !!props.inputProps?.value ||
            shouldShrink,
        }}
      />
    );
  }
);

Input.displayName = 'Input';

export default Input;
