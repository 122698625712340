import Box, { BoxProps } from '@mui/material/Box';
import { styled, Theme } from '@mui/material/styles';
import { ElementType } from 'react';

interface StyledBoxProps extends Omit<BoxProps, 'color' | 'as' | 'component'> {
  color?: keyof Theme['palette'] | 'inherit';
  variant?:
    | 'button-s'
    | 'button-m'
    | 'button-l'
    | 'body-1'
    | 'body-2'
    | 'subtitle-1'
    | 'subtitle-2'
    | 'caption'
    | 'overline'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6';
}

const componentMap = {
  span: ['button-s', 'button-m', 'button-l', 'overline', 'caption'],
  p: ['subtitle-1', 'subtitle-2', 'body-1', 'body-2'],
};

const generateVariantStyles = (
  variant: StyledBoxProps['variant'],
  theme: Theme
) => {
  switch (variant) {
    case 'body-1':
      return {
        fontWeight: 400,
        fontSize: '1rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.5',
        color: theme.palette.ixColorGrey90.main,
      };
    case 'body-2':
      return {
        fontWeight: 400,
        fontSize: '0.875rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.5',
        color: theme.palette.ixColorOnyx.main,
      };
    case 'subtitle-1':
      return {
        fontWeight: 600,
        fontSize: '1rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.5',
        color: theme.palette.ixColorGrey90.main,
      };
    case 'subtitle-2':
      return {
        fontWeight: 600,
        fontSize: '0.875rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.5',
        color: theme.palette.ixColorOnyx.main,
      };
    case 'button-l':
      return {
        fontWeight: 600,
        fontSize: '1.125rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.5',
      };
    case 'button-m':
      return {
        fontWeight: 600,
        fontSize: '0.875rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.5',
      };
    case 'button-s':
      return {
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.5',
      };
    case 'caption':
    case 'overline':
      return {
        fontWeight: 400,
        fontSize: '0.75rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.5',
        color: theme.palette.ixColorOnyx.main,
      };
    case 'h1':
      return {
        fontWeight: 600,
        fontSize: '2rem',
        fontFamily: 'Bitter, Arial, Helvetica, sans-serif',
        lineHeight: '1.125',
        color: theme.palette.ixColorOnyx.main,
      };
    case 'h2':
      return {
        fontWeight: 600,
        fontSize: '1.825rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1',
        color: theme.palette.ixColorOnyx.main,
      };
    case 'h3':
      return {
        fontWeight: 600,
        fontSize: '1.65rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.136',
        color: theme.palette.ixColorOnyx.main,
      };
    case 'h4':
      return {
        fontWeight: 600,
        fontSize: '1.475rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.186',
        color: theme.palette.ixColorOnyx.main,
      };
    case 'h5':
      return {
        fontWeight: 600,
        fontSize: '1.3rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.15',
        color: theme.palette.ixColorOnyx.main,
      };
    case 'h6':
      return {
        fontWeight: 600,
        fontSize: '1.125rem',
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        lineHeight: '1.11',
        color: theme.palette.ixColorOnyx.main,
      };
    default:
      return {};
  }
};

const StyledText = styled(Box)<StyledBoxProps>(
  ({ color = 'ixColorOnyx', theme, variant = 'body-1' }) => ({
    ...generateVariantStyles(variant, theme),
    ...(color === 'inherit'
      ? { color: 'inherit' }
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        { color: theme.palette[color]?.main || color }),
    margin: 0,
  })
);

const Typography = (props: StyledBoxProps) => {
  const { children, variant = 'body-1' } = props;

  const tag = (Object.keys(componentMap).find(
    key => componentMap[key as keyof typeof componentMap].includes(variant)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) || variant) as ElementType<any>;

  return (
    <StyledText {...props} component={tag} variant={variant}>
      {children}
    </StyledText>
  );
};

export default Typography;
