import { createSvgIcon } from '@mui/material';

const ArrowDownIcon = createSvgIcon(
  <>
    <path id="Path_215" data-name="Path 215" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_216"
      data-name="Path 216"
      d="M12,13.172l4.95-4.95,1.414,1.414L12,16,5.636,9.636,7.05,8.222Z"
      fill="currentColor"
    />
  </>,
  'ArrowDown'
);

export default ArrowDownIcon;
