/* eslint-disable @typescript-eslint/no-explicit-any */
export const access = (obj: any, accessor: string) => {
  const keys = accessor.split('.'); // [fullName]
  let value = obj;

  for (let i = 0; i < keys.length; i++) {
    value = value[keys[i]];
    if (value === undefined) {
      return undefined;
    }
  }

  return value;
};
