import { useEffect, useState } from 'react';

type Options = {
  onOpen?: () => void;
  onClose?: () => void;
};

const useDisclosure = (initialState?: boolean, options?: Options) => {
  const [isOpen, setIsOpen] = useState(initialState || false);

  useEffect(() => {
    if (isOpen !== initialState) {
      setIsOpen(initialState || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  // TODO: refactor
  const open = () => {
    setIsOpen(true);
    if (typeof options?.onOpen === 'function') {
      options.onOpen();
    }
  };
  // TODO: refactor
  const close = () => {
    setIsOpen(false);
    if (typeof options?.onClose === 'function') {
      options?.onClose();
    }
  };

  const toggle = () => (isOpen ? close() : open());

  return { isOpen, open, close, toggle };
};

export default useDisclosure;
