import { Box, TableCell } from '@mui/material';
import { setBackgroundColor, setBackgroundColorUsers } from './utils';

import { Leave } from 'utils/calendar-utils';
import { selectUserRoleList } from 'app-redux/apiSlice';
import { useSelector } from 'react-redux';

const UserCell = ({ vacation }: { vacation: Leave }) => {
  const roleList = useSelector(selectUserRoleList);

  return (
    <TableCell>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Box
          borderRadius="50%"
          width="16px"
          height="16px"
          sx={{
            marginRight: '24px',
            backgroundColor: roleList.includes('USER')
              ? setBackgroundColorUsers(vacation)
              : setBackgroundColor(vacation),
          }}
        ></Box>
        <div>{vacation.fullname + (vacation.isMyself ? ' (myself)' : ' ')}</div>
      </div>
    </TableCell>
  );
};

export default UserCell;
