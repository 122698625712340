import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { headerCells } from './constants';

const ListHeader = () => {
  function displayHeaderCells() {
    return headerCells.map(headerCell => {
      return (
        <TableCell
          key={headerCell.key}
          sx={{
            width: `${headerCell.width}`,
            fontSize: '12px',
            padding: '12px',
            lineHeight: 1,
            zIndex: 1,
            backgroundColor: '#F5F5F5',
          }}
        >
          {headerCell.title}
        </TableCell>
      );
    });
  }

  return (
    <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
      <TableRow>{displayHeaderCells()}</TableRow>
    </TableHead>
  );
};

export default ListHeader;
