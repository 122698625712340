import Slider, { SliderProps } from '@mui/material/Slider';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { minuteToTime, timeToMinute } from '../../../utils/calendar-utils';

import { ReactNode } from 'react';
import Typography from '../../atoms/Typography';

export type HourSliderValueType = {
  start: string;
  end: string;
  activeThumb?: number;
};

type HourSliderProps = {
  value: HourSliderValueType;
  onChange: (value: HourSliderValueType) => void;
  minValueThreshold?: number;
  disabled?: boolean;
  valueLabelDisplay?: 'on' | 'auto' | 'off';
  sx?: SxProps<Theme>;
};

type Mark = {
  value: number;
  label?: ReactNode;
};

// TODO: refactor
const marks: Mark[] = [];

for (let index = 6; index < 23; index++) {
  const hour = index.toString().length > 1 ? `${index}` : `0${index}`;
  const item = {
    value: timeToMinute(`${hour}:00`),
    label: (
      <Typography
        color="ixColorGrey90"
        variant="caption"
      >{`${hour}:00`}</Typography>
    ),
  };
  marks.push(item);
}

const StyledSlider = styled(Slider)<SliderProps>(({ theme }) => ({
  color: theme.palette.ixColorWave.main,
  '.MuiSlider-thumb': {
    width: '16px',
    height: '16px',
    '&.Mui-active': {
      boxShadow: '0px 0px 0px 14px rgb(146 165 165 / 16%)',
    },
    '&.Mui-focusVisible': {
      boxShadow: '0px 0px 0px 14px rgb(146 165 165 / 16%)',
    },
  },
  '.MuiSlider-rail': {
    opacity: 1,
    backgroundColor: theme.palette.ixColorGrey20.main,
  },
}));

const HourSlider = (props: HourSliderProps) => {
  const { value, onChange, disabled, valueLabelDisplay = 'auto', sx } = props;

  const handleFormatValueLabel = (value: number) => {
    const { hours, minutes } = minuteToTime(value);

    return `${hours}:${minutes}`;
  };

  const handleChange = (
    _event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    const [start, end] = newValue as number[];
    onChange({
      start: handleFormatValueLabel(start),
      end: handleFormatValueLabel(end),
      activeThumb,
    });
  };

  return (
    <div>
      <StyledSlider
        sx={sx}
        disabled={disabled}
        valueLabelFormat={handleFormatValueLabel}
        valueLabelDisplay={valueLabelDisplay}
        marks={marks}
        onChange={handleChange}
        step={15}
        data-testid="slider"
        min={timeToMinute('06:00')}
        max={timeToMinute('22:00')}
        value={[
          timeToMinute(value?.start || '09:00'),
          timeToMinute(value?.end || '18:00'),
        ]}
        disableSwap
      />
    </div>
  );
};

export default HourSlider;
