import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client, Colleague } from './apiSlice';

export enum PERIOD_VIEW_TYPES {
  YEAR = 'year',
  MONTH = 'month',
}

export enum CALENDAR_VIEW_TYPES {
  LIST = 'list',
  GRID = 'grid',
}

export type CalendarFilterState = {
  selectedMonth: number;
  selectedYear: number;
  selectedClients: Client[];
  selectedColleagues: Colleague[];
  periodViewType: PERIOD_VIEW_TYPES;
  calendarViewType: CALENDAR_VIEW_TYPES;
};

export const initialState: CalendarFilterState = {
  selectedMonth: new Date().getMonth(),
  selectedYear: new Date().getFullYear(),
  selectedClients: [],
  selectedColleagues: [],
  periodViewType: PERIOD_VIEW_TYPES.MONTH,
  calendarViewType: CALENDAR_VIEW_TYPES.GRID,
};

export const storeCalendarFilterSlice = createSlice({
  name: 'calendarFilter',
  initialState,
  reducers: {
    setSelectedClients: (state, action: PayloadAction<Client[]>) => {
      state.selectedClients = action.payload;
    },
    setSelectedColleagues: (state, action: PayloadAction<Colleague[]>) => {
      state.selectedColleagues = action.payload;
    },
    increasePeriod: state => {
      if (state.periodViewType === PERIOD_VIEW_TYPES.YEAR) {
        state.selectedYear += 1;
      } else {
        if (state.selectedMonth == 11) {
          state.selectedMonth = 0;
          state.selectedYear += 1;
        } else {
          state.selectedMonth += 1;
        }
      }
    },
    decreasePeriod: state => {
      if (state.periodViewType === PERIOD_VIEW_TYPES.YEAR) {
        state.selectedYear -= 1;
      } else {
        if (state.selectedMonth == 0) {
          state.selectedMonth = 11;
          state.selectedYear -= 1;
        } else {
          state.selectedMonth -= 1;
        }
      }
    },
    setSelectedMonth: (state, action: PayloadAction<number>) => {
      state.selectedMonth = action.payload;
    },
    setSelectedYear: (state, action: PayloadAction<number>) => {
      state.selectedYear = action.payload;
    },
    setSelectedPeriodViewType: (
      state,
      action: PayloadAction<PERIOD_VIEW_TYPES>
    ) => {
      state.periodViewType = action.payload;
    },
    setSelectedCalendarViewType: (
      state,
      action: PayloadAction<CALENDAR_VIEW_TYPES>
    ) => {
      state.calendarViewType = action.payload;
    },
    setCurrentDate: state => {
      state.selectedMonth = new Date().getMonth();
      state.selectedYear = new Date().getFullYear();
    },
  },
});

export const {
  setSelectedColleagues,
  setSelectedClients,
  setSelectedMonth,
  setSelectedYear,
  setSelectedPeriodViewType,
  setSelectedCalendarViewType,
  decreasePeriod,
  increasePeriod,
} = storeCalendarFilterSlice.actions;

export default storeCalendarFilterSlice.reducer;
