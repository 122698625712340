import { createSvgIcon } from '@mui/material';

const FocusIcon = createSvgIcon(
  <>
    <path id="Path_276" data-name="Path 276" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_277"
      data-name="Path 277"
      d="M12,2A10,10,0,1,1,2,12,10,10,0,0,1,12,2Zm0,18a8,8,0,1,0-8-8A7.989,7.989,0,0,0,12,20Zm0-2a6,6,0,1,1,6-6A5.992,5.992,0,0,1,12,18Zm0-8a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Z"
      fill="currentColor"
    />
  </>,
  'Focus'
);

export default FocusIcon;
