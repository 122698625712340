import { Box } from '@mui/material';

import PageLayout from 'components/molecules/PageLayout';
import ProgressBarWrapper from 'containers/Layout/ProgressBarWrapper';
import EmployeeFilter from './components/EmployeeFilter';
import Calendar from './components/Calendar/components/Calendar/Calendar';

const Home = () => (
  <PageLayout sidebar={<EmployeeFilter />} leftSlot={<ProgressBarWrapper />}>
    <Box sx={{ padding: '0', height: '100%' }}>
      <Calendar />
    </Box>
  </PageLayout>
);

export default Home;
