import { Children, ComponentProps, PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';

import Typography from '../Typography';
import Stack from '../Stack';

type ExternalLinkProps = ComponentProps<'a'>;

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.ixColorGrey70.main,
  textDecoration: 'underline',
  transition: 'color 0.3s ease',
  display: 'flex',
  '&:hover': {
    color: theme.palette.ixColorWave.main,
  },
}));

const ExternalLink = (props: ExternalLinkProps) => {
  const { children } = props;

  return (
    <StyledLink {...props}>
      <Typography variant="caption">{children}</Typography>
    </StyledLink>
  );
};

const Group = (props: PropsWithChildren) => {
  const { children } = props;

  const links = Children.map(children, (child, idx) => {
    if (idx !== 0) {
      return [
        <>
          <span>{'·'}</span>
          {child}
        </>,
      ];
    }

    return child;
  });

  return (
    <Stack align="center" gap="small">
      {links}
    </Stack>
  );
};

ExternalLink.displayName = 'ExternalLink';
ExternalLink.Group = Group;

export default ExternalLink;
