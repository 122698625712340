import { useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import { styled } from '@mui/material/styles';

import Typography from 'components/atoms/Typography';
import CloseIcon from 'components/atoms/Icons/CloseIcon';
import SearchIcon from 'components/atoms/Icons/SearchIcon';
import ArrowDownIcon from 'components/atoms/Icons/ArrowDownIcon';

const CustomInput = styled('input')<{ isOpen: boolean }>(({ isOpen }) => ({
  transition: 'opacity 0.3s ease',
  position: 'absolute',
  left: 0,
  border: 'none',
  outline: 'none',
  width: '85%',
  opacity: isOpen ? 1 : 0,
  zIndex: isOpen ? 1 : -1,
  paddingLeft: '32px',
}));

type SearchableTitleProps = {
  title: string;
  onSearch?: (query: string) => void;
  onCollapse?: (state: boolean) => void;
};

const SearchableTitle = (props: SearchableTitleProps) => {
  const { title, onSearch, onCollapse } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchable, setSearchable] = useState(false);
  const [query, setQuery] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    onCollapse?.(!collapsed);
    setCollapsed(!collapsed);
  };

  const handleClear = () => {
    setSearchable(false);
    setQuery('');
    inputRef.current?.blur();
    onSearch?.('');
  };

  useEffect(() => {
    if (collapsed) {
      handleClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  // TODO: refactor - add separate clickFunctions for each onClick in render
  return (
    <ClickAwayListener
      onClickAway={() => {
        if (query.length === 0 && searchable) {
          handleClear();
        }
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: '8px',
          borderBottom: '2px solid',
          borderColor: theme => theme.palette.ixColorGrey20.main,
          cursor: 'pointer',
          marginBottom: '24px',
          paddingLeft: '32px',
        }}
      >
        <Box>
          <ArrowDownIcon
            onClick={handleCollapse}
            sx={{
              position: 'absolute',
              left: 0,
              transform: collapsed ? 'rotate(-90deg)' : 'rotate(0)',
              transition: 'transform 0.3s ease',
              zIndex: 3,
            }}
          />
          <Typography
            variant="subtitle-1"
            color="ixColorWave"
            sx={{
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              transformOrigin: 'left',
              transform: searchable
                ? 'translateY(-22px) scale(0.75)'
                : 'translateY(0) scale(1)',
            }}
            onClick={handleCollapse}
          >
            {title}
          </Typography>
        </Box>
        <CustomInput
          ref={inputRef}
          value={query}
          isOpen={searchable}
          onChange={e => {
            const value = e.currentTarget.value;
            onSearch?.(value);
            setQuery(value);
          }}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              handleClear();
            }
          }}
        />
        {collapsed ? null : searchable ? (
          <CloseIcon
            onClick={() => {
              handleClear();
            }}
          />
        ) : (
          <SearchIcon
            onClick={() => {
              inputRef.current?.focus();
              setSearchable(true);
            }}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SearchableTitle;
