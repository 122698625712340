import { styled, Theme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

interface StyledStackProps extends Omit<BoxProps, 'gap' | 'align' | 'wrap'> {
  direction?: 'vertical' | 'horizontal';
  gap?:
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge'
    | 'xxxlarge'
    | number;
  align?: 'start' | 'end' | 'center' | 'baseline';
  wrap?: boolean;
  fluid?: boolean;
}

const generateAlignStyles = (align: StyledStackProps['align'] = 'start') => {
  switch (align) {
    case 'baseline':
      return {
        alignItems: 'baseline',
      };
    case 'center':
      return {
        alignItems: 'center',
      };
    case 'end':
      return {
        alignItems: 'flex-end',
      };
    case 'start':
    default:
      return {
        alignItems: 'flex-start',
      };
  }
};

const generateGapStyles = (
  gap: StyledStackProps['gap'] = 'medium',
  direction: StyledStackProps['direction'] = 'horizontal',
  theme: Theme
) => {
  switch (direction) {
    case 'vertical':
      return {
        '> *:not(:last-child)': {
          marginBottom:
            typeof gap === 'string' ? `${theme.size[gap]}rem` : `${gap}rem`,
        },
      };
    case 'horizontal':
    default:
      return {
        '> *:not(:last-child)': {
          marginRight:
            typeof gap === 'string' ? `${theme.size[gap]}rem` : `${gap}rem`,
        },
      };
  }
};

const StyledStack = styled(Box)<StyledStackProps>(
  ({ align, gap, direction, wrap, fluid, theme }) => ({
    display: 'inline-flex',
    flexDirection: direction === 'horizontal' ? 'row' : 'column',
    width: fluid ? '100%' : 'auto',
    ...generateGapStyles(gap, direction, theme),
    ...generateAlignStyles(align),
    ...(wrap &&
      direction === 'horizontal' && {
        flexWrap: 'wrap',
      }),
  })
);

const Stack = (props: StyledStackProps) => {
  const {
    children,
    direction = 'horizontal',
    gap = 'medium',
    align = 'start',
    wrap = true,
    fluid = false,
  } = props;

  return (
    <StyledStack
      direction={direction}
      gap={gap}
      align={align}
      wrap={wrap}
      fluid={fluid}
      {...props}
    >
      {children}
    </StyledStack>
  );
};

export default Stack;
