import { useDispatch } from 'react-redux';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Typography from 'components/atoms/Typography';
import ArrowRightIcon from 'components/atoms/Icons/ArrowRightIcon';
import Separator from 'components/atoms/Separator';
import Popper from 'components/molecules/Popper';
import LeaveForm from 'containers/Layout/forms/BookLeaveForm/LeaveForm';
import { LEAVE_TYPE_MAP } from 'containers/Layout/forms/BookLeaveForm';
import { Leave } from 'utils/calendar-utils';
import { closeEditForm } from 'app-redux/storeCalendarSlice';
import { HAS_ACCESS, hasAccess } from 'utils/roleManagement';

type MoreLeavesProps = {
  vacations: Leave[];
};

type StyledBarProps = {
  isOpen: boolean;
} & BoxProps;

const StyledBar = styled(Box)<StyledBarProps>(({ theme, isOpen }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',
  marginLeft: '5%',
  width: '90%',
  borderRadius: '22px',
  fontSize: '12px',
  backgroundColor: isOpen
    ? theme.palette.ixColorGrey30.main
    : theme.palette.ixColorMist.main,
  color: theme.palette.ixColorGrey80.main,

  '&:hover': {
    backgroundColor: theme.palette.ixColorGrey30.main,
  },
}));

const MoreLeaves = (props: MoreLeavesProps) => {
  const { vacations } = props;
  const isUser = hasAccess(HAS_ACCESS.USER);
  const dispatch = useDispatch();

  return (
    <>
      <Popper
        papperSx={{ minWidth: '200px', padding: '0' }}
        placement="bottom"
        trigger={isOpen => (
          <StyledBar isOpen={isOpen}>
            {'+' + vacations.length.toString() + ' more'}
          </StyledBar>
        )}
        onClose={() => dispatch(closeEditForm())}
      >
        {() => (
          <>
            {vacations.map((vacation, idx) => (
              <>
                <Popper
                  key={vacation.id}
                  trigger={
                    <Box
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '20px 16px',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                          backgroundColor: theme =>
                            theme.palette.ixColorGrey5.main,
                        },
                      }}
                    >
                      <Typography>{vacation.fullname}</Typography>
                      <ArrowRightIcon />
                    </Box>
                  }
                  triggerProps={{ sx: { width: '100%' } }}
                  placement="right-end"
                  onClose={() => dispatch(closeEditForm())}
                >
                  {({ close }) => (
                    <>
                      <Typography
                        color="ixColorGrey60"
                        variant="h6"
                        sx={{ marginBottom: '32px' }}
                      >
                        {(vacation.isMyself
                          ? "You're "
                          : vacation.fullname + ' is ') + 'on holiday'}
                        {isUser &&
                          vacation.isMyself &&
                          ` - ${
                            LEAVE_TYPE_MAP[
                              vacation.type as keyof typeof LEAVE_TYPE_MAP
                            ]
                          }`}
                      </Typography>
                      <LeaveForm
                        isEditing
                        vacation={vacation}
                        leaveType={
                          LEAVE_TYPE_MAP[
                            vacation.type as keyof typeof LEAVE_TYPE_MAP
                          ]
                        }
                        onCloseEdit={() => {
                          close();
                          dispatch(closeEditForm());
                        }}
                      />
                    </>
                  )}
                </Popper>
                {vacations.length !== idx + 1 && (
                  <Separator
                    sx={{ margin: '0 auto', width: 'calc(100% - 32px)' }}
                  />
                )}
              </>
            ))}
          </>
        )}
      </Popper>
    </>
  );
};

export default MoreLeaves;
