import { useFormContext, Controller } from 'react-hook-form';

import DatePicker, { InternalDatePickerProps } from '../../../DatePicker';

type FormInputProps = {
  name: string;
  shouldUnregister?: boolean;
  helperText?: string;
} & InternalDatePickerProps;

const FormDatePicker = (props: Omit<FormInputProps, 'onChange' | 'value'>) => {
  const { name, shouldUnregister, helperText, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <DatePicker
          {...field}
          {...rest}
          inputFormat="dd.MM.yyyy"
          error={!!errors[name]}
          helperText={
            errors[name]
              ? (errors[name]?.message as unknown as string)
              : helperText || ''
          }
        />
      )}
    />
  );
};

export default FormDatePicker;
