import {
  Leave,
  MONTHS,
  getEndDateCalendar,
  getStartDateCalendar,
  isBetweenTwoDates,
  monthDurations,
} from 'utils/calendar-utils';

export function divideLeavesByMonths(
  year: number,
  leaves: Array<Leave>,
  periodFilter: 'year' | 'month'
) {
  const items: { [month: string]: Array<Leave> } = {};

  MONTHS.forEach(element => {
    items[element] = [];
  });
  leaves.map(element => {
    if (
      element.startDate.getFullYear() === year ||
      element.endDate.getFullYear() === year
    ) {
      if (
        element.endDate.getMonth() === element.startDate.getMonth() &&
        element.startDate.getFullYear() === element.endDate.getFullYear()
      ) {
        items[MONTHS[element.endDate.getMonth()]].push(element);
      } else {
        if (periodFilter === 'month') {
          if (element.endDate.getFullYear() === year) {
            items[MONTHS[element.endDate.getMonth()]].push(element);
          }
          if (element.startDate.getFullYear() === year) {
            items[MONTHS[element.startDate.getMonth()]].push(element);
          }
        } else {
          if (element.endDate.getFullYear() === year) {
            items[MONTHS[element.endDate.getMonth()]].push({
              ...element,
              startDate: new Date(
                element.endDate.getFullYear(),
                element.endDate.getMonth(),
                1
              ),
            });
          }
          if (element.startDate.getFullYear() === year) {
            items[MONTHS[element.startDate.getMonth()]].push({
              ...element,
              endDate: new Date(
                element.startDate.getFullYear(),
                element.startDate.getMonth(),
                monthDurations(element.startDate.getFullYear())[
                  element.startDate.getMonth()
                ]
              ),
            });
          }

          const date1 = new Date(element.startDate);
          const date2 = new Date(element.endDate);
          date1.setMonth(date1.getMonth() + 1);

          while (date1.getMonth() < date2.getMonth()) {
            items[MONTHS[date1.getMonth()]].push({
              ...element,
              startDate: new Date(
                element.endDate.getFullYear(),
                element.endDate.getMonth(),
                1
              ),
              endDate: new Date(
                element.startDate.getFullYear(),
                element.startDate.getMonth(),
                monthDurations(element.startDate.getFullYear())[
                  element.startDate.getMonth()
                ]
              ),
            });
            date1.setMonth(date1.getMonth() + 1);
          }
        }
      }
    }
  });

  return items;
}

export function divideLeavesByWeeks(
  year: number,
  month: number,
  leaves: Array<Leave>
) {
  const leavesDivided = [...leaves];
  const items: { [week: string]: Array<Leave> } = {};
  const startData = getStartDateCalendar(month, year);
  const endDate = getEndDateCalendar(month, year);

  while (startData.getTime() <= endDate.getTime()) {
    const endWeek = new Date(startData);
    endWeek.setDate(endWeek.getDate() + 6);
    const index = `${startData.getDate().toString()} ${
      MONTHS[startData.getMonth()]
    } - ${endWeek.getDate().toString()} ${MONTHS[endWeek.getMonth()]}`;

    items[index] = [];

    leavesDivided.map(element => {
      if (
        isBetweenTwoDates(element.startDate, element.endDate, endWeek) &&
        isBetweenTwoDates(element.startDate, element.endDate, startData)
      ) {
        items[index].push({
          ...element,
          startDate: new Date(startData),
          endDate: new Date(endWeek),
        });
      } else {
        if (
          isBetweenTwoDates(startData, endWeek, element.startDate) &&
          isBetweenTwoDates(startData, endWeek, element.endDate)
        ) {
          items[index].push({ ...element });
        } else {
          if (isBetweenTwoDates(element.startDate, element.endDate, endWeek)) {
            items[index].push({ ...element, endDate: new Date(endWeek) });
          } else if (
            isBetweenTwoDates(element.startDate, element.endDate, startData)
          ) {
            items[index].push({ ...element, startDate: new Date(startData) });
          }
        }
      }
    });
    startData.setDate(startData.getDate() + 7);
  }

  return items;
}

export function vacationPeriodDisplay(vacation: Leave): string {
  let display = vacation.startDate.getDate().toString();
  const sameDay = vacation.endDate.getDate() === vacation.startDate.getDate();
  const sameMonth =
    vacation.endDate.getMonth() === vacation.startDate.getMonth();
  if (!sameDay || !sameMonth) {
    if (!sameMonth) {
      display +=
        ' ' +
        MONTHS[vacation.startDate.getMonth()].toUpperCase() +
        ' - ' +
        vacation.endDate.getDate();
    } else {
      display += ' - ' + vacation.endDate.getDate();
    }
  }
  display += ' ' + MONTHS[vacation.endDate.getMonth()].toUpperCase();

  return display;
}

export const setBackgroundColor = (employee: Leave) => {
  if (
    (employee.isMyself && employee.type === 'SPECIAL_LEAVE') ||
    (!employee.isMyself && employee.type === 'SPECIAL_LEAVE')
  ) {
    return '#F2617A';
  }
  if (employee.isMyself && employee.type === 'NORMAL_LEAVE') {
    return '#003D4F';
  }
  if (employee.type === 'NORMAL_LEAVE') {
    return '#9E9E9E';
  }

  // TODO: test this if it's alright
  return '#9E9E9E';
};

export const setBackgroundColorUsers = (employee: Leave) => {
  if (employee.isMyself && employee.type === 'SPECIAL_LEAVE') {
    return '#F2617A';
  }
  if (employee.isMyself && employee.type === 'NORMAL_LEAVE') {
    return '#003D4F';
  }

  return '#9E9E9E';
};

export const inThePast = (leave: Leave) =>
  leave.startDate.getTime() < new Date().getTime();
