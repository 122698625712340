import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MuiPopper, { PopperProps } from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Box, { BoxProps } from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { IconButton } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

import CloseIcon from 'components/atoms/Icons/CloseIcon';
import Typography from 'components/atoms/Typography';
import useDisclosure from 'hooks/useDisclosure';
import useGlobalDisclosure from 'hooks/useGlobalDisclosure';

type InternalPopperProps = Omit<PopperProps, 'open' | 'children'> & {
  storeKey?: string;
  trigger: ((isOpen: boolean) => JSX.Element) | JSX.Element;
  triggerProps?: BoxProps;
  papperSx?: SxProps<Theme>;
  closable?: boolean;
  onClose?: () => void;
  closeWhenRouteChange?: boolean;
  children: ({ close }: { close: () => void }) => JSX.Element;
};

const Popper = (props: InternalPopperProps) => {
  const {
    id,
    storeKey,
    children,
    trigger,
    triggerProps,
    papperSx,
    title,
    closable,
    onClose,
    closeWhenRouteChange,
    ...rest
  } = props;
  const ref = useRef(null);
  const { pathname } = useLocation();
  const [element, setElement] = useState<HTMLElement | null>(null);
  // TODO: check if still works correctly, if not, revert change
  const {
    isOpen: globalIsOpen,
    close: globalClose,
    open: globalOpen,
  } = useGlobalDisclosure(storeKey || '', {
    onClose: () => onClose?.(),
  });
  const {
    isOpen: localIsOpen,
    close: localClose,
    open: localOpen,
  } = useDisclosure(false, {
    onClose: () => onClose?.(),
  });

  const { isOpen, close, open } = storeKey
    ? { isOpen: globalIsOpen, close: globalClose, open: globalOpen }
    : { isOpen: localIsOpen, close: localClose, open: localOpen };

  useLayoutEffect(() => {
    if (ref.current) {
      setElement(ref.current);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      closeWhenRouteChange && close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <Box
        {...triggerProps}
        sx={{ display: 'inline-block', ...triggerProps?.sx }}
        ref={ref}
        onClick={() => {
          isOpen ? close() : open();
        }}
      >
        {typeof trigger === 'function' ? trigger(isOpen) : trigger}
      </Box>

      <MuiPopper {...rest} id={id} open={isOpen} anchorEl={element} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box>
              <ClickAwayListener
                onClickAway={e => {
                  // TODO: fix closing issue when click select input
                  // workaround for closing after clicking select input inside a popper
                  const element = e.target as HTMLElement;
                  if (element.tagName !== 'BODY') {
                    close();
                  }
                }}
              >
                <Paper
                  sx={{
                    padding: '24px',
                    boxShadow: '0px 3px 6px #00000080',
                    ...papperSx,
                  }}
                >
                  {(title || closable) && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '24px',
                        flexWrap: 'nowrap',
                      }}
                    >
                      {title && (
                        <Typography color="ixColorGrey60" variant="h6">
                          {title}
                        </Typography>
                      )}
                      {closable && (
                        <IconButton onClick={() => close()}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </Box>
                  )}
                  {children({ close })}
                </Paper>
              </ClickAwayListener>
            </Box>
          </Fade>
        )}
      </MuiPopper>
    </>
  );
};

export default Popper;
