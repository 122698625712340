import { Box } from '@mui/material';
import { Theme, SxProps } from '@mui/material/styles';

import Button from 'components/atoms/Button';
import Typography from 'components/atoms/Typography';

type StatusBannerProps = {
  message: string;
  action: string;
  onActionClick: () => void;
  sx?: SxProps<Theme>;
};

const StatusBanner = (props: StatusBannerProps) => {
  const { message, action, onActionClick, sx } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '32px',
        ...sx,
      }}
    >
      <Box
        component="img"
        width="auto"
        height="250px"
        src="/images/error.svg"
      />
      <Typography variant="h1">{message}</Typography>
      <Button onClick={onActionClick}>{action}</Button>
    </Box>
  );
};

export default StatusBanner;
