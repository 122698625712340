import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usePermify } from '@permify/react-role';

import { setEmployeeNumber } from '../../../app-redux/storeUserSlice';
import {
  useGetCsrfTokenQuery,
  useGetUserProfileQuery,
} from '../../../app-redux/apiSlice';
import Spinner from '../../../components/molecules/Spinner';
import { redirectToLogin } from '../../../utils/api';

type SecuredProps = {
  children: JSX.Element | JSX.Element[];
};

export function Secured(props: SecuredProps): JSX.Element {
  const dispatch = useDispatch();
  const { setUser: setUserRoles } = usePermify();

  const {
    data: user,
    isLoading: isLoadingForUser,
    isSuccess: isSuccessForUser,
    isError: isErrorForUser,
  } = useGetUserProfileQuery();

  const {
    data: csrfToken,
    isSuccess: isSuccessForToken,
    isLoading: isLoadingForToken,
    isError: isErrorForToken,
  } = useGetCsrfTokenQuery(undefined, {
    skip: !user?.employeeNumber,
  });

  useEffect(() => {
    if (isSuccessForUser && isSuccessForToken) {
      if (csrfToken === '' || user.email === undefined) {
        redirectToLogin();
      } else {
        dispatch(setEmployeeNumber(user.employeeNumber));
        setUserRoles({
          id: user.employeeNumber,
          roles: user.roleList,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessForUser, isSuccessForToken]);

  if (isErrorForUser || isErrorForToken) {
    redirectToLogin();

    return <></>;
  }

  if (isLoadingForUser || isLoadingForToken) {
    return <Spinner text="Loading..." fullPage />;
  }

  if (
    isSuccessForUser &&
    isSuccessForToken &&
    csrfToken !== '' &&
    user.email !== undefined
  ) {
    return <>{props.children}</>;
  }

  return <>Something went wrong. Please contact with your HR.</>;
}
