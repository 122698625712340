import { createSvgIcon } from '@mui/material';

const ArrowLeftIcon = createSvgIcon(
  <>
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"
      fill="currentColor"
    />
  </>,
  'ArrowLeft'
);

export default ArrowLeftIcon;
