import { TableCell, TableRow } from '@mui/material';

import React from 'react';

const NoVacationRow = () => {
  return (
    <TableRow hover sx={{ borderBottom: '2px solid #E3E3E3' }}>
      <TableCell colSpan={4} sx={{ border: 'none', color: '#00000061' }}>
        No vacations registered.
      </TableCell>
    </TableRow>
  );
};

export default NoVacationRow;
