import { createSvgIcon } from '@mui/material';

const InformationIcon = createSvgIcon(
  <>
    <path id="Path_273" data-name="Path 273" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_274"
      data-name="Path 274"
      d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20ZM11,7h2V9H11Zm0,4h2v6H11Z"
      fill="currentColor"
    />
  </>,
  'Information'
);

export default InformationIcon;
