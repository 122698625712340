import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ColleaguesProjectsFilterState = {
  [key: string]: Array<string>;
};

export type UserState = {
  selectedColleagues: ColleaguesProjectsFilterState;
  employeeNumber: string;
  selectedProjectIds: string[];
  selectedProjectId: string;
  selectedSpecialProjectIds: string[];
  selectedProjectName: string;
  selectedSpecialProjectName: string;
};

const initialState: UserState = {
  selectedColleagues: {},
  selectedProjectIds: [],
  employeeNumber: '',
  selectedProjectId: '',
  selectedSpecialProjectIds: [],
  selectedProjectName: '',
  selectedSpecialProjectName: '',
};

export const storeUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEmployeeNumber: (state, action: PayloadAction<string>) => {
      state.employeeNumber = action.payload;
    },
    setSelectedProjectId: (state, action: PayloadAction<string>) => {
      state.selectedProjectId = action.payload;
    },
    setSelectedProjectIds: (state, action: PayloadAction<string[]>) => {
      state.selectedProjectIds = action.payload;
    },
    setSelectedSpecialProjectIds: (state, action: PayloadAction<string[]>) => {
      state.selectedSpecialProjectIds = action.payload;
    },
    setSelectedProjectName: (state, action: PayloadAction<string>) => {
      state.selectedProjectName = action.payload;
    },
    setSelectedSpecialProjectName: (state, action: PayloadAction<string>) => {
      state.selectedSpecialProjectName = action.payload;
    },
    setSelectedColleagues: (
      state,
      action: PayloadAction<ColleaguesProjectsFilterState>
    ) => {
      state.selectedColleagues = action.payload;
    },
  },
});

export const {
  setEmployeeNumber,
  setSelectedProjectId,
  setSelectedProjectIds,
  setSelectedProjectName,
  setSelectedColleagues,
  setSelectedSpecialProjectIds,
  setSelectedSpecialProjectName,
} = storeUserSlice.actions;

export default storeUserSlice.reducer;
