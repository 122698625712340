import { ChangeEvent, useState } from 'react';
import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';

import Typography from '../../../components/atoms/Typography';
import Select from '../../../components/atoms/Select';
import Button from '../../../components/atoms/Button';
import Table, { Column } from '../../../components/molecules/Table';

import { Months } from '../../../utils/globalConstants/Months';
import { range } from '../../../utils/arraysOfRange';

// TODO: Change types according to real data
type IUser = {
  fullName: string;
};

type ILog = {
  id: string;
  creationDate: string;
  actionType: string;
  creationUser: IUser;
  startDate: string;
  endDate: string;
  leaveType: string;
  affectedUser: IUser;
};

// TODO: Use real data after BE implemented
const mockLogData: ILog[] = [
  {
    id: '1',
    creationDate: '2023-02-09 16:46:09',
    actionType: 'Removed a leave',
    creationUser: { fullName: 'Görkem Tosun' },
    startDate: '2023-02-22T00:00:00.000Z',
    endDate: '2023-02-23T00:00:00.000Z',
    leaveType: 'Special',
    affectedUser: {
      fullName: 'Alexandru Marin',
    },
  },
  {
    id: '2',
    creationDate: '2023-02-12 21:46:09',
    actionType: 'Added a new leave',
    creationUser: { fullName: 'Görkem Tosun' },
    startDate: '2023-02-24T00:00:00.000Z',
    endDate: '2023-02-25T00:00:00.000Z',
    leaveType: 'Normal',
    affectedUser: {
      fullName: 'Alexandru Marin',
    },
  },
  {
    id: '3',
    creationDate: '2023-02-21 13:46:09',
    actionType: 'Edited an existing leave',
    creationUser: { fullName: 'Görkem Tosun' },
    startDate: '2023-02-26T00:00:00.000Z',
    endDate: '2023-02-28T00:00:00.000Z',
    leaveType: 'Normal',
    affectedUser: {
      fullName: 'Iulian Vraja',
    },
  },
];

const LogView = () => {
  const months = Object.keys(Months) as Array<keyof typeof Months>;
  const currentYear = new Date().getFullYear();

  //TODO: Open here when be implemeted
  // const dispatch = useDispatch();
  // const [downloadLogs, resultDownloadLogs] =
  // useDownloadLogsMutation();

  //TODO: Open here when be implemeted
  // const {data: logs, isLoading: logsIsLoading} = useGetLogs(params);

  // TODO: Change dataIndex, accessors and renders according to real data
  const columns: Column<ILog>[] = [
    {
      dataIndex: 'creationDate',
      label: 'Date & time',
      align: 'left',
      width: 142,
      render: value =>
        (value as string)
          .split(' ')
          .map(part => <Typography key={part}>{part}</Typography>),
    },
    {
      dataIndex: 'actionType',
      label: 'Action',
      align: 'left',
      minWidth: 150,
    },
    {
      dataIndex: 'creationUser',
      accessor: 'fullName',
      label: 'Made By',
      align: 'left',
      minWidth: 150,
    },
    {
      dataIndex: 'startDate',
      label: 'Start Date',
      align: 'left',
      width: 142,
      render: value => (
        <Typography>{format(parseISO(value), 'dd-MM-yyyy')}</Typography>
      ),
    },
    {
      dataIndex: 'endDate',
      label: 'End Date',
      align: 'left',
      width: 142,
      render: value => (
        <Typography>{format(parseISO(value), 'dd-MM-yyyy')}</Typography>
      ),
    },
    {
      dataIndex: 'leaveType',
      label: 'Type of leave',
      align: 'left',
      minWidth: 150,
    },
    {
      dataIndex: 'affectedUser',
      accessor: 'fullName',
      label: 'User affected',
      align: 'left',
      minWidth: 150,
    },
  ];

  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [month, setMonth] = useState(new Date().getMonth().toString());

  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    setYear(e.target.value as string);
  };
  const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMonth(e.target.value);
  };

  const handleDownloadLog = async () => {
    //TODO: Open here when be implemeted
    // try {
    //   await downloadLogs(params).unwrap();
    //   dispatch(openSuccessSnackbar('Logs was downloaded successfully.'));
    // } catch (err) {
    //   if (isFetchBaseQueryError(err)) {
    //     const errMsg = (err.data as { error: string }).error;
    //     dispatch(openErrorSnackbar(errMsg));
    //   } else if (isErrorWithMessage(err)) {
    //     dispatch(openErrorSnackbar(err.message));
    //   }
    // }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ marginBottom: '32px' }}>
        Log
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: '24px',
        }}
      >
        <Select
          sx={{ width: 130, marginRight: '24px', marginBottom: 0 }}
          value={year}
          onChange={handleYearChange}
          searchable={false}
          label="Year"
        >
          {range(2010, currentYear).map(year => (
            <Select.MenuItem key={year} value={year.toString()}>
              {year}
            </Select.MenuItem>
          ))}
        </Select>
        <Select
          sx={{ width: 280, marginRight: '24px', marginBottom: 0 }}
          value={month}
          onChange={handleMonthChange}
          searchable={false}
          label="Month"
        >
          {months.map((key, index) => (
            <Select.MenuItem key={key} value={index}>
              {key}
            </Select.MenuItem>
          ))}
        </Select>
        <Button onClick={handleDownloadLog}>Download Log</Button>
      </Box>
      {/*TODO: Open here when be implemeted*/}
      {/* {logsIsLoading ? (
        <Spinner fullPage text='Logs are loading...' />
      ) : (
        <Table<ILog> columns={columns} data={mockLogData} />
      )} */}
      <Table<ILog> columns={columns} data={mockLogData} />
      {/*TODO: Open here when be implemeted*/}
      {/* {resultDownloadLogs.isLoading && (
        <Spinner fullPage text='Logs are being prepared...' />
      )} */}
    </Box>
  );
};

export default LogView;
