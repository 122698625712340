import { createSvgIcon } from '@mui/material';

const ChatIcon = createSvgIcon(
  <>
    <path id="Path_237" data-name="Path 237" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_238"
      data-name="Path 238"
      d="M5.763,17H20V5H4V18.385Zm.692,2L2,22.5V4A1,1,0,0,1,3,3H21a1,1,0,0,1,1,1V18a1,1,0,0,1-1,1Z"
      fill="currentColor"
    />
  </>,
  'Chat'
);

export default ChatIcon;
