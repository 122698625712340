import { useState } from 'react';

import Tabs from 'components/molecules/Tabs';
import LeaveForm from './LeaveForm';
import { HAS_ACCESS } from 'utils/roleManagement';

export enum LEAVE_TYPES {
  NORMAL = 'Normal Leave',
  SPECIAL = 'Special Leave',
}

export const LEAVE_TYPE_MAP = {
  NORMAL_LEAVE: LEAVE_TYPES.NORMAL,
  SPECIAL_LEAVE: LEAVE_TYPES.SPECIAL,
} as const;

export type CommonFields = {
  employeeId: string;
  comment: string;
  cc: string[];
};

const BookLeaveForm = () => {
  const [commonFeilds, setCommonFields] = useState<CommonFields>({
    employeeId: '',
    comment: '',
    cc: [],
  });

  return (
    <Tabs>
      <Tabs.Item label="Normal Leave">
        <LeaveForm
          leaveType={LEAVE_TYPES.NORMAL}
          setCommonFields={setCommonFields}
          commonFields={commonFeilds}
        />
      </Tabs.Item>
      <Tabs.Item label="Special Leave" hasAccess={HAS_ACCESS.HR}>
        <LeaveForm
          leaveType={LEAVE_TYPES.SPECIAL}
          setCommonFields={setCommonFields}
          commonFields={commonFeilds}
        />
      </Tabs.Item>
      {/* TODO: open when BE implement */}
      {/* <Tabs.Item label='Bank Holiday' hasAccess={HAS_ACCESS.HR}>
        <BankHolidayForm />
      </Tabs.Item>
      <Tabs.Item label='Business Trip' hasAccess={HAS_ACCESS.HR}>
        <BusinessTripForm />
      </Tabs.Item> */}
    </Tabs>
  );
};

export default BookLeaveForm;
