import { createSvgIcon } from '@mui/material';

const CloseIcon = createSvgIcon(
  <>
    <path id="Path_239" data-name="Path 239" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_240"
      data-name="Path 240"
      d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z"
      fill="currentColor"
    />
  </>,
  'Close'
);

export default CloseIcon;
