import { HasAccess } from 'utils/roleManagement';
import { Leave } from 'utils/calendar-utils';
import { TableCell } from '@mui/material';

const LeaveTypeCell = ({ vacation }: { vacation: Leave }) => {
  return (
    <TableCell>
      <HasAccess
        roles={vacation.isMyself ? ['USER', 'HR', 'ADMIN'] : ['HR', 'ADMIN']}
      >
        {vacation.specialLeaveType && vacation.specialLeaveType?.length > 1
          ? vacation.specialLeaveType?.slice(0, 1) +
            vacation.specialLeaveType?.slice(1)
          : vacation.type == 'NORMAL_LEAVE'
          ? 'Normal leave'
          : ''}
      </HasAccess>
    </TableCell>
  );
};

export default LeaveTypeCell;
