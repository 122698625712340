import { createSvgIcon } from '@mui/material';

const MenuIcon = createSvgIcon(
  <>
    <path id="Path_249" data-name="Path 249" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_250"
      data-name="Path 250"
      d="M3,4H21V6H3Zm0,7H21v2H3Zm0,7H21v2H3Z"
      fill="currentColor"
    />
  </>,
  'Menu'
);

export default MenuIcon;
