import Chip from 'components/atoms/Chip';
import { HasAccess } from 'utils/roleManagement';
import { Leave } from 'utils/calendar-utils';
import { TableCell } from '@mui/material';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const DocumentStatusCell = ({ vacation }: { vacation: Leave }) => {
  return (
    <TableCell>
      <div style={{ display: 'flex' }}>
        <HasAccess
          roles={vacation.isMyself ? ['USER', 'HR', 'ADMIN'] : ['HR', 'ADMIN']}
        >
          {vacation.docusignEnvelopeStatus ? (
            <Chip
              variant="filled"
              color="grey"
              label={capitalizeFirstLetter(vacation.docusignEnvelopeStatus)}
            ></Chip>
          ) : (
            <Chip
              variant="filled"
              color="grey"
              label={capitalizeFirstLetter('pending')}
            />
          )}
        </HasAccess>
      </div>
    </TableCell>
  );
};

export default DocumentStatusCell;
