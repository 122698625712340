import { Box } from '@mui/material';
import ProgressBarInfoBubble from '../ProgressBarInfoBubble';
import Typography from 'components/atoms/Typography';
import { styled } from '@mui/material/styles';

type ProgressBarProps = {
  totalDays: number;
  takenDays: number;
  plannedDays: number;
  availableDays: number;
  carryOverDays?: number;
  daysTakenInAdvance?: { [key: number]: number };
};

const StyledLine = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '4px',
  backgroundColor: theme.palette.ixColorFlamingo.main,
  opacity: 0.24,
  position: 'relative',
}));

const StyledPlannedLine = styled(Box)(({ theme }) => ({
  height: '4px',
  backgroundColor: theme.palette.ixColorFlamingo.main,
  opacity: 0.4,
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  transition: 'all 0.3s ease',
}));

const StyledProgressLine = styled(Box)(({ theme }) => ({
  height: '4px',
  backgroundColor: theme.palette.ixColorFlamingo.main,
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  transition: 'all 0.3s ease',
}));

const ProgressBar = (props: ProgressBarProps) => {
  const {
    takenDays,
    availableDays,
    totalDays,
    carryOverDays,
    daysTakenInAdvance,
    plannedDays,
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: 584,
      }}
    >
      <Box
        sx={{
          flex: '1',
          marginRight: '8px',
        }}
      >
        <Box sx={{ position: 'relative', marginBottom: '12px' }}>
          <StyledLine />
          <StyledPlannedLine
            sx={{ width: `${((takenDays + plannedDays) * 100) / totalDays}%` }}
          />
          <StyledProgressLine
            sx={{ width: `${(takenDays * 100) / totalDays}%` }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="caption">{takenDays} days taken</Typography>
          <Typography variant="caption">{plannedDays} days planned</Typography>
          <Typography variant="caption">
            {availableDays} days available
          </Typography>
        </Box>
      </Box>
      <ProgressBarInfoBubble
        carryOverDays={carryOverDays}
        daysTakenInAdvance={daysTakenInAdvance}
      ></ProgressBarInfoBubble>
    </Box>
  );
};

export default ProgressBar;
