import { ComponentProps } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';

type FormProps = ComponentProps<'form'>;

const StyledForm = styled('form')<FormProps>(() => ({
  '.MuiFormControlLabel-root': {
    marginBottom: '24px',
  },
  '.MuiFormControl-root': {
    position: 'relative',
    marginBottom: '24px',

    '.MuiFormHelperText-root': {
      whiteSpace: 'nowrap',
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));

const Form = (props: FormProps) => {
  const { children } = props;

  return <StyledForm {...props}>{children}</StyledForm>;
};

export { useForm, FormProvider, yupResolver };

export default Form;
