import { createSvgIcon } from '@mui/material';

const GridIcon = createSvgIcon(
  <>
    <path id="Path_241" data-name="Path 241" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_242"
      data-name="Path 242"
      d="M14,10v4H10V10Zm2,0h5v4H16ZM14,21H10V16h4Zm2,0V16h5v4a1,1,0,0,1-1,1ZM14,3V8H10V3Zm2,0h4a1,1,0,0,1,1,1V8H16ZM8,10v4H3V10ZM8,21H4a1,1,0,0,1-1-1V16H8ZM8,3V8H3V4A1,1,0,0,1,4,3Z"
      fill="currentColor"
    />
  </>,
  'Grid'
);

export default GridIcon;
