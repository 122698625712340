import { useEffect, useRef, useState } from 'react';
import { Box, Tooltip } from '@mui/material';

import { BankHolidayResponse } from 'app-redux/apiSlice';

export type HolidayProps = {
  borderType: string;
  bankHolidayInformation: BankHolidayResponse;
  width: number;
  nrDaysRepresented?: number;
};

const BankHoliday = ({
  borderType,
  width,
  bankHolidayInformation,
}: HolidayProps) => {
  const labelRef = useRef<HTMLDivElement>();
  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);

  const getBorderRadius = () => {
    switch (borderType) {
      case 'both':
        return '22px';
      case 'leftBorder':
        return '22px 0px 0px 22px';
      case 'rightBorder':
        return '0px 22px 22px 0px';
      default:
        return '0px';
    }
  };

  const getLabel = () => bankHolidayInformation.name || 'Bank holiday';

  useEffect(() => {
    const onResize = () => {
      if (labelRef.current) {
        setIsEllipsis(
          labelRef.current.offsetWidth < labelRef.current.scrollWidth
        );
      }
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <Tooltip title={isEllipsis && getLabel()}>
      <Box
        ref={(node: HTMLDivElement) => {
          if (node) {
            labelRef.current = node;
            setIsEllipsis(node.offsetWidth < node.scrollWidth);
          }
        }}
        whiteSpace="nowrap"
        role="holiday"
        display="block"
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        marginLeft="4%"
        marginBottom="4px"
        height="24px"
        minHeight="24px"
        width={(width + 4).toString() + '%'}
        border="1px solid #E1E2E3"
        bgcolor="#ffffff"
        color="#212223"
        borderRadius={getBorderRadius()}
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          padding: '3px 8px 0 8px',
          fontSize: 12,
          cursor: 'not-allowed',
        }}
        className="bank-holiday"
      >
        {getLabel()}
      </Box>
    </Tooltip>
  );
};
export default BankHoliday;
