import { Box, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import theme from 'theme';
import { Leave as LeaveType } from 'utils/calendar-utils';
import { HasAccess, HAS_ACCESS } from 'utils/roleManagement';
import {
  closeEditForm,
  openEditForm,
  setSelectionInProgress,
} from 'app-redux/storeCalendarSlice';
import { RootState } from 'app-redux/store';
import './Leave.scss';

export type LeaveProps = {
  borderType: string;
  vacInformation: LeaveType;
  width: number;
  nrDaysRepresented?: number;
  sx?: SxProps;
  secondLeave?: boolean;
};

const Leave = ({
  borderType,
  vacInformation,
  width,
  sx,
  secondLeave,
}: LeaveProps) => {
  const labelRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const selectionInProgress = useSelector(
    (state: RootState) => state.calendar.dateSelection.selectionInProgress
  );
  const selectedVacation = useSelector(
    (state: RootState) => state.calendar.selectedVacation
  );

  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);

  const borderRadius =
    borderType === '0'
      ? '0px'
      : borderType === 'leftBorder'
      ? '22px 0px 0px 22px'
      : borderType === 'rightBorder'
      ? '0px 22px 22px 0px'
      : '22px';

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    const allVacations = document.querySelectorAll<HTMLDivElement>(
      `[data-vacationId="${vacInformation.id}"]`
    );
    if (!selectionInProgress && !selectedVacation) {
      dispatch(setSelectionInProgress(selectionInProgress));

      dispatch(
        openEditForm({ el: event?.currentTarget, vacation: vacInformation })
      );
      allVacations.forEach(el => {
        el.style.zIndex = '3';
      });
    } else {
      allVacations.forEach(el => {
        el.style.zIndex = '0';
      });
      dispatch(closeEditForm());
    }
  };

  const setBackgroundColor = () => {
    if (
      (vacInformation.isMyself && vacInformation.type === 'SPECIAL_LEAVE') ||
      vacInformation.type === 'SPECIAL_LEAVE'
    ) {
      return theme.palette.ixColorFlamingo.main;
    }
    if (vacInformation.isMyself && vacInformation.type === 'NORMAL_LEAVE') {
      return theme.palette.ixColorWave.main;
    }
    if (vacInformation.type === 'NORMAL_LEAVE') {
      return theme.palette.ixColorTalc.main;
    }

    return theme.palette.ixColorTalc.main;
  };

  const setTextColor = () => {
    if (
      (vacInformation.isMyself && vacInformation.type === 'SPECIAL_LEAVE') ||
      (vacInformation.isMyself && vacInformation.type === 'NORMAL_LEAVE') ||
      vacInformation.type === 'SPECIAL_LEAVE'
    ) {
      return '#FFFFFF';
    }

    if (vacInformation.type === 'NORMAL_LEAVE') {
      return theme.palette.ixColorGrey80.main;
    }

    return '#FFFFFF';
  };

  const setBorder = () => {
    if (
      (vacInformation.isMyself && vacInformation.type === 'SPECIAL_LEAVE') ||
      (vacInformation.isMyself && vacInformation.type === 'NORMAL_LEAVE') ||
      vacInformation.type === 'SPECIAL_LEAVE'
    ) {
      return 'none';
    }

    if (vacInformation.type === 'NORMAL_LEAVE') {
      return '1px solid #003D4F';
    }

    return 'none';
  };

  const setBackgroundColorUsers = () => {
    if (vacInformation.isMyself && vacInformation.type === 'SPECIAL_LEAVE') {
      return theme.palette.ixColorFlamingo.main;
    }
    if (vacInformation.isMyself && vacInformation.type === 'NORMAL_LEAVE') {
      return theme.palette.ixColorWave.main;
    }

    return theme.palette.ixColorTalc.main;
  };

  const setTextColorUsers = () => {
    if (
      (vacInformation.isMyself && vacInformation.type === 'SPECIAL_LEAVE') ||
      (vacInformation.isMyself && vacInformation.type === 'NORMAL_LEAVE')
    ) {
      return '#FFFFFF';
    }

    return theme.palette.ixColorGrey80.main;
  };

  const setBorderUsers = () => {
    if (
      (vacInformation.isMyself && vacInformation.type === 'SPECIAL_LEAVE') ||
      (vacInformation.isMyself && vacInformation.type === 'NORMAL_LEAVE')
    ) {
      return 'none';
    }

    return '1px solid #003D4F';
  };

  const setLabel = () => {
    if (vacInformation.isMyself && vacInformation.type === 'SPECIAL_LEAVE') {
      return "You're on special leave";
    }
    if (vacInformation.isMyself && vacInformation.type === 'NORMAL_LEAVE') {
      return "You're on leave";
    }
    if (vacInformation.type === 'SPECIAL_LEAVE') {
      return vacInformation.fullname + ' is on special leave';
    }
    if (vacInformation.type === 'NORMAL_LEAVE') {
      return vacInformation.fullname + ' is on leave';
    }

    return vacInformation.fullname + ' is on leave';
  };

  const setLabelUsers = () => {
    if (vacInformation.isMyself && vacInformation.type === 'SPECIAL_LEAVE') {
      return "You're on special leave";
    }
    if (vacInformation.isMyself && vacInformation.type === 'NORMAL_LEAVE') {
      return "You're on leave";
    }

    return vacInformation.fullname + (width > 90 ? ' is on leave' : '');
  };

  useEffect(() => {
    const onResize = () => {
      if (labelRef.current) {
        setIsEllipsis(
          labelRef.current.offsetWidth < labelRef.current.scrollWidth
        );
      }
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div>
      {/* TODO: add constants with roles */}
      <HasAccess roles={['HR', 'ADMIN', 'REPORTER']}>
        <Tooltip title={isEllipsis && setLabel()}>
          <Box
            ref={(node: HTMLDivElement) => {
              if (node) {
                labelRef.current = node;
                setIsEllipsis(node.offsetWidth < node.scrollWidth);
              }
            }}
            data-vacationId={vacInformation.id}
            onClick={handleClick}
            whiteSpace="nowrap"
            role="leave"
            display="block"
            justifyContent={'center'}
            alignItems="center"
            position={'relative'}
            marginLeft="4%"
            height="24px"
            width={(width + 4).toString() + '%'}
            color={setTextColor}
            bgcolor={setBackgroundColor}
            border={setBorder}
            borderRadius={borderRadius}
            sx={{
              cursor: selectionInProgress ? 'not-allowed' : 'pointer',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              padding: '3px 8px 0 8px',
              fontSize: 12,
              ...sx,
            }}
            className={secondLeave ? 'leave leave-2' : 'leave'}
          >
            {setLabel()}
          </Box>
        </Tooltip>
      </HasAccess>
      <HasAccess roles={HAS_ACCESS.USER}>
        <Tooltip title={isEllipsis && setLabel()}>
          <Box
            ref={(node: HTMLDivElement) => {
              if (node) {
                labelRef.current = node;
                setIsEllipsis(node.offsetWidth < node.scrollWidth);
              }
            }}
            data-vacationId={vacInformation.id}
            onClick={handleClick}
            whiteSpace="nowrap"
            role="leave"
            display="block"
            justifyContent={'center'}
            alignItems="center"
            position={'relative'}
            marginLeft="4%"
            height="24px"
            width={(width + 4).toString() + '%'}
            color={setTextColorUsers}
            bgcolor={setBackgroundColorUsers}
            border={setBorderUsers}
            borderRadius={borderRadius}
            sx={{
              cursor: selectionInProgress ? 'not-allowed' : 'pointer',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              padding: '3px 8px 0 8px',
              fontSize: 12,
              ...sx,
            }}
            className={secondLeave ? 'leave leave-2' : 'leave'}
          >
            {setLabelUsers()}
          </Box>
        </Tooltip>
      </HasAccess>
    </div>
  );
};

export default Leave;
