import {
  NavLink,
  Outlet,
  useLocation,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';

import {
  selectUserEmployeeData,
  useGetUserInfoQuery,
  UserInfoResponse,
} from 'app-redux/apiSlice';
import { openErrorSnackbar } from 'app-redux/storeSnackbarSlice';
import Button from 'components/atoms/Button';
import ArrowLeftIcon from 'components/atoms/Icons/ArrowLeftIcon';
import Typography from 'components/atoms/Typography';
import Navigation from 'components/molecules/Navigation';
import PageLayout from 'components/molecules/PageLayout';
import Spinner from 'components/molecules/Spinner';
import { HAS_ACCESS, hasAccess } from 'utils/roleManagement';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.5,
};

const EmployeeSettingsLayout = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentUser = useSelector(selectUserEmployeeData);
  const isHRorAdmin = hasAccess([...HAS_ACCESS.HR, ...HAS_ACCESS.ADMIN]);

  const {
    data: selectedUserData,
    isLoading,
    isError,
  } = useGetUserInfoQuery(params.employeeId || '', {
    skip: params.employeeId === currentUser?.employeeNumber,
  });

  const otherUser: UserInfoResponse = {
    email: selectedUserData?.email || '',
    firstName: selectedUserData?.firstName || '',
    lastName: selectedUserData?.lastName || '',
    employeeNumber: selectedUserData?.employeeId || '',
    roleList: [selectedUserData?.role || ''],
  };
  const selectedUser =
    params.employeeId === currentUser?.employeeNumber ? currentUser : otherUser;

  if (isLoading) {
    return <Spinner fullPage text="Loading.." />;
  }
  if (!selectedUser) {
    return null;
  }
  if (isError) {
    if (isHRorAdmin) {
      navigate('/employees');
    } else {
      navigate('/');
    }
    dispatch(openErrorSnackbar('Failed to fetch user information.'));

    return null;
  }

  const message = `Viewing employee ${selectedUser.firstName} ${selectedUser.lastName}`;

  return (
    <PageLayout
      sidebar={
        <Box>
          <NavLink to={isHRorAdmin ? '/employees' : '/'}>
            <Button
              startIcon={<ArrowLeftIcon />}
              variant="outlined"
              sx={{ marginBottom: '28px' }}
            >
              Back
            </Button>
          </NavLink>
          <Navigation
            direction="vertical"
            items={[
              {
                text: 'Personal Details',
                href: `/employees/personal-details/${selectedUser.employeeNumber}`,
              },
              {
                text: 'Job Details',
                href: `/employees/job-details/${selectedUser.employeeNumber}`,
              },
              {
                text: 'Office Days',
                href: `/employees/office-days/${selectedUser.employeeNumber}`,
              },
              {
                text: 'Week Days',
                href: `/employees/week-days/${selectedUser.employeeNumber}`,
              },
            ]}
          />
        </Box>
      }
      leftSlot={<Typography variant="body-1">{message}</Typography>}
    >
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Box sx={{ padding: '24px' }}>
          <Outlet />
        </Box>
      </motion.div>
    </PageLayout>
  );
};

export default EmployeeSettingsLayout;
