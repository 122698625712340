import { ChangeEvent, useState } from 'react';
import { Box, InputAdornment, IconButton } from '@mui/material';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import Input from 'components/atoms/Input';
import SearchIcon from 'components/atoms/Icons/SearchIcon';
import PageLayout from 'components/molecules/PageLayout';
import ButtonGroup from 'components/molecules/ButtonGroup';
import Table, { Column } from 'components/molecules/Table';
import Spinner from 'components/molecules/Spinner';
import Avatar from 'components/atoms/Avatar';
import Typography from 'components/atoms/Typography';
import Stack from 'components/atoms/Stack';
import Chip from 'components/atoms/Chip';
import Button from 'components/atoms/Button';
import { AssignmentDTO, UserDTO } from 'app-redux/apiSlice';
import { useGetAllUsersInfoQuery } from 'app-redux/apiSlice';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { getComparator, stableSort } from 'utils/stableSort';
import { useDebounce } from 'hooks/useDebounce';

type Order = 'asc' | 'desc';
enum ACTIVE_STATUS {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

const generateProjectText = (projects: UserDTO['assignmentSet']) =>
  stableSort(
    projects,
    getComparator('asc', 'projectName', descendingComparator)
  )
    .map(project => project.projectName)
    .join(' ');

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === 'assignmentSet') {
    if (!a['active' as keyof T] && !a['contractEndDate' as keyof T]) {
      return 1;
    }
    if (!b['active' as keyof T] && !b['contractEndDate' as keyof T]) {
      return -1;
    }
    if (
      generateProjectText(b[orderBy] as AssignmentDTO[]) <
      generateProjectText(a[orderBy] as AssignmentDTO[])
    ) {
      return -1;
    }
    if (
      generateProjectText(b[orderBy] as AssignmentDTO[]) >
      generateProjectText(a[orderBy] as AssignmentDTO[])
    ) {
      return 1;
    }

    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }

    return 0;
  }
}

const statusParamMap = {
  ALL: undefined,
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;

const EmployeeList = () => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);

  const [activeStatus, setActiveStatus] = useState<keyof typeof ACTIVE_STATUS>(
    ACTIVE_STATUS.ALL
  );

  // TODO: check data is correct when clear search query.
  const [sortState, setSortState] = useState<{
    order: Order;
    orderBy: keyof UserDTO;
  }>({
    order: 'asc',
    orderBy: 'firstName',
  });

  const { data: employees = [], isLoading: isLoadingEmployees } =
    useGetAllUsersInfoQuery({
      query: debouncedQuery,
      status: statusParamMap[activeStatus],
    });

  const renderFullName = (employee: UserDTO) => {
    let fullname = '-';
    if (employee.firstName && employee.lastName) {
      fullname = `${employee.firstName} ${employee.lastName}`;
      if (employee.active === false) {
        fullname = `${employee.firstName} ${employee.lastName} (inactive)`;
      }
    }

    return fullname;
  };

  const renderProjects = (employee: UserDTO) => {
    // TODO: refactor
    if (employee.active) {
      if (employee.assignmentSet.length > 0) {
        return stableSort(
          employee.assignmentSet,
          getComparator('asc', 'projectName', descendingComparator)
        ).map((project, i) => (
          <div key={`${project?.projectName}-${i}`}>{project?.projectName}</div>
        ));
      } else {
        return 'Not assigned at the moment';
      }
    } else {
      if (employee.contractEndDate) {
        return `End date: ${moment(employee.contractEndDate).format(
          'DD/MM/yyyy'
        )}`;
      } else {
        return 'No end date set';
      }
    }
  };

  const columns: Column<UserDTO>[] = [
    {
      dataIndex: 'firstName',
      label: 'Name',
      align: 'left',
      render: (_value, row) => (
        <Stack align="center">
          <Avatar src="/anonimous_user.png" />
          <Typography variant="subtitle-1">{renderFullName(row)}</Typography>
        </Stack>
      ),
      orderBy: 'firstName',
    },
    {
      dataIndex: 'email',
      label: 'Email',
      orderBy: 'email',
    },
    {
      dataIndex: 'role',
      label: 'Role',
      orderBy: 'role',
      render: value => (
        <Chip
          variant="filled"
          color="grey"
          label={capitalizeFirstLetter(value)}
        />
      ),
    },
    {
      dataIndex: 'id',
      label: 'Projects',
      render: (_value, row) => <Typography>{renderProjects(row)}</Typography>,
      orderBy: 'assignmentSet',
    },
    {
      dataIndex: 'employeeId',
      label: '',
      render: value => (
        <NavLink to={`/employees/personal-details/${value}`}>
          <Button variant="outlined">Edit</Button>
        </NavLink>
      ),
    },
  ];

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);
    if (event.target.value.length > 0) {
      setisCrossIconVisible(true);
    } else {
      setisCrossIconVisible(false);
    }
  };

  const clearSearch = () => {
    setQuery('');
    setisCrossIconVisible(false);
  };

  const handleChangeActiveStatus = (key: keyof typeof ACTIVE_STATUS) => {
    setActiveStatus(key);
  };

  const [isCrossIconVisible, setisCrossIconVisible] = useState<boolean>(false);

  const filterActiveInColleaguesList = (list: UserDTO[]) => {
    return list.filter(employee => {
      switch (activeStatus) {
        case ACTIVE_STATUS.ALL:
          return true;
        case ACTIVE_STATUS.ACTIVE:
          return employee.active === true;
        case ACTIVE_STATUS.INACTIVE:
          return employee.active === false;
        default:
          return false;
      }
    });
  };

  const searchInColleaguesList = (list: Array<UserDTO>) => {
    return list.filter(
      (row: UserDTO) =>
        (row.lastName || '').toLowerCase().includes(query.toLowerCase()) ||
        (row.firstName || '').toLowerCase().includes(query.toLowerCase()) ||
        (`${row.firstName} ${row.lastName}` || '')
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        (`${row.lastName} ${row.firstName}` || '')
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        (row.email || '').toLowerCase().includes(query.toLowerCase()) ||
        (row.role || '').toLowerCase().includes(query.toLowerCase()) ||
        row.assignmentSet?.filter((project: AssignmentDTO) => {
          return project.projectName
            .toLowerCase()
            .includes(query.toLowerCase());
        }).length > 0
    );
  };

  const sortedEmployees = stableSort<UserDTO>(
    filterActiveInColleaguesList(searchInColleaguesList(employees)),
    getComparator(sortState.order, sortState.orderBy, descendingComparator)
  );

  if (isLoadingEmployees) {
    return <Spinner fullPage text="Employees loading..." />;
  }

  return (
    <PageLayout
      leftSlot={
        // TODO: Open this box after BE implemented
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: 580,
              marginRight: '20px',
              '.MuiTextField-root': { marginBottom: 0 },
            }}
          >
            <Input
              onChange={handleSearch}
              placeholder="Search Employee"
              value={query}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isCrossIconVisible ? (
                      <IconButton onClick={clearSearch}>
                        <CloseIcon color="action" />
                      </IconButton>
                    ) : (
                      <IconButton disabled>
                        <SearchIcon color="action" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ButtonGroup defaultActiveIndex={0}>
            {(
              Object.keys(ACTIVE_STATUS) as Array<keyof typeof ACTIVE_STATUS>
            ).map(key => (
              <ButtonGroup.Button
                key={key}
                onClick={() => {
                  handleChangeActiveStatus(key);
                }}
              >
                {key}
              </ButtonGroup.Button>
            ))}
          </ButtonGroup>
        </Box>
      }
    >
      <Box sx={{ padding: '0 24px 24px 64px' }}>
        <Table<UserDTO>
          columns={columns}
          data={sortedEmployees}
          options={{ defaultOrderBy: 'firstName', defaultOrder: 'asc' }}
          onSort={(order, orderBy) => setSortState({ order, orderBy })}
        />
      </Box>
    </PageLayout>
  );
};

export default EmployeeList;
