import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'app-redux/store';
import { open, close } from 'app-redux/storeDisclosureSlice';

type Options = {
  onOpen?: () => void;
  onClose?: () => void;
};

const useGlobalDisclosure = (storeKey: string, options?: Options) => {
  // if (!storeKey) {
  //   // eslint-disable-next-line @typescript-eslint/no-empty-function
  //   return { isOpen: false, close: () => {}, open: () => {} };
  // }

  const dispatch = useDispatch();

  const isOpen: boolean = useSelector(
    (state: RootState) => state.disclosure[storeKey] || false
  );

  // TODO: refactor
  const openFn = useCallback(() => {
    dispatch(open(storeKey));
    // if (typeof options?.onOpen === 'function') {
    //   options.onOpen();
    // }
    options?.onOpen && options?.onOpen();
  }, [dispatch, options, storeKey]);
  // TODO: refactor
  const closeFn = useCallback(() => {
    dispatch(close(storeKey));
    // if (typeof options?.onClose === 'function') {
    //   options?.onClose();
    // }
    options?.onClose && options?.onClose();
  }, [dispatch, options, storeKey]);

  const toggleFn = () => (isOpen ? closeFn() : openFn());

  return { isOpen, open: openFn, close: closeFn, toggle: toggleFn };
};

export default useGlobalDisclosure;
