import { createSvgIcon } from '@mui/material';

const UserPlusIcon = createSvgIcon(
  <>
    <path id="Path_261" data-name="Path 261" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_262"
      data-name="Path 262"
      d="M14,14.252v2.09A6,6,0,0,0,6,22H4a8,8,0,0,1,10-7.748ZM12,13a6,6,0,1,1,6-6A6,6,0,0,1,12,13Zm0-2A4,4,0,1,0,8,7,4,4,0,0,0,12,11Zm6,6V14h2v3h3v2H20v3H18V19H15V17Z"
      fill="currentColor"
    />
  </>,
  'UserPlus'
);

export default UserPlusIcon;
