import useMediaQuery from '@mui/material/useMediaQuery';
import { isWeekend } from 'date-fns';
import moment from 'moment';

import { VacationResponse } from '../app-redux/apiSlice';
import { BankHolidayResponse } from '../app-redux/apiSlice';
import theme from '../theme';

export const monthDurations = (year: number) => {
  if (year % 4 === 0) {
    //if the year is leap
    return [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  }

  return [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
};

export const isToday = (day: number, month: number, year: number) => {
  const today = new Date();
  if (
    day !== today.getDate() ||
    month !== today.getMonth() ||
    year !== today.getFullYear()
  ) {
    return false;
  }

  return true;
};

export function isDayOfWeekend(data: Date) {
  //check if it is a Sunday or a Saturday
  return data.getDay() === 0 || data.getDay() === 6;
}

export function isBankHoliday(
  data: Date,
  bankHolidayList: Array<BankHolidayResponse>
) {
  //check if it is a bank Holiday
  let isBankHoliday = false;
  bankHolidayList.forEach((bankHoliday: BankHolidayResponse) => {
    const [year, startMonth, startDate] = bankHoliday.startDate.split('-');
    const [, endMonth, endDate] = bankHoliday.endDate.split('-');

    if (data.getFullYear() === parseInt(year)) {
      if (startMonth !== endMonth) {
        if (
          (data.getDate() >= parseInt(startDate) &&
            data.getMonth() + 1 === parseInt(startMonth)) ||
          (data.getDate() <= parseInt(endDate) &&
            data.getMonth() + 1 === parseInt(endMonth))
        ) {
          isBankHoliday = true;
        }
      } else {
        const month = startMonth;
        if (
          data.getMonth() + 1 === parseInt(month) &&
          data.getDate() >= parseInt(startDate) &&
          data.getDate() <= parseInt(endDate)
        ) {
          isBankHoliday = true;
        }
      }
    }
  });

  return isBankHoliday;
}

export function isBetweenTwoDates(
  startDate: Date,
  endDate: Date,
  myDate: Date
) {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  myDate.setHours(0, 0, 0, 0);
  if (
    (myDate.getTime() >= startDate.getTime() &&
      myDate.getTime() <= endDate.getTime()) ||
    (myDate.getTime() >= endDate.getTime() &&
      myDate.getTime() <= startDate.getTime())
  ) {
    return true;
  }

  return false;
}

export function getStartDateCalendar(month: number, year: number) {
  const startData = new Date(year, month, 1);
  const dayOfWeek = startData.getDay();

  if (dayOfWeek !== 1) {
    if (dayOfWeek === 0) {
      startData.setDate(startData.getDate() - 6);
    } else {
      startData.setDate(startData.getDate() - dayOfWeek + 1);
    }
  }

  return startData;
}

export function getEndDateCalendar(month: number, year: number) {
  const endDate = new Date(year, month, monthDurations(year)[month]);
  if (endDate.getDay() !== 0) {
    endDate.setDate(endDate.getDate() + 7 - endDate.getDay());
  }

  return endDate;
}

export function getWorkingDaysBetweenStartDateEndDate(
  startDate: Date,
  endDate: Date,
  banckHolidayList: BankHolidayResponse[]
): string[] {
  const fromDate = moment(new Date(startDate)).startOf('day');
  const toDate = moment(new Date(endDate)).endOf('day');

  const span = moment.duration(toDate.diff(fromDate)).asDays();
  const days = [];
  for (let i = 0; i <= span; i++) {
    const currentDate = moment(fromDate).add(i, 'day').startOf('day');
    if (
      !isWeekend(currentDate.toDate()) &&
      !isBankHoliday(currentDate.toDate(), banckHolidayList)
    ) {
      days.push(currentDate);
    }
  }

  return days.map(day => day.format());
}

export function useScreenDim() {
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  if (sm) {
    return 'sm';
  }
  if (md) {
    return 'md';
  }

  return 'lg';
}

export type Leave = {
  fullname: string;
  startDate: Date;
  endDate: Date;
  type: string;
  specialLeaveType: string | null;
  isMyself: boolean;
  comment: string;
  cc: string;
  id: string;
  status: 'APPROVED' | 'CANCELED';
  docusignEnvelopeStatus?: string;
};

export function transformTypeLeaveFromResponse(
  vacationResponse: VacationResponse,
  myId: string
): Leave {
  return {
    fullname: vacationResponse.userFullName,
    startDate: new Date(vacationResponse.startDate),
    endDate: new Date(vacationResponse.endDate),
    type: vacationResponse.type,
    specialLeaveType: vacationResponse.specialLeaveType?.name || null,
    isMyself: vacationResponse.userId === myId,
    comment: vacationResponse.comment,
    cc: vacationResponse.cc,
    id: vacationResponse.id,
    status: vacationResponse.status,
    docusignEnvelopeStatus: vacationResponse.docusignEnvelopeStatus,
  };
}

export function transformArrayOfLeaveTypeFromResponse(
  leaves: Array<VacationResponse>,
  myId: string
): Array<Leave> {
  const newLeaves = leaves.map(element =>
    transformTypeLeaveFromResponse(element, myId)
  );

  return newLeaves;
}

export function numberOfDaysBetweenTwoDates(date1: Date, date2: Date): number {
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  const difference_In_Time = date2.getTime() - date1.getTime();

  return difference_In_Time / (1000 * 3600 * 24);
}
export function areTheseDatesEqual(date1: Date, date2: Date) {
  if (
    date1.getFullYear() !== date2.getFullYear() ||
    date1.getMonth() !== date2.getMonth() ||
    date1.getDate() !== date2.getDate()
  ) {
    return false;
  }

  return true;
}
export function dateToString(date: Date): string {
  return `${String(date.getDate())}-${String(date.getMonth())}-${String(
    date.getFullYear()
  )}`;
}

export function numberOfDaysUntilTheEndOfWeek(date: Date): number {
  const dateEnd = new Date(date);
  dateEnd.setDate(dateEnd.getDate() + 7 - date.getDay());

  return numberOfDaysBetweenTwoDates(date, dateEnd) % 7;
}

export function sortVacations(leaves: Leave[]) {
  leaves.sort((a, b) => {
    if (a.isMyself < b.isMyself) {
      return 1;
    }
    if (a.isMyself > b.isMyself) {
      return -1;
    }

    a.startDate.setHours(0, 0, 0, 0);
    b.startDate.setHours(0, 0, 0, 0);
    if (
      a.startDate.getTime() > b.startDate.getTime() ||
      a.endDate.getTime() > b.endDate.getTime()
    ) {
      return 1;
    }
    if (
      a.startDate.getTime() < b.startDate.getTime() ||
      a.endDate.getTime() < b.endDate.getTime()
    ) {
      return -1;
    }

    return 0;
  });

  return leaves;
}

export function firstDayInPreviousMonth(yourDate: Date): Date {
  const d = new Date(yourDate);
  d.setDate(1);
  d.setMonth(d.getMonth() - 1);

  return new Date(d.toDateString());
}

export function dateRangeOverlaps(
  a_start: Date,
  a_end: Date,
  b_start: Date,
  b_end: Date
): boolean {
  if (
    (a_start <= b_start && b_start <= a_end) || // b starts in a
    (a_start <= b_end && b_end <= a_end) || // b ends in a
    (b_start < a_start && a_end < b_end) // a in b
  ) {
    return true;
  }

  return false;
}

export const minuteToTime = (value: number) => {
  value = value > 1439 ? 1439 : value;

  let hours = Math.floor(value / 60).toString(),
    minutes = (value - +hours * 60).toString();

  if (hours.toString().length == 1) {
    hours = `0${hours}`;
  }
  if (minutes.toString().length == 1) {
    minutes = `0${minutes}`;
  }
  if (minutes.toString() === '0') {
    minutes = '00';
  }

  return { hours, minutes };
};

export const timeToMinute = (time: string) => {
  let rMinutes = 1439; // TODO: what is this?
  const splittedTime = time.split(':');

  if (splittedTime.length < 2) {
    throw new Error('Invalid time');
  }
  const hours = splittedTime[0];
  const minutes = parseInt(splittedTime[1]);
  const parsedHours = parseInt(hours) * 60;
  rMinutes = parsedHours + minutes;

  return rMinutes > 1439 ? 1439 : rMinutes;
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DAYS_OF_WEEK = {
  lg: [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ],
  md: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  sm: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
};
