import { ChangeEvent, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import Select from 'components/atoms/Select';
import Separator from 'components/atoms/Separator';
import Button from 'components/atoms/Button';
import Typography from 'components/atoms/Typography';
import Spinner from 'components/molecules/Spinner';
import { Months } from 'utils/globalConstants/Months';
import { range } from 'utils/arraysOfRange';
import { isErrorWithMessage, isFetchBaseQueryError } from 'utils/api';
import { useDownloadLeaveBalanceAnalysisReportMutation } from 'app-redux/apiSlice';
import {
  openErrorSnackbar,
  openSuccessSnackbar,
} from 'app-redux/storeSnackbarSlice';

const LeaveBalanceAnalysisView = () => {
  const months = Object.keys(Months) as Array<keyof typeof Months>;
  const currentYear = new Date().getFullYear();

  const dispatch = useDispatch();
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [month, setMonth] = useState(new Date().getMonth().toString());
  // const [isXLSFormat, setIsXLSFormat] = useState(true);

  const [
    downloadLeaveBalanceAnalysisReport,
    resultDownloadLeaveBalanceAnalysisReport,
  ] = useDownloadLeaveBalanceAnalysisReportMutation();

  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    setYear(e.target.value as string);
  };
  const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMonth(e.target.value);
  };

  const handleDownloadReport = async () => {
    try {
      // TODO: Add here isXLSFormat parameter when BE is ready
      await downloadLeaveBalanceAnalysisReport({ year, month }).unwrap();
      dispatch(openSuccessSnackbar('Report was downloaded successfully.'));
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        const errMsg = (err.data as { error: string }).error;
        dispatch(openErrorSnackbar(errMsg));
      } else if (isErrorWithMessage(err)) {
        dispatch(openErrorSnackbar(err.message));
      }
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ marginBottom: '32px' }}>
        Leave Balance Analysis
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Select
          sx={{ width: 130, marginRight: '24px', marginBottom: 0 }}
          value={year}
          onChange={handleYearChange}
          searchable={false}
          label="Year"
        >
          {range(2010, currentYear).map(year => (
            <Select.MenuItem key={year} value={year.toString()}>
              {year}
            </Select.MenuItem>
          ))}
        </Select>
        <Select
          sx={{ width: 280, marginRight: '24px', marginBottom: 0 }}
          value={month}
          onChange={handleMonthChange}
          searchable={false}
          label="Month"
        >
          {months.map((key, index) => (
            <Select.MenuItem key={key} value={index}>
              {key}
            </Select.MenuItem>
          ))}
        </Select>
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={isXLSFormat}
              onChange={handleIsXLSFormatChange}
            />
          }
          label={<Typography variant='body-2'>Export in XLS format</Typography>}
        /> */}
      </Box>
      <Separator sx={{ margin: '24px 0' }} />
      <Button onClick={handleDownloadReport}>Download Report</Button>
      {resultDownloadLeaveBalanceAnalysisReport.isLoading && (
        <Spinner fullPage text="Report is being prepared..." />
      )}
    </Box>
  );
};

export default LeaveBalanceAnalysisView;
