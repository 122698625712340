import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import Button, { ButtonProps } from 'components/atoms/Button';
import Stack from 'components/atoms/Stack';
import Typography from 'components/atoms/Typography';
import ArrowRightIcon from 'components/atoms/Icons/ArrowRightIcon';
import { HasAccess } from 'utils/roleManagement';

export type NavigationItemProps = {
  text: string;
  href: string;
  hasAccess?: string[];
  buttonProps?: ButtonProps;
};

type NavigationProps = {
  items: NavigationItemProps[];
  direction?: 'horizontal' | 'vertical';
};

const StyledVerticalWrapper = styled(Box)(() => ({
  width: '100%',
  '> *:last-child > div': { borderBottom: 'none' },
  '> *:first-child > div': { paddingTop: '0' },
}));

const StyledNavLinkHorizontal = styled(NavLink)(({ theme }) => ({
  '&.active button': {
    backgroundColor: theme.palette.ixColorMist.main,
  },
}));

const StyledNavLinkVertical = styled(NavLink)(({ theme }) => ({
  '&.active div': {
    color: theme.palette.ixColorWave.main,
  },
}));

const StyledNavButton = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 0 24px 0',
  color: theme.palette.ixColorGrey60.main,
  textTransform: 'uppercase',
  cursor: 'pointer',
  transition: 'color 0.3s ease',
  borderBottom: `2px solid ${theme.palette.ixColorGrey20.main}`,
  '&:hover': {
    color: theme.palette.ixColorWave.main,
  },
}));

const Navigation = (props: NavigationProps) => {
  const { items = [], direction = 'horizontal' } = props;

  const renderHorizontal = () =>
    items.map(item =>
      item.hasAccess ? (
        <HasAccess roles={item.hasAccess} key={item.text}>
          <StyledNavLinkHorizontal to={item.href}>
            <Button {...item.buttonProps}>{item.text}</Button>
          </StyledNavLinkHorizontal>
        </HasAccess>
      ) : (
        <StyledNavLinkHorizontal key={item.text} to={item.href}>
          <Button {...item.buttonProps}>{item.text}</Button>
        </StyledNavLinkHorizontal>
      )
    );

  const renderVertical = () => {
    return (
      <StyledVerticalWrapper>
        {items.map(item =>
          item.hasAccess ? (
            <HasAccess roles={item.hasAccess} key={item.text}>
              <StyledNavLinkVertical to={item.href} key={item.text}>
                <StyledNavButton>
                  <Typography color="inherit" variant="button-m">
                    {item.text}
                  </Typography>
                  <ArrowRightIcon />
                </StyledNavButton>
              </StyledNavLinkVertical>
            </HasAccess>
          ) : (
            <StyledNavLinkVertical to={item.href} key={item.text}>
              <StyledNavButton>
                <Typography color="inherit" variant="button-m">
                  {item.text}
                </Typography>
                <ArrowRightIcon />
              </StyledNavButton>
            </StyledNavLinkVertical>
          )
        )}
      </StyledVerticalWrapper>
    );
  };

  return (
    <Stack
      fluid={direction === 'vertical'}
      gap={direction === 'horizontal' ? 'small' : 0}
      direction={direction}
    >
      <>
        {direction === 'horizontal' && renderHorizontal()}
        {direction === 'vertical' && renderVertical()}
      </>
    </Stack>
  );
};

export default Navigation;
