import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import ArrowLeftIcon from 'components/atoms/Icons/ArrowLeftIcon';
import ArrowRightIcon from 'components/atoms/Icons/ArrowRightIcon';
import Stack from 'components/atoms/Stack';
import Typography from 'components/atoms/Typography';

type SelectorProps = {
  label: ReactNode;
  onNext: () => void;
  onPrev: () => void;
  disableNext?: boolean;
  disablePrev?: boolean;
};

const StyledLeftIcon = styled(ArrowLeftIcon)<{ disabled: boolean }>(
  ({ theme, disabled }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    color: disabled
      ? theme.palette.ixColorGrey30.main
      : theme.palette.ixColorGrey90.main,
  })
);

const StyledRightIcon = styled(ArrowRightIcon)<{ disabled: boolean }>(
  ({ theme, disabled }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    color: disabled
      ? theme.palette.ixColorGrey30.main
      : theme.palette.ixColorGrey90.main,
  })
);

const Selector = (props: SelectorProps) => {
  const {
    label,
    onNext,
    onPrev,
    disableNext = false,
    disablePrev = false,
  } = props;

  return (
    <Stack align="center" gap={0.5}>
      <StyledLeftIcon disabled={disablePrev} onClick={onPrev} />
      <Box sx={{ minWidth: '120px' }}>
        <Typography variant="body-2" color="ixColorGrey70">
          {label}
        </Typography>
      </Box>
      <StyledRightIcon disabled={disableNext} onClick={onNext} />
    </Stack>
  );
};

export default Selector;
