import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import Stack from 'components/atoms/Stack';
import Button from 'components/atoms/Button';
import Navigation, { NavigationItemProps } from '../Navigation';
import Popper from '../Popper';
import BookLeaveForm from 'containers/Layout/forms/BookLeaveForm';
import { HAS_ACCESS } from 'utils/roleManagement';
import { clearRangeSelection } from 'app-redux/storeCalendarSlice';

type SubHeaderProps = {
  leftSlot: ReactNode;
};

const StyledWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.ixColorTalc.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 64px',
  position: 'relative',
  height: '100%',
}));

const navigationItems: NavigationItemProps[] = [
  {
    text: 'Homepage',
    href: '/',
    buttonProps: {
      variant: 'text',
    },
  },
  {
    text: 'Reports',
    href: '/reports',
    hasAccess: HAS_ACCESS.REPORTER,
    buttonProps: {
      variant: 'text',
    },
  },
  {
    text: 'Employees',
    href: '/employees',
    hasAccess: HAS_ACCESS.HR,
    buttonProps: {
      variant: 'text',
    },
  },
];

const bookLeaveFormPopperStoreKey = 'bookLeaveFormPopper';

const SubHeader = (props: SubHeaderProps) => {
  const { leftSlot } = props;
  const dispatch = useDispatch();

  return (
    <StyledWrapper>
      <div>{leftSlot}</div>
      <Stack>
        <Navigation items={navigationItems} />
        <Popper
          title="Book a Leave"
          closable
          storeKey={bookLeaveFormPopperStoreKey}
          placement="bottom-end"
          trigger={<Button>Book Leave</Button>}
          papperSx={{
            marginTop: '12px',
            width: '670px',
          }}
          onClose={() => dispatch(clearRangeSelection())}
          closeWhenRouteChange
        >
          {() => <BookLeaveForm />}
        </Popper>
      </Stack>
    </StyledWrapper>
  );
};

export default SubHeader;
