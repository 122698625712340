import {
  Children,
  ForwardRefExoticComponent,
  Ref,
  RefAttributes,
  forwardRef,
  isValidElement,
  useEffect,
  useState,
} from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { InputAdornment, ListSubheader } from '@mui/material';
import MuiMenuItem, { MenuItemProps } from '@mui/material/MenuItem';

import Input from '../Input';
import SearchIcon from '../Icons/SearchIcon';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

export type InternalSelectProps = {
  searchable?: boolean;
} & TextFieldProps;

type CompoundedComponent = ForwardRefExoticComponent<
  InternalSelectProps & RefAttributes<HTMLElement>
> & {
  MenuItem: (props: MenuItemProps) => JSX.Element;
};

const Select = forwardRef(
  (props: InternalSelectProps, ref: Ref<HTMLDivElement>) => {
    const { children, searchable = true, ...rest } = props;
    const [query, setQuery] = useState('');

    const clonedChildren = Children.toArray(children).filter(child => {
      if (isValidElement(child) && child.props?.children) {
        return child.props.children
          .toString()
          .toLowerCase()
          .includes(query.toLowerCase());
      }

      return false;
    });

    const selectedOption = Children.toArray(children).find(child => {
      if (isValidElement(child) && child.props?.value) {
        return props.value === child.props?.value;
      }

      return false;
    });

    const indexOfSelected = clonedChildren.findIndex(child => {
      if (
        isValidElement(child) &&
        child.props?.value &&
        isValidElement(selectedOption) &&
        selectedOption?.props?.value
      ) {
        return child.props?.value === selectedOption?.props?.value;
      }

      return false;
    });

    useEffect(() => {
      setQuery('');
    }, [props.value]);

    return (
      <Input
        {...rest}
        ref={ref}
        select
        SelectProps={{
          IconComponent: props => <ArrowDownIcon {...props} />,
          inputProps: {
            ...rest.inputProps,
            sx: { paddingLeft: '16px' },
          },
          MenuProps: {
            autoFocus: false,
            ...rest.SelectProps?.MenuProps,
          },
          ...rest.SelectProps,
        }}
      >
        {searchable && (
          <ListSubheader>
            <Input
              onChange={event => setQuery(event.currentTarget.value)}
              placeholder="Search"
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onKeyDown={e => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {indexOfSelected === -1 && selectedOption}
        {clonedChildren}
      </Input>
    );
  }
);

Select.displayName = 'Select';
const CompoundSelect = Select as CompoundedComponent;

const MenuItem = (props: MenuItemProps) => {
  const { children, ...rest } = props;

  return <MuiMenuItem {...rest}>{children}</MuiMenuItem>;
};

CompoundSelect.MenuItem = MenuItem;

export default CompoundSelect;
