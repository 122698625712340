import { useParams } from 'react-router-dom';

import { useGetUserInfoQuery } from 'app-redux/apiSlice';
import Spinner from 'components/molecules/Spinner';
import PersonalDetailsForm from 'containers/Layout/forms/PersonalDetailsForm';

const PersonalDetailsView = () => {
  const { employeeId } = useParams();

  const { data: user, isLoading: userIsLoading } = useGetUserInfoQuery(
    employeeId || ''
  );

  if (userIsLoading) {
    return <Spinner fullPage text="User information loading..." />;
  }

  return <PersonalDetailsForm defaultValues={user} />;
};

export default PersonalDetailsView;
