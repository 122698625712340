import { styled, SxProps, Theme } from '@mui/material/styles';

import AlertIcon from '../../atoms/Icons/AlertIcon';
import Typography from '../../atoms/Typography';
import CloseIcon from '../../atoms/Icons/CloseIcon';
import Button from '../../atoms/Button';
import IconButton from '../../atoms/IconButton';
import ErrorIcon from '../../atoms/Icons/ErrorIcon';
import InformationIcon from '../../atoms/Icons/InformationIcon';
import CheckIcon from '../../atoms/Icons/CheckIcon';

export type AlertProps = {
  onClose?: () => void;
  onUndo?: () => void;
  allowClose?: boolean;
  allowUndo?: boolean;
  message: string;
  variant: 'error' | 'warning' | 'information' | 'confirmation';
  fluid?: boolean;
  sx?: SxProps;
};

type StyledAlertProps = {
  variant: AlertProps['variant'];
  fluid?: boolean;
};

const generateIconColorStyles = (
  variant: AlertProps['variant'],
  theme: Theme
) => {
  const iconColorStyle = {
    confirmation: {
      color: theme.palette.ixColorJade40.main,
    },
    information: {
      color: theme.palette.ixColorSapphire40.main,
    },
    error: {
      color: theme.palette.ixColorFlamingo.main,
    },
    warning: {
      color: theme.palette.ixColorTurmeric50.main,
    },
  };

  return iconColorStyle[variant];
};

const generateBackgroundStyles = (
  variant: AlertProps['variant'],
  theme: Theme
) => {
  const backgrounds = {
    confirmation: {
      backgroundColor: theme.palette.ixColorJade60.main,
    },
    information: {
      backgroundColor: theme.palette.ixColorWave70.main,
    },
    error: {
      backgroundColor: theme.palette.ixColorFlamingo50.main,
    },
    warning: {
      backgroundColor: theme.palette.ixColorTurmeric70.main,
    },
  };

  return backgrounds[variant];
};

const StyledAlert = styled('div')<StyledAlertProps>(
  ({ fluid, variant, theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '2px',
    padding: '14px',
    width: fluid ? '100%' : 'auto',
    '> svg': {
      width: '20px',
      height: '20px',
    },
    ...generateBackgroundStyles(variant, theme),
  })
);

const StyledIconWrapper = styled('div')<StyledAlertProps>(
  ({ variant, theme }) => ({
    display: 'flex',
    marginRight: '8px',
    ...generateIconColorStyles(variant, theme),
  })
);

const variantIconMap = {
  error: <ErrorIcon />,
  warning: <AlertIcon />,
  information: <InformationIcon />,
  confirmation: <CheckIcon />,
};

const Alert = (props: AlertProps) => {
  const {
    message,
    variant,
    allowClose,
    allowUndo,
    onClose,
    onUndo,
    fluid = false,
    sx,
  } = props;

  return (
    <StyledAlert variant={variant} fluid={fluid} sx={{ ...sx }}>
      <StyledIconWrapper variant={variant}>
        {variantIconMap[variant]}
      </StyledIconWrapper>
      <Typography
        variant="caption"
        color="ixColorTalc"
        sx={{ marginRight: '8px' }}
      >
        {message}
      </Typography>
      {allowClose && (
        <IconButton size="small" invert onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
      {allowUndo && (
        <Button invert variant="text" onClick={onUndo}>
          UNDO
        </Button>
      )}
    </StyledAlert>
  );
};

export default Alert;
