import {
  divideLeavesByMonths,
  divideLeavesByWeeks,
  vacationPeriodDisplay,
} from './utils';
import { useDispatch, useSelector } from 'react-redux';

import { Leave } from 'utils/calendar-utils';
import LeavePeriodRow from './LeavePeriodRow';
import NoVacationRow from './NoVacationRow';
import { RootState } from 'app-redux/store';
import TableBody from '@mui/material/TableBody';
import VacationRow from './VacationRow';
import { closeEditForm } from 'app-redux/storeCalendarSlice';
import { useEffect } from 'react';

const ListBody = ({ leaves }: { leaves: Leave[] }) => {
  const dispatch = useDispatch();
  const {
    selectedYear: year,
    selectedMonth: month,
    periodViewType: periodFilter,
  } = useSelector((state: RootState) => state.calendarFilter);

  useEffect(() => {
    dispatch(closeEditForm());
  }, [periodFilter, dispatch]);

  const mappedLeaves =
    periodFilter === 'year'
      ? divideLeavesByMonths(year, leaves, 'year')
      : divideLeavesByWeeks(year, month, leaves);
  const rows = [];

  for (const period in mappedLeaves) {
    const currentLeaves = mappedLeaves[period];
    rows.push(
      <TableBody key={period} data-testid={period}>
        <LeavePeriodRow period={period} />
        {currentLeaves.length === 0 ? (
          <NoVacationRow key={period}></NoVacationRow>
        ) : (
          currentLeaves.map(vacation => (
            <VacationRow
              key={vacation.fullname + period + vacationPeriodDisplay(vacation)}
              vacation={vacation}
            ></VacationRow>
          ))
        )}
      </TableBody>
    );
  }

  return (
    <>
      {rows.map(row => (
        <>{row}</>
      ))}
    </>
  );
};

export default ListBody;
